import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import md5 from "blueimp-md5";

const DetailPanel = ({ thirdPoint, twoThirdPoint, nonEmptyFields }) => {
  console.log(nonEmptyFields);
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    // Find the email in nonEmptyFields
    const emailField = Object.entries(nonEmptyFields).find(([label]) => label === "E-Mail");
    if (emailField) {
      const [, emailValue] = emailField;
      const newImageUrl = getGravatarURL(emailValue); // Pass the email value
      setImageUrl(newImageUrl);
    }
  }, [nonEmptyFields]);

  function getGravatarURL(email, size = 80) {
    const defaultImageURL = "https://cdn-icons-png.flaticon.com/512/149/149071.png";

    if (!email) {
      return defaultImageURL;
    }
    const hash = md5(email.toLowerCase().trim());
    return `https://www.gravatar.com/avatar/${hash}?s=${size}&d=${encodeURIComponent(defaultImageURL)}`;
  }

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // Convert nonEmptyFields object into an array of key-value pairs
  const fieldsArray = Object.entries(nonEmptyFields);

  return (
    <Box
      sx={{
        display: "flex",
        gap: "20px",
        padding: "10px",
        backgroundColor: "#fafafa",
        borderRadius: "8px",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        width: "100%",
        justifyContent: "space-evenly",
      }}
    >
      <div style={{ padding: "10px", height: "100px", width: "100px", display: "flex", alignItems: "center" }}>
        <img
          className="pro"
          alt=""
          src={imageUrl}
          data-intro="This is Profile image"
          width="100%"
          style={{ display: "block", margin: "0 auto", borderRadius: "50%" }}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "10px", padding: "10px 0", flex: "1" }}>
        {fieldsArray.slice(0, thirdPoint).map(([key, value]) => (
          <div className="d-flex items-center gap-5px" key={key}>
            <strong>{capitalizeFirstLetter(key)}:</strong>
            <span>{value}</span>
          </div>
        ))}
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "10px", padding: "10px 0", flex: "1" }}>
        {fieldsArray.slice(thirdPoint, twoThirdPoint).map(([key, value]) => (
          <div className="d-flex items-center gap-5px" key={key}>
            <strong>{capitalizeFirstLetter(key)}:</strong>
            <span>{value}</span>
          </div>
        ))}
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "10px", padding: "10px 0", flex: "1" }}>
        {fieldsArray.slice(twoThirdPoint).map(([key, value]) => (
          <div className="d-flex items-center gap-5px" variant="body2" key={key}>
            <strong>{capitalizeFirstLetter(key)}:</strong>
            <span>{value}</span>
          </div>
        ))}
      </div>
    </Box>
  );
};

export default DetailPanel;
