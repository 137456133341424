import {
  PieChart,
  Box,
  User,
  Filter,
  Activity,
  MessageSquare,
  Target,
  GitPullRequest,
  Tag,
  UserCheck,
  Database,
} from "react-feather";
import { HiOutlineCube } from "react-icons/hi";
import { RxComponent1 } from "react-icons/rx";
import { BiSolidComponent } from "react-icons/bi";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import DisplaySettingsOutlinedIcon from "@mui/icons-material/DisplaySettingsOutlined";
import StreamOutlinedIcon from "@mui/icons-material/StreamOutlined";
import InsertChartOutlinedRoundedIcon from "@mui/icons-material/InsertChartOutlinedRounded";
import BugReportOutlinedIcon from "@mui/icons-material/BugReportOutlined";
import GpsFixedOutlinedIcon from "@mui/icons-material/GpsFixedOutlined";
import { FaMobileAlt, FaRegNewspaper } from "react-icons/fa";
import { AiOutlineConsoleSql, AiOutlineFolderOpen } from "react-icons/ai";
import { IoExtensionPuzzleOutline } from "react-icons/io5";
import { BiCog, BiEnvelope } from "react-icons/bi";
import { FiLifeBuoy, FiUsers, FiLock, FiList, FiFolder } from "react-icons/fi";
import { TbArrowsRightLeft, TbPlug } from "react-icons/tb";
import { BsTag } from "react-icons/bs";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import RouteOutlinedIcon from "@mui/icons-material/RouteOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { FaRegBuilding, FaRegIdBadge, FaEnvelope } from "react-icons/fa";
import { GrLineChart } from "react-icons/gr";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import { t } from "i18next";

export const MENUITEMS = [
  // {
  //   title: t("caymland.dashboard.menu.index"),
  //   icon: DashboardOutlinedIcon,
  //   type: "link",
  //   badgeType: "primary",
  //   active: false,
  //   path: "/dashboard",
  // },
  {
    title: "caymland.mailbox.menu",
    icon: EmailOutlinedIcon,
    type: "link",
    path: "/mailbox",
    active: false,
  },

  {
    title: "caymland.contact.segments.fields.contacts",
    icon: PeopleAltOutlinedIcon,
    type: "link",
    path: "/contacts",
    active: false,
  },
  {
    title: "caymland.companies.menu.index",
    icon: BusinessOutlinedIcon,
    type: "link",
    path: "/companies",
    active: false,
  },
  {
    title: "caymland.brochures.menu.orders",
    icon: ArticleOutlinedIcon,
    type: "link",
    path: "/orders",
    active: false,
  },
  // {
  //   title: "caymland.brochures",
  //   icon: ArticleOutlinedIcon,
  //   type: "sub",
  //   active: false,
  //   children: [
  //     {
  //       path: "/products",
  //       title: "caymland.brochures.menu.brochures",
  //       type: "link",
  //     },
  //     {
  //       path: "/orders",
  //       title: "caymland.brochures.menu.orders",
  //       type: "link",
  //     },
  //     {
  //       path: "/templates",
  //       title: "caymland.brochures.menu.assets",
  //       type: "link",
  //     },
  //   ],
  // },

  // {
  //   title: "caymland.lead.preferred.segments",
  //   icon: FilterAltOutlinedIcon,
  //   type: "link",
  //   path: "/segments",
  //   active: false,
  // },
  // {
  //   title: "SQL conditions",
  //   icon: Database,
  //   type: "link",
  //   path: "/sqlConditions",
  //   active: false,
  // },
  // {
  //   title: "caymland.core.components",
  //   icon: ViewInArOutlinedIcon,
  //   type: "sub",
  //   active: false,
  //   children: [
  //     {
  //       path: "/assets",
  //       title: "caymland.asset.assets",
  //       type: "link",
  //     },
  //     // {
  //     //   path: "/file_manager",
  //     //   title: "File Manager",
  //     //   type: "link",
  //     // },
  //     {
  //       path: "/forms",
  //       title: "caymland.form.forms",
  //       type: "link",
  //     },
  //     {
  //       path: "/pages",
  //       title: "caymland.page.pages",
  //       type: "link",
  //     },
  //     {
  //       path: "/dwc",
  //       title: "caymland.core.dynamicContent",
  //       type: "link",
  //     },
  //   ],
  // },

  // {
  //   title: "caymland.campaign.campaigns",
  //   icon: DisplaySettingsOutlinedIcon,
  //   type: "link",
  //   path: "/campaigns",
  //   active: false,
  // },
  // {
  //   title: "caymland.core.channels",
  //   icon: ChatBubbleOutlineOutlinedIcon,
  //   type: "sub",
  //   active: false,
  //   children: [
  //     // {
  //     //   path: "/messages",
  //     //   title: "Marketing Messages",
  //     //   type: "link",
  //     // },
  //     {
  //       path: "/emails",
  //       title: "E-Mails",
  //       type: "link",
  //     },
  //     {
  //       path: "/sms",
  //       title: "SMS",
  //       type: "link",
  //     },
  //     {
  //       path: "/focus",
  //       title: "caymland.focus",
  //       type: "link",
  //     },
  //     // {
  //     //   path: "/monitoring",
  //     //   title: "Social Monitoring",
  //     //   type: "link",
  //     // },
  //   ],
  // },
  // {
  //   title: "caymland.lead.field.points",
  //   icon: GpsFixedOutlinedIcon,
  //   type: "sub",
  //   active: false,
  //   children: [
  //     { path: "/points", title: "caymland.point.menu.index", type: "link" },
  //     {
  //       path: "/triggers",
  //       title: "caymland.point.trigger.menu.index",
  //       type: "link",
  //     },
  //   ],
  // },
  // {
  //   title: "caymland.stages.menu.root",
  //   icon: RouteOutlinedIcon,
  //   type: "link",
  //   path: "/stages",
  //   active: false,
  // },
  // {
  //   title: "caymland.report.reports",
  //   icon: AssessmentOutlinedIcon,
  //   type: "link",
  //   path: "/reports",
  //   active: false,
  // },
];

export const SETTINGSMENU = [
  {
    title: "caymland.category.header.index",
    icon: <AiOutlineFolderOpen size={20} />,
    path: "/categories",
    type: "link",
    active: false,
  },
  {
    title: "caymland.user.users",
    icon: <FiUsers size={20} />,
    path: "/users",
    type: "link",
    active: false,
  },
  {
    title: "caymland.webhook.webhooks",
    icon: <TbArrowsRightLeft size={20} />,
    path: "/webhooks",
    type: "link",
    active: false,
  },
  {
    title: "caymland.lead.field.header.index",
    icon: <FiList size={20} />,
    path: "/contacts/fields",
    type: "link",
    active: false,
  },
  {
    title: "caymland.tags_editor",
    icon: <BsTag size={20} />,
    path: "/tags",
    type: "link",
    active: false,
  },
  {
    title: "caymland.mailbox.menu.admin.index",
    icon: ArticleOutlinedIcon,
    type: "sub",
    active: false,
    children: [
      // {
      //   path: "/",
      //   title: "Regulate",
      //   type: "link",
      // },
      {
        path: "/mailbox-admin/template",
        title: "caymland.mailbox.templates.index",
        type: "link",
      },
      {
        path: "/mailbox-admin/classification",
        title: "caymland.mailbox.classification.index",
        type: "link",
      },
      {
        path: "/mailbox-admin/rules",
        title: "caymland.mailbox.rules.index",
        type: "link",
      },
    ],
  },
];
