import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "../../../../reusableComponents/Buttons/Button";
import { FaRegTimesCircle } from "react-icons/fa";
import { AiOutlineSave } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import InnerHTML from "dangerously-set-html-content";
import { format } from "date-fns";
import { Separator } from "../../../../ui/separator";

function removeStyleTags(html) {
  return html.replace(/<style[\s\S]*?>[\s\S]*?<\/style>/gi, "");
}

const MailModal = ({ setShow, show, mail, buttons }) => {
  const navigate = useNavigate();

  const closeModal = () => {
    setShow(false);
  };

  return (
    <Modal show={show} onHide={closeModal} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t("caymland.mailbox.messages.modal.header.received")}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ maxHeight: "75vh", overflowY: "auto", gap: "10px", display: "flex", flexDirection: "column" }}
      >
        {mail && (
          <div className="tw-flex tw-flex-1 tw-flex-col ">
            <div className="tw-flex tw-items-start tw-pb-3 tw-px-3">
              <div className="tw-flex tw-items-start tw-gap-4 tw-text-[10px]">
                <div className="tw-grid tw-gap-1">
                  <div className="tw-line-clamp-1 tw-flex tw-gap-4 tw-text-[13px]">
                    <div className="tw-w-fit tw-min-w-[8%]">
                      <span className="tw-font-medium tw-text-[13px]">{t("caymland.mailbox.list.table.field.subject")}:</span>
                    </div>
                    <div>{mail.subject}</div>
                  </div>
                  <div className="tw-line-clamp-1 tw-flex tw-gap-4 tw-text-[13px]">
                    <div className="tw-w-fit tw-min-w-[8%]">
                      <span className="tw-font-medium tw-text-[13px]">{t("caymland.mailbox.list.table.field.from")}:</span>
                    </div>
                    <div>{mail.to[0].address}</div>
                  </div>
                  <div className="tw-line-clamp-1 tw-flex tw-gap-4 tw-text-[13px]">
                    <div className="tw-w-fit tw-min-w-[8%]">
                      <span className="tw-font-medium tw-text-[13px]">{t("caymland.mailbox.list.table.field.date")}:</span>
                    </div>
                    <div>{format(new Date(mail.date), "PPpp")}</div>
                  </div>
                </div>
              </div>
            </div>
            <Separator />
            <div style={{ maxHeight: "75vh", overflowY: "auto", zoom: 1 }} className="tw-p-4">
              {mail.html && <InnerHTML html={removeStyleTags(mail?.html)} allowRerender />}
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button buttons={buttons} />
      </Modal.Footer>
    </Modal>
  );
};

export default MailModal;
