import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRemoveDoNotContactMutation } from "../../../redux/api/contactsApi";
import { Error, Success } from "../../reusableComponents/Alerts";
import { t } from "i18next";

function DoNotContact({ doNotContact }) {
  const [pointerStyle, setPointerStyle] = useState("default");
  const [currentDoNotContact, setCurrentDoNotContact] = useState(doNotContact);

  const { id } = useParams();
  useEffect(() => {
    setCurrentDoNotContact(doNotContact);
  }, [doNotContact]);

  const [removeDoNotContact] = useRemoveDoNotContactMutation();

  const removeDoNotContactHandle = async () => {
    const response = await removeDoNotContact({ id });
    console.log("response", response);
    if (!response.errors) {
      Success(`${t("caymland.message.type.successfully", { type: t("caymland.dashboard.update.past.tense") })}!`);
      setCurrentDoNotContact(0);
    } else {
      Error(t("caymland.message.error"));
    }
  };

  return (
    <>
      {currentDoNotContact ? (
        <div className="right-info">
          {/* <p
            sytle={{
              color: "white",
              backgroundColor: "white",
            }}
          >
            Do Not Contact
          </p>{" "} */}
          <span
            style={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#f86b4f",
              backgroundColor: "white",
              border: "1px solid #f86b4f",
              padding: "2px 6px",
              boxShadow: "0 0 5px rgb(0 0 0 / 40%)",
              cursor: "pointer",
            }}
          >
            {t("caymland.lead.do.not.contact")}{" "}
            <span
              className="dncHover"
              onMouseOver={() => setPointerStyle("pointer")}
              onMouseOut={() => setPointerStyle("default")}
              onClick={removeDoNotContactHandle}
              style={{
                fontSize: "1",
                cursor: pointerStyle,
                marginLeft: "3px",
                color: "#f86b4f",
              }}
            >
              X
            </span>
          </span>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default DoNotContact;
