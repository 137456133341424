import React from "react";
import { ImCancelCircle } from "react-icons/im";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { AiOutlineSave } from "react-icons/ai";
import Button from "./Buttons/Button";
import { t } from "i18next";

function MainModal({
  isOpen,
  setIsOpen,
  modalTitle,
  handleSubmit,
  loading,
  content,
  saveText,
  icon,
  disabled,
  noFooter,
  closeLink,
}) {
  const toggle = () => {
    setIsOpen((prev) => !prev);
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.close"),
      icon: <ImCancelCircle size={15} />,
      onClick: () => toggle(),
    },
    {
      name: "save",
      title: t("caymland.core.form.save"),
      icon: <AiOutlineSave size={15} />,
      onClick: () => handleSubmit(),
    },
  ];
  return (
    <Modal backdrop="static" keyboard={true} isOpen={isOpen} toggle={toggle} size="lg">
      <form>
        <ModalHeader toggle={closeLink ?? toggle}>
          <div className="d-flex items-center gap-5px p-no-margin">
            <p style={{ fontWeight: "600", fontSize: "16px" }}>{modalTitle}</p>
          </div>
        </ModalHeader>
        <ModalBody>{content}</ModalBody>
        {!noFooter && (
          <ModalFooter>
            <Button buttons={buttons} loading={loading} />
          </ModalFooter>
        )}
      </form>
    </Modal>
  );
}

export default MainModal;
