import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { mailboxApi } from "../../../src/redux/api/mailboxApi";
import axios from "axios";
import PostalMime from "postal-mime";

// export const fetchMailData = createAsyncThunk("mail/fetchData", async (mailId, { rejectWithValue }) => {

//   try {
//     // Using initiate directly from the API service
//     const response = await mailboxApi.endpoints.markMessageAsRead(mailId);
//     console.log("response", response.data);
//     if (response.error) {
//       return rejectWithValue(response.error.data || response.error.status);
//     }
//     return response.data;
//   } catch (error) {
//     return rejectWithValue(error.toString());
//   }
// });

export const fetchMailData = createAsyncThunk("mail/fetchData", async (mailId, { rejectWithValue, getState }) => {
  try {
    const token = getState().auth.token;
    const response = await axios.post(
      `${process.env.REACT_APP_DEV_URL}/api/mailbox/messages/${mailId}/read`,
      {},
      {
        headers: {
          Authorization: `JWT ${token}`,
        },
      }
    );

    if (response.status !== 200) {
      return rejectWithValue(response.data);
    }

    if (response.status === 200 || response.status === 201) {
      const parser = new PostalMime();
      const parsedEmailData = await parser.parse(response?.data);
      return parsedEmailData;
    }
  } catch (error) {
    console.log("error", error);
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});

const mailSlice = createSlice({
  name: "mail",
  initialState: {
    mails: {},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMailData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMailData.fulfilled, (state, action) => {
        state.mails[action.meta.arg] = action.payload;
        state.loading = false;
      })
      .addCase(fetchMailData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch data";
      });
  },
});

export default mailSlice.reducer;
