import { Route, Routes as ReactRoutes, Navigate } from "react-router-dom";
import flattenDeep from "lodash/flattenDeep";
import { Fragment } from "react";

const generateFlattenRoutes = (routes) => {
  if (!routes) return [];
  return flattenDeep(routes.map(({ children: subRoutes, ...rest }) => [rest, generateFlattenRoutes(subRoutes)]));
};

export const RoutesComponent = ({ mainRoutes }) => (
  <ReactRoutes>
    <Route path="/" element={<Navigate to="/mailbox" replace />} />
    {mainRoutes.map(({ layout: Layout, routes: subRoutes }, index) => {
      const subRoutes2 = generateFlattenRoutes(subRoutes);
      return (
        <Fragment key={index}>
          <Route key={index} element={<Layout />}>
            {subRoutes2.map(({ element, path, name }) => (
              <Route key={name} path={path} element={element} />
            ))}
          </Route>
        </Fragment>
      );
    })}
    {/* <Route path="*" element={<Navigate to="/dashboard" replace />} /> */}
  </ReactRoutes>
);
