import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { logout } from "../features/authSlice";



const url = window.location.hostname;
const isCaymLandDomain = url.endsWith("caym.land");

const subdomainParts = url.split(".");
const subdomain = subdomainParts[0];
const api = isCaymLandDomain ? `https://${subdomain}.caymland.dev/` : process.env.REACT_APP_DEV_URL;
const baseQuery = fetchBaseQuery({
  baseUrl: api,
  timeout: 15000,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    if (token) {
      headers.set("Authorization", `JWT ${token}`);
    }
    headers.set("X-Requested-With", "XMLHttpRequest");
    return headers;
  },
});

export const customFetchBaseQuery = async (args, api, extraOptions) => {
  let adjustedArgs = {
    ...args,
    responseHandler: args.responseType === "blob" ? "blob" : "json",
  };
 
  const result = await baseQuery(
    args.responseType === "blob" ? adjustedArgs : args,
    api,
    extraOptions,
  );
 
  if (result.error) {
    if (result.error.status === 401) {
  console.log("result", result)

      console.error("Authentication error, logging out...");
      api.dispatch(logout());
    }
  }
 
  return result;
};


