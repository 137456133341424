import React from "react";
import StaticCompanySelect from "./StaticCompanySelect";
import { Select, Text, TextArea, DatePicker, Label, ButtonGroup, TimePicker } from "../../reusableComponents/Inputs";
import { Controller } from "react-hook-form";
import TimezoneSelect from "../../reusableComponents/ReusableSelects/Timezone";
import moment from "moment";
import CountriesSelect from "../../reusableComponents/ReusableSelects/Countries";
import LocalesSelect from "../../reusableComponents/ReusableSelects/Locales";
import RegionsSelect from "../../reusableComponents/ReusableSelects/Regions";
import { t } from "i18next";

export const FieldsRenderSection = ({
  aliases,
  filteredFields,
  title,
  isW50 = false,
  setContactImg,
  setFormChanged,
  control,
  watch,
  getValues,
  noBorder = false,
  errors,
  setValue,
}) => {
  return (
    <div className="group-fields-style" style={{ border: noBorder ? "none" : "1px solid #135f9517 " }}>
      <h5 style={{ fontWeight: "600" }}>{title}</h5>
      <div className="row">
        {filteredFields
          ?.sort((a, b) => a.order - b.order)
          .filter((field) => {
            return Array.isArray(aliases) ? aliases.includes(field.alias) : true;
          })
          .map((field) => {
            if (field.alias === "company") {
              return (
                <div key={field.id} className={`${isW50 ? "w-50" : ""}`}>
                  <StaticCompanySelect control={control} watch={watch} setValue={setValue} getValues={getValues} />
                </div>
              );
            } else {
              return (
                <FiledsRenderReusable
                  key={field.id}
                  field={field}
                  isW50={isW50}
                  setContactImg={setContactImg}
                  setFormChanged={setFormChanged}
                  control={control}
                  watch={watch}
                  errors={errors}
                />
              );
            }
          })}
      </div>
    </div>
  );
};

const FiledsRenderReusable = ({ field, isW50, control, watch, errors }) => {
  const yesLabel = field?.properties?.yes || t("caymland.core.form.yes");
  const noLabel = field?.properties?.no || t("caymland.core.form.no");
  const toggleButtons = [
    { value: 3, label: "X" },
    { value: 0, label: noLabel },
    { value: 1, label: yesLabel },
  ];

  let content;
  let options = [];

  switch (field.type) {
    case "text":
    case "email":
    case "number":
    case "tel":
    case "url":
      content = (
        <div key={field.id} className={`${isW50 ? "w-50" : ""}`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <Text
                name={name}
                type={field.type}
                value={value}
                onChange={onChange}
                label={field.label}
                required={field.isRequired}
                labelHidden={true}
                errors={errors && errors[name]}
              />
            )}
          />
        </div>
      );
      break;

    case "boolean":
      const defaultBooleanValue = field?.defaultValue == 1 ? 1 : field?.defaultValue == 0 ? 0 : 3;
      content = (
        <div key={field.id} className={`${isW50 ? "w-50" : ""}`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <>
                <Label name={name} label={field.label} required={field?.isRequired} errors={errors && errors[name]} />
                <ButtonGroup
                  buttons={toggleButtons}
                  defaultValue={defaultBooleanValue}
                  value={value}
                  onChange={(event) => {
                    const newValue = parseInt(event.target.value, 10);
                    onChange(!isNaN(newValue) ? newValue : event.target.value);
                  }}
                />
              </>
            )}
          />
        </div>
      );
      break;

    case "date":
    case "datetime":
      content = (
        <div key={field.id} className={`${isW50 ? "w-50" : ""}`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <DatePicker
                label={field.label}
                required={field.isRequired}
                name={name}
                isClearable={false}
                value={value}
                showTimeSelect={field.type === "datetime" ? true : false}
                onChange={(date) => {
                  // Format the date to "YYYY-MM-DD HH:mm:ss"
                  const formattedDate =
                    field.type === "datetime"
                      ? moment(date).format("YYYY-MM-DD HH:mm:ss")
                      : moment(date).format("YYYY-MM-DD");
                  onChange(formattedDate); // Use the formatted date
                }}
              />
            )}
          />
        </div>
      );
      break;

    case "country":
      content = (
        <div key={field.id} className={`${isW50 ? "w-50" : ""} mb-20px`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <CountriesSelect
                label={field.label}
                name={name}
                onChange={onChange}
                value={value}
                required={field.isRequired}
                errors={errors && errors[name]}
              />
            )}
          />
        </div>
      );
      break;
    case "locale":
      content = (
        <div key={field.id} className={`${isW50 ? "w-50" : ""} mb-20px`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <LocalesSelect
                label={field.label}
                name={name}
                onChange={onChange}
                value={value}
                required={field.isRequired}
                errors={errors && errors[name]}
              />
            )}
          />
        </div>
      );
      break;
    case "region":
      content = (
        <div key={field.id} className={`${isW50 ? "w-50" : ""}`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <RegionsSelect
                label={field.label}
                name={name}
                onChange={onChange}
                value={value}
                required={field.isRequired}
                errors={errors && errors[name]}
              />
            )}
          />
        </div>
      );

      break;

    case "lookup":
      content = (
        <div key={field.id} className={`${isW50 ? "w-50" : ""}`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => {
              // Split the properties.list string into an array
              const options = field?.properties?.list?.split("|").map((option) => {
                return { label: option, value: option };
              });

              return (
                <Select
                  name={name}
                  label={field.label}
                  isRequired={field.isRequired}
                  options={options} // Use the created options array
                  value={value}
                  onChange={(option) => onChange(option.value)}
                  errors={errors && errors[name]}
                />
              );
            }}
          />
        </div>
      );

      break;

    case "select":
      if (Array.isArray(field?.properties?.list)) {
        if (field.alias === "anrede") {
          options = field.properties.list
            .filter(i => i.label !== "Fräulein")  
            .map((i) => ({ label: i.label, value: i.value }));
        } else {
          options = field.properties.list.map((i) => ({ label: i.label, value: i.value }));
        }
      } else if (typeof field?.properties?.list === "string") {
        options = field.properties.list.split("|").map((i) => ({ label: i, value: i }));
      }

      content = (
        <div key={field.id} className={`${isW50 ? "w-50" : ""}`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <Select
                name={name}
                label={field.label}
                isRequired={field.isRequired}
                isMulti={field.type === "multiselect" ? true : false}
                options={options}
                onChange={(selectedOption) => {
                  onChange(selectedOption ? selectedOption.value : "");
                }}
                value={value}
                errors={errors && errors[name]}
              />
            )}
          />
        </div>
      );
      break;
    case "multiselect":
      if (Array.isArray(field?.properties?.list)) {
        options = field.properties.list.map((i) => ({ label: i.label, value: i.value }));
      } else if (typeof field?.properties?.list === "string") {
        options = field.properties.list.split("|").map((i) => ({ label: i, value: i }));
      }

      content = (
        <div key={field.id} className={`${isW50 ? "w-50" : ""}`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => {
              // Transform the value back to an array of option objects
              const selectedOptions = options.filter((option) => value?.includes(option.value));

              return (
                <Select
                  name={name}
                  label={field.label}
                  isRequired={field.isRequired}
                  errors={errors && errors[name]}
                  isMulti={field.type === "multiselect"}
                  options={options}
                  value={selectedOptions} // Set the selected options as the value
                  onChange={(selectedOptions) => {
                    // Transform the selected options into an array of their values
                    const newValue = selectedOptions.map((option) => option.value);

                    // Call the original onChange method with the new array of values
                    onChange(newValue);
                  }}
                />
              );
            }}
          />
        </div>
      );
      break;

    case "textarea":
      content = (
        <div key={field.id} className="w-50 w-full small-editor">
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <TextArea
                name={name}
                errors={errors && errors[name]}
                label={field.label}
                required={field.isRequired}
                data={value ?? ""}
                onChange={onChange}
              />
            )}
          />
        </div>
      );
      break;

    case "time":
      content = (
        <div key={field.id} className={`${isW50 ? "w-50" : ""} mb-20px`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <TimePicker
                label={name}
                name={field.alias}
                errors={errors && errors[name]}
                value={value}
                required={field.isRequired}
                onChange={onChange}
              />
            )}
          />
        </div>
      );
      break;

    case "timezone":
      content = (
        <div key={field.id} className={`${isW50 ? "w-50" : ""} mb-20px`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <TimezoneSelect
                errors={errors && errors[name]}
                label={field.alias}
                name={name}
                onChange={(selectedOption) => {
                  onChange(selectedOption ? selectedOption.value : "");
                }}
                value={value ? { label: value, value } : null}
                required={field.isRequired}
              />
            )}
          />
        </div>
      );
      break;

    default:
      content = (
        <div className="pb-0 mb-0">
          <p>
            {t("caymland.core.needs.done")} ({field.type})
          </p>
        </div>
      );
  }
  return content;
};

export default FiledsRenderReusable;
