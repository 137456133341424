import Default from "../dashboard/defaultCompo/default";
import Contacts from "../main-menu-sidebar/contacts";
import CustomFields from "../right-menu-components/customFields";
import AddCustomField from "../right-menu-components/customFields/AddCustomField";
import Categories from "../right-menu-components/categories";
import Companies from "../main-menu-sidebar/companies";
import ViewCompany from "../main-menu-sidebar/companies/ViewCompany";
import AddNew from "../main-menu-sidebar/contacts/addNew";
// import ViewContact from "../main-menu-sidebar/contacts/ViewContact";
import Segments from "../main-menu-sidebar/segments";
import AddNewSegment from "../main-menu-sidebar/segments/addNew";
import ViewSegment from "../main-menu-sidebar/segments/ViewSegment";
import EditCloneSegment from "../main-menu-sidebar/segments/EditCloneSegment";
import Assets from "../main-menu-sidebar/Components/Assets/Assets";
import Documentation from "../common/header-component/documentation";
import AddEditAsset from "../main-menu-sidebar/Components/Assets/AddEditAsset";
import ViewAsset from "../main-menu-sidebar/Components/Assets/ViewAsset";
import Forms from "../main-menu-sidebar/Components/Forms/Forms";
import AddNewForm from "../main-menu-sidebar/Components/Forms/AddNewFom";
import ViewForm from "../main-menu-sidebar/Components/Forms/ViewForm";
import FileManagerFunction from "../main-menu-sidebar/Components/fileManager/FileManagerFunction";
import LandingPages from "../main-menu-sidebar/Components/landingPages/LandingPages";
import DynamicContent from "../main-menu-sidebar/Components/dynamicContent/DynamicContent";
import NewLandingPage from "../main-menu-sidebar/Components/landingPages/NewLandingPage";
import Campaigns from "../main-menu-sidebar/campaigns/Campaigns";
import MarketingMessages from "../main-menu-sidebar/channels/marketingMessages/MarketingMessages";
import Emails from "../main-menu-sidebar/channels/emails/Emails";
import FocusItems from "../main-menu-sidebar/channels/focusItems/FocusItems";
import SocialMonitoring from "../main-menu-sidebar/channels/socialMonitoring/SocialMonitoring";
import ManageActions from "../main-menu-sidebar/points/manageActions/ManageActions";
import ManageTriggers from "../main-menu-sidebar/points/manageTriggers/ManageTriggers";
import Stages from "../main-menu-sidebar/stages/Stages";
import Reports from "../main-menu-sidebar/reports/Reports";
import ViewReport from "../main-menu-sidebar/reports/ViewReport";
import AddStage from "../main-menu-sidebar/stages/AddStage";
import AddAction from "../main-menu-sidebar/points/manageActions/AddAction";
import AddTrigger from "../main-menu-sidebar/points/manageTriggers/AddTrigger";
import ViewEmail from "../main-menu-sidebar/channels/emails/ViewEmail";
import AddEmail from "../main-menu-sidebar/channels/emails/AddEmail";
import SQLconditions from "../main-menu-sidebar/sqlConditions/SQLconditions";
import AddSQLCondition from "../main-menu-sidebar/sqlConditions/AddSQLcondition";
import AddDynamicContent from "../main-menu-sidebar/Components/dynamicContent/AddDynamicContent";
import AddCampaign from "../main-menu-sidebar/campaigns/AddCampaign";
import AddCompany from "../main-menu-sidebar/companies/AddCompany";
import Users from "../right-menu-components/users/Users";
import WebHooks from "../right-menu-components/webHooks/WebHooks";
import TagsEditor from "../right-menu-components/tagsEditor/TagsEditor";
import AddUser from "../right-menu-components/users/AddUser";
import EditCloneCompany from "../main-menu-sidebar/companies/EditCloneCompany";
import EditEmail from "../main-menu-sidebar/channels/emails/EditEmail";
import ViewDWC from "../main-menu-sidebar/Components/dynamicContent/ViewDWC";
import ViewCampaign from "../main-menu-sidebar/campaigns/ViewCampaign";
import ViewPage from "../main-menu-sidebar/Components/landingPages/ViewPage";
import FormResults from "../main-menu-sidebar/Components/Forms/Results";
import DataTableToHTMLTable from "../main-menu-sidebar/Components/Forms/Results/HTMLTable";
import AddFocusItems from "../main-menu-sidebar/channels/focusItems/AddFocusItems";
import ViewFocusItem from "../main-menu-sidebar/channels/focusItems/ViewFocusItem";
import AddorEditReport from "../main-menu-sidebar/reports/AddorEditReport";
import AdvancedReportView from "../main-menu-sidebar/channels/emails/viewEmail/AdvancedReportView";
import Main from "../reusableComponents/ImportExport/Main";
import History from "../reusableComponents/ImportExport/History";
import EmailPreview from "../main-menu-sidebar/contacts/viewContact/EmailPreview";
import View from "../reusableComponents/ImportExport/View";
import ExportView from "../reusableComponents/ImportExport/ExportRender";
import ViewHTMLReports from "../main-menu-sidebar/reports/viewReport/ViewHTMLReports";
import PreviewFrom from "../main-menu-sidebar/Components/Forms/ViewForm/PreviewFrom";
import AddEditHooks from "../right-menu-components/webHooks/AddEditHooks";
import ViewHooks from "../right-menu-components/webHooks/ViewHooks";
import EditProfile from "../AccountSettings/EditProfile";
import ViewContact from "../main-menu-sidebar/contacts/ViewContactNew/ViewContact";

import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import LoginLayout from "../common/Layout/LoginLayout";
import Login from "../auth/Login";
import MainLayout from "../common/Layout/MainLayout";
import { t } from "i18next";
import ResetPassword from "../auth/ResetPassword";
import SetNewPassword from "../auth/SetNewPassword";
import EmailTesting from "../main-menu-sidebar/channels/emails/viewEmail/EmailTestingUrl/EmailTesting";
import EmailPreviewHash from "../main-menu-sidebar/contacts/viewContact/EmailPreviewHash";
import Sms from "../main-menu-sidebar/channels/sms/Sms";
import AddEditSMS from "../main-menu-sidebar/channels/sms/AddEditSMS";
import ViewSMS from "../main-menu-sidebar/channels/sms/ViewSMS";
import SendSms from "../main-menu-sidebar/channels/sms/SendSms";
import Brochures from "../main-menu-sidebar/products/Products";
import AddEditProducts from "../main-menu-sidebar/products/AddEditProducts";
import Templates from "../main-menu-sidebar/templetes/Templates";
import AddEditTemplete from "../main-menu-sidebar/templetes/AddEditTemplete";
import Orders from "../main-menu-sidebar/orders/Orders";
import AddEditOrders from "../main-menu-sidebar/orders/AddEditOrders";
import Products from "../main-menu-sidebar/products/Products";
import Mailbox from "../main-menu-sidebar/mailbox/Mailbox";
import Classification from "../right-menu-components/mailboxAdmin/Classification/Classification";
import AddEditClassification from "../right-menu-components/mailboxAdmin/Classification/AddEditClassification";
import Template from "../right-menu-components/mailboxAdmin/Template/Template";
import AddEditTemplate from "../right-menu-components/mailboxAdmin/Template/AddEditTemplate";
import Rule from "../main-menu-sidebar/rules/Rules";
import AddEditRules from "../main-menu-sidebar/rules/AddEditRules";

export const customRoutes = [
  {
    layout: MainLayout,
    routes: [
      // {
      //   title: t("caymland.dashboard.header.index"),
      //   icon: DashboardOutlinedIcon,
      //   path: "dashboard",
      //   element: <Default />,
      //   children: [],
      // },
      {
        title: t("caymland.core.documentation"),
        path: "documentation",
        element: <Documentation />,
        children: [],
      },
      {
        title: t("caymland.lead.leads"),
        icon: PeopleOutlinedIcon,
        path: "contacts",
        element: <Contacts />,
        children: [
          {
            path: "contacts/new",
            element: <AddNew />,
          },
          {
            path: "contacts/edit/:id",
            element: <AddNew />,
          },
          {
            path: "contacts/clone/:id",
            element: <AddNew />,
          },
          {
            path: "contacts/view/:id",
            element: <ViewContact />,
          },
          {
            path: "contacts/import/new",
            element: <Main />,
          },
          {
            path: "contacts/import",
            element: <History />,
          },
          {
            path: `contacts/import/view/:id`,
            element: <View />,
          },
          {
            path: "contacts/export/custom",
            element: <ExportView />,
          },
          // {
          //   path: "contacts/fields",
          //   element: <CustomFields />,
          // },
          {
            path: "contacts/fields/edit/:id",
            element: <AddCustomField />,
          },
          {
            path: "contacts/fields/clone",
            element: <AddCustomField />,
          },
          {
            path: "contacts/fields/new",
            element: <AddCustomField />,
          },
        ],
      },
      {
        title: t("caymland.lead.tags"),
        path: "profile",
        element: <></>,
        children: [
          {
            path: "edit-profile",
            element: <EditProfile />,
          },
        ],
      },
      // {
      //   path: "products",
      //   element: <Products />,
      //   children: [
      //     {
      //       path: "products/new",
      //       element: <AddEditProducts />,
      //     },
      //     {
      //       path: "products/clone",
      //       element: <AddEditProducts />,
      //     },
      //     {
      //       path: "products/edit/:id",
      //       element: <AddEditProducts />,
      //     },
      //   ],
      // },
      // {
      //   path: "templates",
      //   element: <Templates />,
      //   children: [
      //     {
      //       path: "templates/new",
      //       element: <AddEditTemplete />,
      //     },
      //     {
      //       path: "templates/clone",
      //       element: <AddEditTemplete />,
      //     },
      //     {
      //       path: "templates/edit/:id",
      //       element: <AddEditTemplete />,
      //     },
      //   ],
      // },
      {
        path: "orders",
        element: <Orders />,
        children: [
          {
            path: "orders/new",
            element: <AddEditOrders />,
          },
          {
            path: "orders/clone",
            element: <AddEditOrders />,
          },
          {
            path: "orders/edit/:id",
            element: <AddEditOrders />,
          },
        ],
      },
      {
        path: "mailbox",
        element: <Mailbox />,
      },
      {
        title: t("caymland.company"),
        icon: BusinessOutlinedIcon,
        path: "companies",
        element: <Companies />,
        children: [
          {
            path: "companies/new",
            element: <AddCompany />,
          },
          {
            path: "companies/edit/:id",
            element: <AddCompany />,
          },
          {
            path: "companies/clone",
            element: <AddCompany />,
          },
          {
            path: "companies/view/:id",
            element: <ViewCompany />,
          },
          {
            path: "companies/import/new",
            element: <Main />,
          },
          {
            path: "companies/import/history",
            element: <History />,
          },
          {
            path: "companies/import/history/view/:id",
            element: <View />,
          },
        ],
      },
      // {
      //   title: "Categories",
      //   icon: CategoryOutlinedIcon,
      //   path: "categories",
      //   element: <Categories />,
      //   children: [],
      // },
      // {
      //   title: t("caymland.category.header.index"),
      //   icon: FilterAltOutlinedIcon,
      //   path: "segments",
      //   element: <Segments />,
      //   children: [
      //     {
      //       path: "segments/new",
      //       element: <AddNewSegment />,
      //     },
      //     {
      //       path: "segments/view/:id",
      //       element: <ViewSegment />,
      //     },
      //     {
      //       path: "segments/edit/:id",
      //       element: <EditCloneSegment />,
      //     },
      //     {
      //       path: "segments/clone",
      //       element: <EditCloneSegment />,
      //     },
      //   ],
      // },
      // {
      //   path: "assets",
      //   element: <Assets />,
      //   children: [
      //     {
      //       path: "assets/new",
      //       element: <AddEditAsset />,
      //     },
      //     {
      //       path: "assets/view/:id",
      //       element: <ViewAsset />,
      //     },
      //     {
      //       path: "assets/edit/:id",
      //       element: <AddEditAsset />,
      //     },
      //     {
      //       path: "assets/clone",
      //       element: <AddEditAsset />,
      //     },
      //   ],
      // },
      // {
      //   path: "sms",
      //   element: <Sms />,
      //   children: [
      //     {
      //       path: "sms/new",
      //       element: <AddEditSMS />,
      //     },
      //     {
      //       path: "sms/edit/:id",
      //       element: <AddEditSMS />,
      //     },
      //     {
      //       path: "sms/view/:id",
      //       element: <ViewSMS />,
      //     },
      //     {
      //       path: "sms/clone",
      //       element: <AddEditSMS />,
      //     },
      //     {
      //       path: "sms/send/:id",
      //       element: <SendSms />,
      //     },
      //   ],
      // },
      // {
      //   path: "forms",
      //   element: <Forms />,
      //   children: [
      //     {
      //       path: "forms/new",
      //       element: <AddNewForm />,
      //     },
      //     {
      //       path: "forms/view/:id",
      //       element: <ViewForm />,
      //     },
      //     {
      //       path: "forms/edit/:id",
      //       element: <AddNewForm />,
      //     },
      //     {
      //       path: "forms/clone",
      //       element: <AddNewForm />,
      //     },
      //     {
      //       path: "forms/results/:id",
      //       element: <FormResults />,
      //     },
      //     {
      //       path: "forms/results/:id/export/html",
      //       element: <DataTableToHTMLTable />,
      //     },
      //   ],
      // },
      // {
      //   path: "file_manager",
      //   element: <FileManagerFunction />,
      //   children: [],
      // },
      // {
      //   path: "pages",
      //   element: <LandingPages />,
      //   children: [
      //     {
      //       path: "pages/new",
      //       element: <NewLandingPage />,
      //     },
      //     {
      //       path: "pages/edit/:id",
      //       element: <NewLandingPage />,
      //     },
      //     {
      //       path: "pages/view/:id",
      //       element: <ViewPage />,
      //     },
      //     {
      //       path: "pages/clone",
      //       element: <NewLandingPage />,
      //     },
      //   ],
      // },
      // {
      //   path: "messages",
      //   element: <MarketingMessages />,
      //   children: [],
      // },
      // {
      //   path: "monitoring",
      //   element: <SocialMonitoring />,
      //   children: [],
      // },
      // {
      //   path: "focus",
      //   element: <FocusItems />,
      //   children: [
      //     {
      //       path: "focus/new",
      //       element: <AddFocusItems />,
      //     },
      //     {
      //       path: "focus/edit/:id",
      //       element: <AddFocusItems />,
      //     },
      //     {
      //       path: "focus/view/:id",
      //       element: <ViewFocusItem />,
      //     },
      //     {
      //       path: "focus/clone",
      //       element: <AddFocusItems />,
      //     },
      //   ],
      // },
      // {
      //   path: "stages",
      //   element: <Stages />,
      //   children: [
      //     {
      //       path: "stages/new",
      //       element: <AddStage />,
      //     },
      //     {
      //       path: "stages/edit/:id",
      //       element: <AddStage />,
      //     },
      //     {
      //       path: "stages/clone",
      //       element: <AddStage />,
      //     },
      //   ],
      // },
      // {
      //   path: "reports",
      //   element: <Reports />,
      //   children: [
      //     {
      //       path: "reports/new",
      //       element: <AddorEditReport />,
      //     },
      //     {
      //       path: "reports/clone",
      //       element: <AddorEditReport />,
      //     },
      //     {
      //       path: "reports/edit/:id",
      //       element: <AddorEditReport />,
      //     },
      //     {
      //       path: "reports/view/:id",
      //       element: <ViewReport />,
      //     },
      //     {
      //       path: "reports/view/:id/html/export",
      //       element: <ViewHTMLReports />,
      //     },
      //   ],
      // },
      // {
      //   path: "points",
      //   element: <ManageActions />,
      //   children: [
      //     {
      //       path: "points/new",
      //       element: <AddAction />,
      //     },
      //     {
      //       path: "points/edit/:id",
      //       element: <AddAction />,
      //     },
      //     {
      //       path: "points/clone",
      //       element: <AddAction />,
      //     },
      //     {
      //       path: "triggers",
      //       element: <ManageTriggers />,
      //     },
      //     {
      //       path: "triggers/new",
      //       element: <AddTrigger />,
      //     },
      //     {
      //       path: "triggers/edit/:id",
      //       element: <AddTrigger />,
      //     },
      //     {
      //       path: "triggers/clone",
      //       element: <AddTrigger />,
      //     },
      //   ],
      // },
      // {
      //   path: "emails",
      //   element: <Emails />,
      //   children: [
      //     {
      //       path: "emails/new",
      //       element: <AddEmail />,
      //     },
      //     {
      //       path: "emails/edit/:id",
      //       element: <EditEmail />,
      //     },
      //     {
      //       path: "emails/clone",
      //       element: <EditEmail />,
      //     },
      //     {
      //       path: "emails/view/:id",
      //       element: <ViewEmail />,
      //     },
      //     {
      //       path: "emails/advanced-report/:id",
      //       element: <AdvancedReportView />,
      //     },
      //     {
      //       path: "email/testing/:id",
      //       element: <EmailTesting />,
      //     },
      //   ],
      // },
      // {
      //   path: "sqlConditions",
      //   element: <SQLconditions />,
      //   children: [
      //     {
      //       path: "sqlConditions/new",
      //       element: <AddSQLCondition />,
      //     },
      //   ],
      // },
      // {
      //   path: "dwc",
      //   element: <DynamicContent />,
      //   children: [
      //     {
      //       path: "dwc/new",
      //       element: <AddDynamicContent />,
      //     },
      //     {
      //       path: "dwc/edit/:id",
      //       element: <AddDynamicContent />,
      //     },
      //     {
      //       path: "dwc/clone",
      //       element: <AddDynamicContent />,
      //     },
      //     {
      //       path: "dwc/view/:id",
      //       element: <ViewDWC />,
      //     },
      //   ],
      // },
      // {
      //   path: "campaigns",
      //   element: <Campaigns />,
      //   children: [
      //     {
      //       path: "campaigns/new",
      //       element: <AddCampaign />,
      //     },
      //     {
      //       path: "campaigns/edit/:id",
      //       element: <AddCampaign />,
      //     },
      //     {
      //       path: "campaigns/clone",
      //       element: <AddCampaign />,
      //     },
      //     {
      //       path: "campaigns/view/:id",
      //       element: <ViewCampaign />,
      //     },
      //   ],
      // },
      // {
      //     path: 'themes',
      //     element: <Themes/>,
      //     children: []
      // },
      // {
      //   path: "users",
      //   element: <Users />,
      //   children: [
      //     {
      //       path: "users/new",
      //       element: <AddUser />,
      //     },
      //     {
      //       path: "users/edit/:id",
      //       element: <AddUser />,
      //     },
      //   ],
      // },
      // {
      //   path: "webhooks",
      //   element: <WebHooks />,
      //   children: [
      //     {
      //       path: "webhooks/new",
      //       element: <AddEditHooks />,
      //     },
      //     {
      //       path: "webhooks/edit/:id",
      //       element: <AddEditHooks />,
      //     },
      //     {
      //       path: "webhooks/clone",
      //       element: <AddEditHooks />,
      //     },
      //     {
      //       path: "webhooks/view/:id",
      //       element: <ViewHooks />,
      //     },
      //   ],
      // },
      // {
      //   path: "mailbox-admin/classification",
      //   element: <Classification />,
      //   children: [
      //     {
      //       path: "mailbox-admin/classification/new",
      //       element: <AddEditClassification />,
      //     },
      //     {
      //       path: "mailbox-admin/classification/edit/:id",
      //       element: <AddEditClassification />,
      //     },
      //     {
      //       path: "mailbox-admin/classification/clone",
      //       element: <AddEditClassification />,
      //     },
      //   ],
      // },
      // {
      //   path: "mailbox-admin/rules",
      //   element: <Rule />,
      //   children: [
      //     {
      //       path: "mailbox-admin/rules/new",
      //       element: <AddEditRules />,
      //     },
      //     {
      //       path: "mailbox-admin/rules/edit/:id",
      //       element: <AddEditRules />,
      //     },
      //     {
      //       path: "mailbox-admin/rules/clone",
      //       element: <AddEditRules />,
      //     },
      //   ],
      // },
      // {
      //   path: "mailbox-admin/template",
      //   element: <Template />,
      //   children: [
      //     {
      //       path: "mailbox-admin/template/new",
      //       element: <AddEditTemplate />,
      //     },
      //     {
      //       path: "mailbox-admin/template/edit/:id",
      //       element: <AddEditTemplate />,
      //     },
      //     {
      //       path: "mailbox-admin/template/clone",
      //       element: <AddEditTemplate />,
      //     },
      //   ],
      // },
      // {
      //   path: "tags",
      //   element: <TagsEditor />,
      //   children: [],
      // },
    ],
  },
  {
    layout: LoginLayout,
    routes: [
      {
        name: "login",
        title: t("caymland.core.login.page"),
        element: <Login />,
        path: "login",
        isPublic: true,
      },
      {
        name: "reset",
        title: "reset",
        path: "reset",
        element: <ResetPassword />,
        isPublic: true,
      },
      {
        name: "set",
        title: "set",
        path: "passwordresetconfirmV2",
        element: <SetNewPassword />,
        isPublic: true,
      },
      // {
      //   path: "email/preview/:id",
      //   element: <EmailPreview />,
      // },

      // {
      //   path: "form/preview/:id",
      //   element: <PreviewFrom />,
      // },
      // {
      //   path: "email/view/:id",
      //   element: <EmailPreviewHash />,
      // },
    ],
  },
];
