import React, { useMemo } from "react";
import { Form } from "reactstrap";
import Loading from "../../reusableComponents/loading";
import "react-dropzone-uploader/dist/styles.css";
import { FieldsRenderSection } from "../contacts/FieldsRenderer";

function DynamicTab({ currentTab, contactFields, control, errors, isLoading }) {

  const filteredFields = useMemo(() => {
    return [...contactFields].sort((a, b) => a.order - b.order)
                            .filter((x) => x.isPublished && x.group === currentTab && x.isVisible === true );
  }, [contactFields, currentTab]);

  return (
    <Form>
      <div>
        {isLoading ? (
          <div style={{ display: "grid", placeItems: "center" }}>
            <Loading />
          </div>
        ) : (
          <div className="gap-10" style={{ display: "grid", padding: "10px" }}>
            <FieldsRenderSection errors={errors} filteredFields={filteredFields} isW50={false} control={control} />
          </div>
        )}
      </div>
    </Form>
  );
}

export default DynamicTab;
