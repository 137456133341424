import React, { Fragment, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { t } from "i18next";
import { Text } from "../reusableComponents/Inputs";
import { SlideAlert } from "../reusableComponents/Alerts";

const SetNewPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [wrongCredentials, setWrongCredentials] = useState(false);
  const [passwordTooShort, setPasswordTooShort] = useState(false);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [backgorundToast, setBackgroundToast] = useState("");
  const location = useLocation(); // Use useLocation hook to get location object
  const queryParams = new URLSearchParams(location.search); // Parse the query string
  const token = queryParams.get("token"); // Extract the token from the query string

  const resetPassword = () => {
    if (password !== confirmPassword) {
      setWrongCredentials(true);
    } else if (password.length < 6) {
      setPasswordTooShort(true);
    } else {
      axios
        .post("https://dev.m-4.ch/passwordresetconfirmV2", {
          password: password,
          password_confirm: confirmPassword,
          token: token,
        })
        .then((response) => {
          console.log("Password reset successful");
          setWrongCredentials(false);
          setPasswordTooShort(false);
          if (response.status === 200) {
            setShow(true);
            setMessage(t("Password has been successfully reset."));
            setBackgroundToast("1");
            setTimeout(() => {
              setShow(false);
              setMessage("");
            }, 6000);
          }
          // navigate("/login");
        })
        .catch((error) => {
          console.error("Password reset failed", error);
        });
    }
  };

  // const handlePasswordChange = (e) => {
  //   const newPassword = e.target.value;
  //   setPassword(newPassword);
  //   if (newPassword.length >= 6 && passwordTooShort) {
  //     setPasswordTooShort(false);
  //   }
  // };

  return (
    <Fragment>
      <SlideAlert background={backgorundToast} message={message} show={show} />
      <div className="page-wrapper">
        <div className="auth-bg">
          <div className="authentication-box">
            <div className="text-center">
              <img src="https://dev.m-4.ch/media/images/sidebar_logo.png" alt="" />
            </div>
            <div className="card mt-4">
              <div className="card-body">
                <div className="text-center">
                  <h4>{t("Reset Password")}</h4>
                </div>

                <div style={{ marginTop: "20px" }}>
                  <Text
                    id="password"
                    className="jump-input"
                    type="password"
                    placeholder=" "
                    value={password}
                    label={t("New Password")}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div style={{ marginTop: "20px" }}>
                  <Text
                    id="confirmPassword"
                    className="jump-input"
                    type="password"
                    placeholder=" "
                    value={confirmPassword}
                    label={t("Confirm Password")}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>

                {wrongCredentials && (
                  <div>
                    <p style={{ color: "red", marginBottom: "15px" }}>{t("Passwords don't match!")}</p>
                  </div>
                )}

                {passwordTooShort && (
                  <div>
                    <p style={{ color: "red", marginBottom: "15px" }}>{t("Password must be at least 6 characters!")}</p>
                  </div>
                )}

                <div className="forgotPassword">
                  <Link to="/login">{t("Back to Login")}</Link>
                </div>
                <div className="form-group form-row mt-3 mb-0 d-grid">
                  <button className="btn active-btn" onClick={resetPassword}>
                    {t("Reset Password")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SetNewPassword;
