import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { IconButton, Tooltip, Zoom } from "@mui/material";
import { RiQuestionFill } from "react-icons/ri";
import Label from "./Label";
import { t } from "i18next";

export default function ({
  name,
  value,
  onChange,
  onBlur,
  label,
  tooltip,
  required = false,
  type = "text",
  size = "small",
  errors = false,
  iconStart,
  placeholder,
  disabled,
  defaultValue,
  accept,
  extraText,
  wFull,
  marginBottom = false,
  labelHidden = false,
  autoFocus = false,
  params,
}) {
  return (
    <>
      <div className={`${marginBottom ? marginBottom : "mb-20px"}  ${wFull ? wFull : ""}`}>
        {labelHidden && <Label name={name} label={"."} labelHidden={labelHidden} />}
        <TextField
          {...params}
          id={name}
          name={name}
          value={value || ""}
          defaultValue={defaultValue}
          placeholder={placeholder}
          size="small"
          variant="outlined"
          accept={accept}
          label={label}
          autoFocus={autoFocus}
          className="custom-textfield"
          required={required}
          type={type}
          helperText={errors.message}
          error={errors}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          fullWidth
          FormHelperTextProps={{
            sx: {
              textAlign: "right",
              width: "100%",
              margin: 0,
              color: "rgba(255, 102, 102, 0.5)",
            },
          }}
          sx={{
            "& .MuiInputBase-root": {
              height: "40px",
              alignItems: "center",
              backgroundColor: "#fff",
            },
            "& .MuiInputBase-input": {
              fontSize: "15px",
              height: "40px",
              padding: "0 14px",
              boxSizing: "border-box",
            },
            "& .MuiInputBase-input::placeholder": {
              fontSize: "15px",
            },
            "& .MuiInputLabel-root": {
              color: "#6C757D",
              fontSize: "15px",
              transform: "translate(14px, 10px) scale(1)",
            },
            "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
              transform: "translate(14px, -6px) scale(0.75)",
            },
            "& .MuiFormHelperText-root": {
              position: "absolute",
              bottom: "100%",
              left: "0",
              fontSize: "11px",
              color: "rgba(255, 0, 0, 0.7)",
            },
            "& .Mui-focused .MuiInputLabel-root": {
              color: "#6C757D",
            },
            "&:hover": {
              transform: "scale(1.003)",
              boxShadow: "0 6px 20px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.4s ease, box-shadow 0.4s ease",
            },
          }}
          InputProps={{
            endAdornment:
              tooltip && tooltip.length ? (
                <Tooltip
                  sx={{
                    "& .MuiTooltip-tooltip": {
                      fontSize: "15px",
                    },
                  }}
                  title={tooltip}
                  arrow
                  followCursor
                  placement="top"
                  TransitionComponent={Zoom}
                >
                  <IconButton style={{ cursor: "help", padding: 4 }}>
                    <RiQuestionFill size={16} />
                  </IconButton>
                </Tooltip>
              ) : extraText && extraText.length ? (
                <span style={{ fontSize: "15px", color: "#6C757D" }}>{extraText}</span>
              ) : null,
            startAdornment: iconStart ? <InputAdornment position="start">{iconStart}</InputAdornment> : null,
          }}
        />
      </div>
    </>
  );
}
