import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQuery } from "../utils/customFetchBaseQuery";
import { customTransformResponse } from "../utils/customTransformResponse";
import qs from "qs";
import moment from "moment";

export const segmentsApi = createApi({
  reducerPath: "segmentsApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["Segments"],
  keepUnusedDataFor: 30,
  endpoints: (build) => ({
    //SEGMENTS
    getSegments: build.query({
      query: (q) =>
        `/api/segments?${qs.stringify({
          ...q,
          ...{ start: q.pageIndex * q.pageSize, limit: q.pageSize },
        })}`,
      keepUnusedDataFor: false,
      providesTags: ["Segments"],
      transformResponse: customTransformResponse,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getSegmentsOptions: build.query({
      query: (q) => `/api/segments/select`,
      keepUnusedDataFor: false,
      providesTags: ["Segments"],
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getSegmentsLeads: build.query({
      query: ({ query, id }) =>
        `/api/segments/${id}/leads?${qs.stringify({
          ...query,
          ...{ start: query.pageIndex * query.pageSize, limit: query.pageSize },
        })}`,
      keepUnusedDataFor: false,
      providesTags: ["Segments"],
      transformResponse: customTransformResponse,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    addSegments: build.mutation({
      query: (body) => ({
        url: `api/segments/new`,
        method: "POST",
        body,
      }),
    }),
    updateSegments: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/api/segments/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: (result, error, id) => [{ type: "Segments", id }],
    }),
    deleteSegment: build.mutation({
      query: (id) => ({
        url: `/api/segments/${id}/delete`,
        method: "DELETE",
      }),
    }),
    addSegmentsCount: build.mutation({
      query: (body) => ({
        url: `api/segments/segmentcount`,
        method: "POST",
        body,
      }),
    }),
    getSegmentsExtras: build.query({
      query: ({ id, dateFrom, dateTo }) => {
        const params = qs.stringify({
          dateFrom: moment(dateFrom).format("YYYY-MM-DD"),
          dateTo: moment(dateTo).format("YYYY-MM-DD"),
        });
        return `api/segments/${id}/leads/daterange?${params}`;
      },
      providesTags: (result, error, { id }) => [{ type: "Segments", id }],
    }),

    getAddEditSegments: build.query({
      queryFn: async (id, { signal, dispatch, getState }, extraOptions, baseQuery) => {
        if (id) {
          let segmentById = await baseQuery({ url: `/api/segments/${id}` });
          let segmentFilters = await baseQuery({
            url: `/api/segments/filters`,
          });

          const segment = segmentById?.data;
          const segmentFilter = segmentFilters?.data;

          const data = {
            segment,
            segmentFilter,
          };

          return { data: data };
        } else {
          let segmentFilters = await baseQuery({
            url: `/api/segments/filters`,
          });
          const segmentFilter = segmentFilters.data;

          const data = {
            segmentFilter,
          };

          return { data: data };
        }
      },
      providesTags: (result, error, id) => [{ type: "Segments", id }],
    }),
    getFilterOptions: build.query({
      query: (q) => `/api/segments/filters`,
      transformResponse: customTransformResponse,
    }),
    getDevices: build.query({
      query: (q) => `/api/settings/devices`,
      providesTags: ["Segments"],
    }),
    //FOLDERS
    //GET FOLDERS
    getFolderSegments: build.query({
      query: (q) => `/api/folders?limit=100&where[0][col]=source&where[0][expr]=eq&where[0][val]=segments`,
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },

      forceRefetch({ currentArg, previousArg }, arg) {
        return currentArg !== previousArg;
      },
    }),
    //ADD FOLDER
    addFolderSegments: build.mutation({
      query: (body) => ({
        url: `api/folders/new`,
        method: "POST",
        body,
      }),
    }),
    //EDIT FOLDER
    updateFolderSegments: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `api/folders/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Segments", id }],
    }),
    //DELETE FOLDER
    deleteFolderSegments: build.mutation({
      query: (id) => ({
        url: `/api/folders/${id}/delete`,
        method: "DELETE",
      }),
    }),
    //MOVE CAMPAIGN TO FOLDER
    updateSegmentsToFolders: build.mutation({
      query: ({ id, itemId, ...patch }) => ({
        url: `api/folders/${id}/segments/${itemId}`,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Segments", id }],
    }),
    //REMOVE CAMPAIGN TO FOLDER
    removeSegmentsToFolders: build.mutation({
      query: ({ id, itemId, ...patch }) => ({
        url: `api/folders/${id}/segments/${itemId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Segments", id }],
    }),
    //Get Segments for Select
    getSelectSegments: build.query({
      query: (type) => `/api/segments?where[0][expr]=eq&where[0][col]=isPublished&where[0][val]=1`,
      providesTags: ["Segments"],
    }),
    getRebuildSegments: build.mutation({
      query: ({ id }) => ({
        url: `api/segments/${id}/rebuild`,
        method: "POST",
      }),
    }),
    getUsages: build.query({
      query: (segmentId) => `api/segments/${segmentId}/usage`,
      transformResponse: customTransformResponse,
      providesTags: (result, error, segmentId) => [{ type: "Segments", id: segmentId }],
    }),
  }),
});

export const {
  useGetSegmentsQuery,
  useGetDevicesQuery,
  useUpdateSegmentsMutation,
  useDeleteSegmentMutation,
  useAddSegmentsMutation,
  useAddSegmentsCountMutation,
  useGetFilterOptionsQuery,
  useGetSegmentsLeadsQuery,
  useGetUsagesQuery,
  useGetSegmentsOptionsQuery,
  //GET SELECT SEGMENTS
  useGetSelectSegmentsQuery,
  useGetAddEditSegmentsQuery,
  //VIEW SEGMENTS
  useGetSegmentsExtrasQuery,
  //FOLDERS
  useGetFolderSegmentsQuery,
  useUpdateFolderSegmentsMutation,
  useDeleteFolderSegmentsMutation,
  useAddFolderSegmentsMutation,
  useUpdateSegmentsToFoldersMutation,
  useRemoveSegmentsToFoldersMutation,
  //REBUILD
  useGetRebuildSegmentsMutation,
} = segmentsApi;
