import React, { useEffect, useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { FaBuilding } from "react-icons/fa";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import ModalForMerge from "./ModalForMerge";
import Button from "./Button";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function Merge(props) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <ModalForMerge
        handleClose={handleClose}
        handleShow={handleShow}
        name={props.name}
        show={show}
        company={props.company}
      />
      <Button
       color="customGrey"
        buttons={[
          {
            name: t("caymland.lead.merge"),
            title: !props.noText && t("caymland.lead.merge"),
            icon: props.company ? (
              <Tooltip title={`${t("caymland.lead.merge")} ${t("caymland.company.field.header.company")}`} arrow>
                {" "}
                <BusinessOutlinedIcon size={15} />{" "}
              </Tooltip>
            ) : (
              <Tooltip title={`${t("caymland.lead.merge")} ${t("caymland.lead.lead")}`} arrow>
                <PeopleOutlinedIcon size={15} />
              </Tooltip>
            ),
            onClick: () => handleShow(),
          },
        ]}
      />
    </>
  );
}
