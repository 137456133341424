import { t } from "i18next";
import React from "react";

function ViewhHistoryDetails({
  headersString,
  parserPropertiesString,
  importView,
  getDuration,
  importSpeed,
  defaultOptionsString,
  progressDisplay,
  mappedFieldsString,
  formatDateTime,
  getStatusColor,
}) {
  // Assuming getStatusColor returns an object with color and label properties
  const statusInfo = importView ? getStatusColor(importView.importData?.status) : { color: "#fff", label: "N/A" };

  const details = [
    { label: t("caymland.lead.import.csv.headers"), value: headersString },
    { label: t("caymland.lead.import.csv.parser.config"), value: parserPropertiesString },
    { label: t("caymland.showare.field.created_by"), value: importView?.importData?.createdByUser },
    { label: t("caymland.core.created"), value: formatDateTime(importView?.dateStarted) },
    { label: t("caymland.showare.field.modified_by"), value: importView?.importData?.modifiedByUser },
    {
      label: t("caymland.ip.organisations.last-modified.20"),
      value: formatDateTime(importView?.importData?.dateModified),
    },
    { label: t("caymland.core.id"), value: importView?.importData?.id },
    { label: t("caymland.lead.import.source.file"), value: importView?.importData?.originalFile },
    {
      label: t("caymland.brochures.status.label"),
      value: statusInfo.label,
      style: { backgroundColor: statusInfo.color, color: "#FFF" },
    },
    { label: t("caymland.lead.import.line.count"), value: importView?.importData?.lineCount },
    { label: t("caymland.lead.import.date.started"), value: formatDateTime(importView?.importData?.dateStarted) },
    { label: t("caymland.lead.import.date.ended"), value: formatDateTime(importView?.importData?.dateEnded) },
    { label: t("caymland.lead.import.runtime"), value: getDuration(importView?.importData) },
    { label: t("caymland.lead.import.speed"), value: importSpeed },
    { label: t("caymland.lead.import.progress"), value: progressDisplay },
    { label: t("caymland.lead.import.mapped.fields"), value: mappedFieldsString },
    { label: t("caymland.lead.import.default.options"), value: defaultOptionsString },
    // Add other details as needed
  ];

  return (
    <div className="details-container-accordion">
      {details.map((item, index) => (
        <div key={index} className="details-item gap-5px" style={item.style || {}}>
          <span className="details-label">{item.label}</span>:<span className="details-value">{item.value}</span>
        </div>
      ))}
    </div>
  );
}

export default ViewhHistoryDetails;
