const Progress = (props) => {
  const insertedCount = props?.insertedCount;
  const ignoredCount = props?.ignoredCount;
  const updatedCount = props?.updatedCount;
  const lineCount = props?.lineCount;

  const totalProcessed = insertedCount + updatedCount + ignoredCount;
  const progressPercentage = (totalProcessed / lineCount) * 100;

  let progressDisplay;

  if (isNaN(progressPercentage) || progressPercentage === 0) {
    progressDisplay = "0";
  } else if (Math.floor(progressPercentage) === progressPercentage) {
    progressDisplay = `${progressPercentage}`;
  } else {
    progressDisplay = progressPercentage.toFixed(2);
  }

  return (
    <div className="d-flex items-center justify-center">
      <span
        style={{
          display: "flex",
          color: "black",
          alignItems: "center",
          justifyContent: "flex-end",
          width: "2.5em",
        }}
      >
        {progressDisplay}%
      </span>
    </div>
  );
};

export default Progress;
