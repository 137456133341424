import React, { Fragment, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../reusableComponents/Header/Header";
import { useGetExportsQuery, useUpdateImportMutation } from "../../../redux/api/companiesApi";
import CustomTable from "../CustomTable/CustomTable";
import { ActionButtons } from "../tableComponents/ActionButtons";
import { Status, SourceFile } from "./HelplerExport";
import { Runtime, DateAdded, TotalRows } from "./HelperHistory";
import { useTranslation } from "react-i18next";
import { FaRegTimesCircle } from "react-icons/fa";

const ExportRender = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: 30,
    orderByDir: "DESC",
    orderBy: "id",
  });
  const { data, refetch, isLoading } = useGetExportsQuery(query);
  const [updateImport] = useUpdateImportMutation();

  const handleTogglePublished = async (data) => {
    const updatedData = { ...data, isPublished: !data.isPublished };
    try {
      const result = await updateImport(updatedData).unwrap();
      await refetch();
      setAlertMessage(`${data?.originalFile} has been updated successfully!`);
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 7500);
    } catch (error) {
      console.log("Error: ", error);
      setAlertMessage(`There was an error updating!`);
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 7500);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "status",
        header: `${t("caymland.lead.import.status")}`,
        size: 80,
        enableColumnActions: false,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return <Status {...rowData} />;
        },
      },
      {
        accessorKey: "originalFile",
        header: `${t("caymland.lead.import.source.file")}`,
        enableColumnActions: false,
        size: 100,
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return <SourceFile {...rowData} handleTogglePublished={handleTogglePublished} />;
        },
      },
      {
        accessorKey: "Runtime",
        header: `${t("caymland.lead.import.runtime")}`,
        enableColumnActions: false,
        size: 80,
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return <Runtime {...rowData} />;
        },
      },
      {
        accessorKey: "totalRows",
        header: `${t("caymland.lead.import.line.count")}`,
        enableColumnActions: false,
        size: 80,
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return <TotalRows {...rowData} />;
        },
      },
      {
        accessorKey: "dataAdded",
        header: `${t("caymland.core.date.added")}`,
        enableColumnActions: false,
        size: 80,
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return <DateAdded {...rowData} />;
        },
      },
    ],
    []
  );

  const actionButtons = ActionButtons({
    link: "",
  });

  return (
    <Fragment>
      <Header
        buttons={[
          {
            name: "Close",
            title: t("caymland.core.close"),
            icon: <FaRegTimesCircle size={15} />,
            onClick: () => navigate("/contacts"),
          },
        ]}
        title={t("caymland.core.export.history")}
        loading={isLoading}
        showAlert={showAlert}
        alertMessage={alertMessage}
        setShowAlert={setShowAlert}
      />
      <CustomTable
        data={data}
        columns={columns}
        query={query}
        setQuery={setQuery}
        actions={false}
        // actionButtons={actionButtons}
        setSelectedRowIds={setSelectedRowIds}
        selectedRowIds={selectedRowIds}
        linkNavigate={null}
      />
    </Fragment>
  );
};

export default ExportRender;
