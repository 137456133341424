import DatePicker from "react-datepicker";
import React from "react";
import { Label } from "./index";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

const CustomDatePicker = ({
  name,
  value,
  onChange,
  label,
  tooltip,
  required = false,
  minDate,
  maxDate,
  showTimeSelect = true,
  isClearable = false,
  isLoading = false,
  disabled = false,
  placeholderText,
  myBorder,
  noBorderRadius,
  marginBottom = true,
  viewEdit = false,
  extraStyle,
  timeFormat = "HH:mm",
  errors,
}) => {
  const dateFormat = showTimeSelect ? "dd.MM.yyyy HH:mm" : "dd.MM.yyyy";

  return (
    <div className={`w-full ${marginBottom ? "mb-20px" : ""}`}>
      {label && label.length && (
        <Label name={name} label={label} tooltip={tooltip} required={required} errors={errors} />
      )}
      <DatePicker
        name={name}
        placeholderText={placeholderText}
        isLoading={isLoading}
        disabled={disabled}
        className={`form-control   datepicker-placeholder hover-effect ${
          viewEdit ? "h-32 inherit font-13 noBorders" : "h-40 white font-15"
        } ${myBorder ? myBorder : ""} ${noBorderRadius ? "noBorderRadius" : "borderRadius"} ${extraStyle?.className}`}
        dateFormat={dateFormat}
        showTimeSelect={showTimeSelect}
        timeFormat={timeFormat}
        minDate={minDate}
        maxDate={maxDate}
        showYearDropdown
        showMonthDropdown
        isClearable={isClearable}
        required={required}
        selected={value ? new Date(value) : null}
        onChange={(e) => onChange(e ? new Date(e) : null)}
      />
    </div>
  );
};

export default CustomDatePicker;
