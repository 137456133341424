import React, { useState, useEffect, useMemo, Fragment } from "react";
import SendEmailModal from "./SendEmailModal";
import { useLocation } from "react-router-dom";
import { FaUserSecret } from "react-icons/fa";
import Moment from "moment";
import "moment/locale/de";
import { Error } from "../../reusableComponents/Alerts";
import SweetAlert from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { AiOutlineSave } from "react-icons/ai";
import ExportModal from "../../reusableComponents/ImportExport/ExportModal";
import { BiAddToQueue, BiImport, BiHistory } from "react-icons/bi";
import { CgExport } from "react-icons/cg";
import Header from "../../reusableComponents/Header/Header";
import CustomTable from "../../reusableComponents/CustomTable/CustomTable";
import { LinkComponent } from "../../reusableComponents/tableComponents/LinkComponent";
import { ActionButtons } from "../../reusableComponents/tableComponents/ActionButtons";
import { useGetContactsQuery, useDeleteContactsMutation, contactsApi } from "../../../redux/api/contactsApi";
import { ActionBatchButtons } from "../../reusableComponents/tableComponents/ActionBatchButtons";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CampaignBatchModal from "./ContactBatchModals/CampaignBatchModal";
import CategoriesBatchModal from "./ContactBatchModals/CategoriesBatchModal";
import SegmentBatchModal from "./ContactBatchModals/SegmentsBatchModal";
import StageBatchModal from "./ContactBatchModals/StageBatchModal";
import OwnerBatchModal from "./ContactBatchModals/OwnerBatchModal";
import DNCBatchModal from "./ContactBatchModals/DNCBatchModal";
import ChannelBatchModal from "./ContactBatchModals/ChannelsBatchModal";
import QuickAdd from "./QuickAdd/QuickAdd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function Contacts(props) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [contactClicked, setContactClicked] = useState(null);
  const [emailShow, setEmailShow] = useState(false);
  const [email, setEmail] = useState();
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [backgorundToast, setBackgroundToast] = useState("");
  const [modalOptions, setModalOptions] = useState({
    id: null,
    isOpen: false,
    title: "",
    type: "",
  });

  const [exportModal, setExportModal] = useState(false);
  const [IpShow, setIpShow] = useState(false);

  const clickOnEmailFunction = (data) => {
    setEmail(data?.fields?.all?.email);
    setContactClicked(data.id);
    setEmailShow(true);
  };


  const onClickIDFunction = async (id, doNotContact) => {
    try {
      let response;

      if (doNotContact === true || doNotContact === false) {
        if (!doNotContact) {
          response = await addDoNotContact({ id }).unwrap();
        }
        if (doNotContact) {
          response = await removeDoNotContact({ id }).unwrap();
        }
      }
      if (!response.error) {
        SweetAlert.fire({
          title: t("caymland.webhook.label.success"),
          text: doNotContact ? t("caymland.lead.remove_dnc", { id: id }) : t("caymland.lead.add_dnc", { id: id }),
          icon: "success",
          confirmButtonText: t("caymland.core.form.ok"),
          customClass: {
            confirmButton: "swal-custom result-component",
          },
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const setAnonymous = () => {
    toggleAnonymousSearch();
  };

  const buttons = [
    {
      name: "addnew",
      title: t("caymland.mailbox.list.action.new"),
      icon: <BiAddToQueue size={15} />,
      onClick: () => navigate("/contacts/new"),
      childs: [
        {
          name: "import",
          title: t("caymland.lead.import"),
          icon: <BiImport size={17} />,
          onClick: () => navigate("/contacts/import/new"),
        },
        {
          name: "importhistory",
          title: t("caymland.lead.lead.import.index"),
          icon: <BiHistory size={17} />,
          onClick: () => navigate("/contacts/import", { state: { myData: "lead" } }),
        },
        {
          name: "Export",
          title: t("caymland.core.export"),
          icon: <CgExport size={17} />,
          onClick: () => setExportModal(true),
        },
        {
          name: "Export History",
          title: t("caymland.core.export.history"),
          icon: <BiHistory size={17} />,
          onClick: () => navigate("/contacts/export/custom"),
        },
      ],
    },
  ];

  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: 30,
    orderByDir: "desc",
    orderBy: "last_active",
    search: location?.state?.search ? location.state.search : null,
  });

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const locale = useSelector((state) => state.auth.user.locale);
  Moment.locale(locale);

  const { data, isFetching, isLoading, error, refetch } = useGetContactsQuery(query);
  const [deleteContact] = useDeleteContactsMutation();
  const [addDoNotContact] = contactsApi.useAddDoNotContactMutation();
  const [removeDoNotContact] = contactsApi.useRemoveDoNotContactMutation();
  const toggleAnonymousSearch = () => {
    setQuery((currentQuery) => {
      let newQuery;
      if (currentQuery.hasOwnProperty("search") && currentQuery.search === "is:anonymous") {
        const { search, ...rest } = currentQuery;
        newQuery = rest;
        setIpShow(false);
      } else {
        newQuery = {
          ...currentQuery,
          search: "is:anonymous",
        };
        setIpShow(true);
      }
      return newQuery;
    });
  };
  const isWhite = (str) => {
    const whiteLimit = 200;
    let r = parseInt("0x" + str?.substring(1, 3));
    let g = parseInt("0x" + str?.substring(3, 5));
    let b = parseInt("0x" + str?.substring(5, 7));
    if (r < whiteLimit || b < whiteLimit || g < whiteLimit) {
      return false;
    }
    return true;
  };
  const getBorderColor = (hexColor) => {
    return isWhite(hexColor) ? "black" : `#${hexColor}`;
  };

  const actionButtons = ActionButtons({
    clone: false,
    link: "contacts",
    deleteItem: deleteContact,
    sendEmail: !(query.hasOwnProperty("search") && query.search === "is:anonymous"),
    clickOnEmailFunction: clickOnEmailFunction,
    setDoNotContact: true,
    onClickIDFunction: onClickIDFunction,
    createBrochureOrder: true,
    refetch: refetch,
  });

  const columns = [
    {
      accessorKey: "name",
      accessorFn: (row) => row.name,
      header: t("caymland.core.name"),
      size: 150,
      enableColumnActions: false,
      enableSorting: false,
      Cell: ({ cell }) => {
        const rowData = cell?.row?.original;
        return <LinkComponent data={rowData} loading={isFetching} link="contacts" view={true} IpShow={IpShow} />;
      },
    },
    {
      accessorKey: "group",
      accessorFn: (row) => row?.fields?.all?.groups_contact,
      header: t("caymland.groups_contact.label"),
      size: 150,
      enableColumnActions: false,
      enableSorting: false,
    },
    {
      accessorKey: "company",
      accessorFn: (row) => row?.fields?.all?.company,
      header: t("caymland.core.company"),
      size: 150,
      enableColumnActions: false,
      enableSorting: false,
    },
    {
      accessorKey: "email",
      accessorFn: (row) => row.email,
      header: t("caymland.core.type.email"),
      enableColumnActions: false,
      enableSorting: false,
      size: 150,
      Cell: ({ cell }) => cell?.row?.original?.fields?.all?.email,
    },
    {
      accessorKey: "location",
      accessorFn: (row) => row.location,
      header: t("caymland.lead.lead.thead.location"),
      enableColumnActions: false,
      enableSorting: false,
      size: 150,
      Cell: ({ cell }) => {
        const { state, city, country } = cell.row.original.fields.all;
        let flagLetters = cell.row.original.fields.all.country
          ? cell.row.original.fields.all.country === "Switzerland"
            ? "ch"
            : cell.row.original.fields.all.country.toLowerCase()
          : "";
        return state || city || country ? (
          <div>
            {flagLetters && <i className={`flag-icon flag-icon-${flagLetters.toLowerCase()}`}></i>} {city}
            {city && state && ", "} {state}
            {!city && !state && country}
          </div>
        ) : null;
      },
    },
    {
      accessorKey: "stage",
      accessorFn: (row) => row.stage,
      header: t("caymland.lead.lead.field.stage"),
      enableColumnActions: false,
      enableSorting: false,
      size: 150,
      Cell: ({ cell }) => {
        const { stage } = cell?.row?.original;
        return stage ? (
          <p
            className="badge badge-dark"
            style={{
              textAlign: "center",
              margin: "0",
              fontSize: "0.75",
              fontWeight: "400",
              border: `1px solid ${getBorderColor(stage?.color)}`,
              color: isWhite(stage?.color) ? "black" : `#${stage?.color}`,
              background: "white",
            }}
          >
            {stage?.name}
          </p>
        ) : (
          <></>
        );
      },
    },
    {
      accessorKey: "points",
      accessorFn: (row) => row.points,
      header: t("caymland.lead.graph.line.points"),
      enableColumnActions: false,
      enableSorting: false,
      size: 150,
      Cell: ({ cell }) => {
        const { points } = cell?.row?.original?.fields?.all;
        return (
          <p
            className="badge badge-dark"
            style={{
              textAlign: "center",
              margin: "0",
              fontSize: "0.75",
              fontWeight: "400",
              border: "1px solid #55607F",
              color: "#55607F",
              background: "white",
            }}
          >
            {points}
          </p>
        );
      },
    },
    {
      accessorKey: "last_active",
      accessorFn: (row) => row.lastActive,
      header: t("caymland.lead.lastactive"),
      enableColumnActions: false,
      size: 130,
      Cell: ({ cell }) => {
        const lastActive = cell?.row?.original?.lastActive;
        return (
          <button className="normal-btn cursor-help" title={Moment(lastActive).format("DD.MM.YYYY hh:mm")}>
            {lastActive !== null ? Moment(lastActive).fromNow() : " "}
          </button>
        );
      },
    },
  ];

  const actionBatchButtons = ActionBatchButtons({
    link: "contacts",
    changeCampaigns: false,
    changeCategories: false,
    changeSegments: false,
    changeStage: true,
    changeOwner: false,
    setModalOptions: setModalOptions,
    refetch: refetch,
  });

  const AnonymousToggleButton = () => {
    return (
      <Tooltip arrow title={t("caymland.lead.lead.anonymous_leads")}>
        <IconButton onClick={setAnonymous}>
          <FaUserSecret size={20} />
        </IconButton>
      </Tooltip>
    );
  };

  const toolbarButtons = useMemo(
    () => [
      {
        name: "anonymous_button",
        component: AnonymousToggleButton,
        props: {},
      },
    ],
    [query]
  );

  const extraButtonsComponent = <QuickAdd setShowAlert={setShow} setAlertMessage={setMessage} />;

  return (
    <Fragment>
      <ExportModal
        isOpen={exportModal}
        setIsOpen={setExportModal}
        icon={<AiOutlineSave color="#2a3142" size={16} />}
        saveText="Send"
        modalTitle={t("caymland.core.fields.export")}
      />
      <SendEmailModal
        show={emailShow}
        hide={() => setEmailShow(false)}
        loading={false}
        email={email}
        contact={contactClicked}
        id={contactClicked}
      />{" "}
      <CampaignBatchModal
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
        setShow={setShow}
        setMessage={setMessage}
        setBackgroundToast={setBackgroundToast}
      />
      <CategoriesBatchModal
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
        setShow={setShow}
        setMessage={setMessage}
        setBackgroundToast={setBackgroundToast}
      />
      <ChannelBatchModal
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
        setShow={setShow}
        setMessage={setMessage}
        setBackgroundToast={setBackgroundToast}
      />
      <SegmentBatchModal
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
        setShow={setShow}
        setMessage={setMessage}
        setBackgroundToast={setBackgroundToast}
      />
      <StageBatchModal
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
        setShow={setShow}
        setMessage={setMessage}
        refetch={refetch}
        setBackgroundToast={setBackgroundToast}
      />
      <OwnerBatchModal
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
        setShow={setShow}
        setMessage={setMessage}
        setBackgroundToast={setBackgroundToast}
      />
      <DNCBatchModal
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
        setShow={setShow}
        setMessage={setMessage}
        refetch={refetch}
        setBackgroundToast={setBackgroundToast}
      />
      <Header
        buttons={buttons}
        title={t("caymland.contact.segments.fields.contacts")}
        alertMessage={message}
        showAlert={show}
        setShowAlert={setShow}
        extraButtonsComponent={extraButtonsComponent}
        background={backgorundToast}
      />
      <CustomTable
        data={data}
        columns={columns}
        query={query}
        setQuery={setQuery}
        actionButtons={actionButtons}
        setSelectedRowIds={setSelectedRowIds}
        selectedRowIds={selectedRowIds}
        actionBatchButtons={actionBatchButtons}
        toolbarButtons={toolbarButtons}
        link="contacts"
        showDetailPanel={true}
        isFetching={isFetching}
        isContactTable
      />
    </Fragment>
  );
}

export default Contacts;
