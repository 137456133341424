import { FaDownload } from "react-icons/fa";
import { Error } from "../../Alerts";

const sourceFile = (props) => {
  const { file, dir, status, id } = props;

  const handleDownloadClick = async () => {
    if (status === 3 && file) {
      try {
        const url = `${process.env.REACT_APP_DEV_URL}/api/exports/download/${id}`;

        let formData = new FormData();
        fetch(url, {
          method: "POST",
          headers: {
            Authorization: "Basic Q2F5bWxhbmQ6VWx0cmFzb2Z0",
          },
          body: formData,
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(`Network response was not ok. Status: ${response.status}`);
            }
            return response.blob();
          })
          .then((blob) => {
            const href = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = href;
            link.download = `${file}.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch((error) => {
            console.error("Error downloading file:", error);
          });
      } catch (err) {
        console.error("Error downloading file:", err);
      }
    }
  };

  return (
    <div className="d-flex items-center justify-start">
      {status === 3 && dir && (
        <FaDownload onClick={handleDownloadClick} style={{ cursor: "pointer", marginRight: "5px" }} />
      )}
      <div className="table-links ml-2" onClick={handleDownloadClick}>
        {file}
      </div>
    </div>
  );
};

export default sourceFile;
