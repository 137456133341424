import React, { useEffect, useState, useLayoutEffect } from "react";
import "./App.css";
import "react-dropzone-uploader/dist/styles.css";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { customRoutes } from "./utils/CustomRoutes";
import { RoutesComponent } from "./utils/renderRoutes";
import { useSettingsQuery } from "../redux/api/settingsApi";
import i18n from "i18next";

const DEFAULT_LOCALE = "en";

const AppLayout = (props) => {
  const token = useSelector((state) => state.auth.token);
  const { pathname } = useLocation();
  const { data, isLoading, isFetching } = useSettingsQuery(localStorage.getItem("user") && undefined);

  const [translationsReady, setTranslationsReady] = useState(false);
  const translations = useSelector((state) => state?.settings?.data?.translations);
  const locale = useSelector((state) =>  state?.auth?.user?.locale || DEFAULT_LOCALE);
  useEffect(() => {
    if (translations) {
      const resources = Object.keys(translations).reduce((acc, lang) => {
        acc[lang] = { translation: translations[lang] };
        return acc;
      }, {});

      Object.keys(resources).forEach((lang) => {
        i18n.addResourceBundle(lang, "translation", resources[lang].translation);
        i18n.reloadResources();
      });
    }
    if (locale) {
      i18n.changeLanguage(locale);
      i18n.reloadResources();
    }
    setTranslationsReady(true);   
  }, [translations, locale]);

  useLayoutEffect(() => {
    if (!localStorage.getItem("fromDate") || !localStorage.getItem("toDate")) {
      localStorage.setItem("fromDate", new Date());
      localStorage.setItem("toDate", new Date());
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("layout_version", "light-only");
    localStorage.setItem("primary_color", "#135f95");
    localStorage.setItem("secondary_color", "#00b1ea");
    localStorage.setItem("color", "");
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  if (isLoading || !translationsReady) {
    return <></>;
  }

  return <RoutesComponent mainRoutes={customRoutes} token={token} />;
};

export default AppLayout;
