import React, { useEffect } from "react";
// import ClassicEditor from "ckeditor5-custom-build";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
import { t } from "i18next";
import Label from "./Label";

import { CKEditor } from "@ckeditor/ckeditor5-react";

import {
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  Autoformat,
  AutoImage,
  AutoLink,
  Autosave,
  // Base64UploadAdapter,
  BlockQuote,
  Bold,
  Code,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  FullPage,
  GeneralHtmlSupport,
  Heading,
  Highlight,
  HorizontalLine,
  HtmlComment,
  HtmlEmbed,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsert,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  // ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  LinkImage,
  List,
  ListProperties,
  MediaEmbed,
  Paragraph,
  PasteFromOffice,
  RemoveFormat,
  SelectAll,
  ShowBlocks,
  SourceEditing,
  SpecialCharacters,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersEssentials,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
  Strikethrough,
  Style,
  Subscript,
  Superscript,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextTransformation,
  Underline,
  Undo,
} from "ckeditor5";

import "../../reusableComponents/ckEditor/App.css";
import "ckeditor5/ckeditor5.css";

export default ({
  name,
  value,
  onChange,
  onReady,
  label,
  tooltip,
  required = false,
  showSmallEditor = true,
  showToolbar = true,
  onEditorReady,
  errors,
  height = "144px",
}) => {
  const handleEditorReady = (editor) => {
    editor.setData(value ?? "");

    // const fontFamily = editor.commands.get("fontFamily");
    // fontFamily.execute({ value: "Arial" });

    if (onEditorReady) {
      onEditorReady(editor);
    }
    if (onReady) {
      onReady(editor);
    }

    // editor.execute("fontFamily", { value: "Courier New, Courier, monospace" });
  };

  const editorConfig = {
    toolbar: showToolbar
      ? showSmallEditor
        ? ["undo", "redo", "bold", "italic", "underline"]
        : [
            "undo",
            "redo",
            "|",
            "sourceEditing",
            "findAndReplace",
            "|",
            "heading",
            "style",
            "|",
            "fontSize",
            "fontFamily",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "subscript",
            "superscript",
            "code",
            "removeFormat",
            "|",
            "specialCharacters",
            "horizontalLine",
            // "pageBreak",
            "link",
            "insertImageViaUrl",
            "mediaEmbed",
            "insertTable",
            "highlight",
            "blockQuote",
            "|",
            "alignment",
            "|",
            // "bulletedList",
            "numberedList",
            "outdent",
            "indent",
          ]
      : [],
    plugins: [
      AccessibilityHelp,
      Alignment,
      AutoImage,
      AutoLink,
      Autosave,
      BlockQuote,
      Bold,
      Code,
      Essentials,
      FindAndReplace,
      FontBackgroundColor,
      FontColor,
      FontFamily,
      FontSize,
      GeneralHtmlSupport,
      Heading,
      Highlight,
      HorizontalLine,
      HtmlComment,
      ImageBlock,
      ImageCaption,
      ImageInline,
      ImageInsertViaUrl,
      ImageResize,
      ImageStyle,
      ImageTextAlternative,
      ImageToolbar,
      Indent,
      IndentBlock,
      Italic,
      Link,
      LinkImage,
      List,
      ListProperties,
      MediaEmbed,
      //   PageBreak,
      Paragraph,
      PasteFromOffice,
      RemoveFormat,
      SelectAll,
      SourceEditing,
      SpecialCharacters,
      SpecialCharactersArrows,
      SpecialCharactersCurrency,
      SpecialCharactersEssentials,
      SpecialCharactersLatin,
      SpecialCharactersMathematical,
      SpecialCharactersText,
      Strikethrough,
      Style,
      Subscript,
      Superscript,
      Table,
      TableCaption,
      TableCellProperties,
      TableColumnResize,
      TableProperties,
      TableToolbar,
      TextTransformation,
      Underline,
      Undo,
    ],
    fontFamily: {
      supportAllValues: true,
      options: [
        "Arial, Helvetica, sans-serif",
        "Courier New, Courier, monospace",
        "Georgia, serif",
        "Lucida Sans Unicode, Lucida Grande, sans-serif",
        "Tahoma, Geneva, sans-serif",
        "Times New Roman, Times, serif",
        "Trebuchet MS, Helvetica, sans-serif",
        "Verdana, Geneva, sans-serif",
      ],
      // default: 'Courier New, Courier, monospace',
    },
    fontSize: {
      options: [11, 12, "default", 14, 16, 18],
      supportAllValues: true,
    },
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        {
          model: "heading5",
          view: "h5",
          title: "Heading 5",
          class: "ck-heading_heading5",
        },
        {
          model: "heading6",
          view: "h6",
          title: "Heading 6",
          class: "ck-heading_heading6",
        },
      ],
    },
    htmlSupport: {
      allow: [
        {
          name: /^.*$/,
          styles: true,
          attributes: true,
          classes: true,
        },
      ],
    },
    image: {
      toolbar: [
        "toggleImageCaption",
        "imageTextAlternative",
        "|",
        "imageStyle:inline",
        "imageStyle:wrapText",
        "imageStyle:breakText",
        "|",
        "resizeImage",
      ],
    },
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: "https://",
      decorators: {
        toggleDownloadable: {
          mode: "manual",
          label: "Downloadable",
          attributes: {
            download: "file",
          },
        },
      },
    },
    list: {
      properties: {
        styles: true,
        startIndex: true,
        reversed: true,
      },
    },
    placeholder: t("caymland.core.typesomething"),
    style: {
      definitions: [
        {
          name: "Article category",
          element: "h3",
          classes: ["category"],
        },
        {
          name: "Title",
          element: "h2",
          classes: ["document-title"],
        },
        {
          name: "Subtitle",
          element: "h3",
          classes: ["document-subtitle"],
        },
        {
          name: "Info box",
          element: "p",
          classes: ["info-box"],
        },
        {
          name: "Side quote",
          element: "blockquote",
          classes: ["side-quote"],
        },
        {
          name: "Marker",
          element: "span",
          classes: ["marker"],
        },
        {
          name: "Spoiler",
          element: "span",
          classes: ["spoiler"],
        },
        {
          name: "Code (dark)",
          element: "pre",
          classes: ["fancy-code", "fancy-code-dark"],
        },
        {
          name: "Code (bright)",
          element: "pre",
          classes: ["fancy-code", "fancy-code-bright"],
        },
      ],
    },
    table: {
      contentToolbar: ["tableColumn", "tableRow", "mergeTableCells", "tableProperties", "tableCellProperties"],
    },
  };

  return (
    <div className="mb-20px tw-w-full" style={{ height: "100%" }}>
      {label && label.length && (
        <Label name={name} label={label} tooltip={tooltip} required={required} errors={errors} />
      )}
      <div className="hover-effect">
        <div>
          <style>{`
        .ck-editor__editable_inline {
          height: ${height};
        }
          .ck-editor__editable {
             word-wrap: break-word;
              white-space: normal; 
              overflow-wrap: break-word; 
          }
        .ck .ck-editor__main {
        height: 100%;
        max-height: 100%;
        }
      `}</style>
          <CKEditor
            name={name}
            editor={ClassicEditor}
            data={value ?? ""}
            onReady={handleEditorReady}
            // config={{
            //   placeholder: t("caymland.core.typesomething"),
            //   toolbar: showToolbar
            //     ? showSmallEditor
            //       ? ["undo", "redo", "bold", "italic", "underline"]
            //       : [
            //           "undo",
            //           "redo",
            //           "|",
            //           "heading",
            //           "|",
            //           "fontfamily",
            //           "fontsize",
            //           "fontColor",
            //           "fontBackgroundColor",
            //           "|",
            //           "bold",
            //           "italic",
            //           "underline",
            //           "strikethrough",
            //           "code",
            //           "|",
            //           "link",
            //           "blockQuote",
            //           "imageUpload",
            //           "mediaEmbed",
            //           "codeBlock",
            //           "|",
            //           "myDropdown", // Add the custom dropdown here
            //           // Alignment & list styles
            //           "alignment:left",
            //           "alignment:center",
            //           "alignment:right",
            //           "alignment:justify",
            //           "numberedList",
            //           "bulletedList",
            //           "|",
            //           // Indenting and quotes
            //           "outdent",
            //           "indent",
            //           "|",
            //           // Advanced editing
            //           "insertTable",
            //           "horizontalLine",
            //           "specialCharacters",
            //           "|",
            //         ]
            //     : [],
            // }}

            config={editorConfig}
            onChange={(event, editor) => {
              const data = editor?.getData();
              // const sanitizedData = data.replace(/^<p>|<\/p>$/g, '').trim()
              onChange(data !== "<p>&nbsp;</p>" ? data : null);
            }}
          />
        </div>
      </div>
    </div>
  );
};
