import React from "react";
import { Label } from "./index";
import { t } from "i18next";

const Checkbox = ({
  name,
  label,
  tooltip,
  required = false,
  value,
  onChange,
  toggleType = "switch-toggle-field",
  alignSelf = false,
  extraStyle,
  marginBottom = "mb-20px",
  rows,
  innerLabel = { trueLabel: t("caymland.core.yes"), falseLabel: t("caymland.core.no") },
}) => {
  
  const getSliderStyle = (value, toggleType) => {
    const baseStyle = {
      position: "absolute",
      fontSize: "10px",
      textAlign: "center",
      lineHeight: "28px",
      top: 0,
      left: value ? "45%" : 0,
      width: "42px",
      height: "28px",
      borderRadius: "8px",
      transition: "0.3s ease-in-out",
      zIndex: 1,
    };

    if (toggleType === "switch-toggleExport") {
      return {
        ...baseStyle,
        width: "82px",
        backgroundColor: value ? "#15925a" : "#128fc5",
        color: value ? "#fff" : "inherit",
      };
    } else if (toggleType === "switch-toggle-field") {
      return {
        ...baseStyle,
        backgroundColor: value ? "#B01B1A" : "#d1dad3",
        color: value ? "#fff" : "inherit",
      };
    }
    return baseStyle;
  };

  const sliderStyle = getSliderStyle(value, toggleType);

  const labelStyle = {
    position: "relative",
    display: "inline-block",
    width: toggleType === "switch-toggleExport" ? "140px" : "70px",
    height: "28px",
    margin: 0,
    cursor: "pointer",
    boxShadow: "inset 0 0 5px rgb(0 0 0 / 20%)",
    borderRadius: "8px",
    overflow: "hidden",
  };

  return (
    <div
      className={marginBottom}
      style={{ alignSelf: alignSelf ? "center" : "", marginBottom: alignSelf ? "0" : "5px" }}
    >
      {label && label.length && (
        <Label name={name} label={label} tooltip={tooltip} required={required} extraStyle={extraStyle} rows={rows} />
      )}
      <div className={toggleType}>
        <input style={{width: '0'}} type="checkbox" name={name} id={name} checked={!!value} required={required} onChange={onChange} />
        <label htmlFor={name} style={labelStyle}>
          <span style={sliderStyle}>{value ? innerLabel?.trueLabel : innerLabel?.falseLabel}</span>
        </label>
      </div>
    </div>
  );
};

export default Checkbox;
