import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQuery } from "../utils/customFetchBaseQuery";
import { customTransformResponse } from "../utils/customTransformResponse";
import qs from "qs";
import customBaseQuery from "../utils/customBaseQuery";

export const usersApi = createApi({
  reducerPath: "usersApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["Users"],
  endpoints: (build) => ({
    getUsers: build.query({
      query: customBaseQuery((q) => `/api/users?${qs.stringify(q)}`),
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: false,
      providesTags: ["Users"],
    }),
    getUsersOptions: build.query({
      query: customBaseQuery((q) => `/api/users/options?${qs.stringify(q)}`),

      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      keepUnusedDataFor: false,
      providesTags: ["Users"],
    }),
    userSelf: build.query({
      queryFn: async ({ signal, dispatch, getState }, extraOptions, baseQuery) => {
        console.log(id);

        let userSelf = await baseQuery({ url: `/api/users/self` });

        const user = userSelf?.data;

        const data = {
          user,
        };

        return { data: data };
      },
      providesTags: (result, error, id) => [{ type: "Users", id }],
    }),

    updateUser: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/api/users/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Users", id }],
    }),
    deleteUser: build.mutation({
      query: (id) => ({
        url: `/api/users/${id}/delete`,
        method: "DELETE",
      }),
      onQueryStarted: (id, { dispatch }) => {
        dispatch(
          usersApi.util.updateQueryData("getUsers", undefined, (draft) => {
            draft.data = draft.data.filter((Users) => Users.id !== id);
            draft.total -= 1;
          })
        );
      },
      invalidatesTags: (result, error, id) => [{ type: "Users", id }],
    }),
    getAllUsers: build.query({
      query: () => "/api/users",
      transformResponse: (response) => {
        return response.users.map((user) => ({
          label: `${user.firstName} ${user.lastName} - ${user.email}`,
          value: user.email,
        }));
      },
    }),
    createUser: build.mutation({
      query: (user) => {
        delete user.id;
        return {
          url: `/api/users/new`,
          method: "POST",
          body: user,
        };
      },
      invalidatesTags: ["Users"],
    }),
    getUser: build.query({
      query: (id) => `/api/users/${id}`,
      providesTags: (result, error, id) => [{ type: "Users", id }],
    }),
    getUsersSelect: build.query({
      query: (id) => `/api/users/select`,
      providesTags: (result, error, id) => [{ type: "Users", id }],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetAllUsersQuery,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useCreateUserMutation,
  useUserSelfQuery,
  useGetUserQuery,
  useGetUsersSelectQuery,
  useGetUsersOptionsQuery,
} = usersApi;
