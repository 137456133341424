import React, { Fragment, useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { BiAddToQueue } from "react-icons/bi";
import Header from "../../reusableComponents/Header/Header";
import { useUpdateImportMutation, useGetImportsQuery } from "../../../redux/api/companiesApi";
import CustomTable from "../CustomTable/CustomTable";
import { ActionButtons } from "../tableComponents/ActionButtons";
import {
  Status,
  Runtime,
  SourceFile,
  Progress,
  TotalRows,
  Inserted,
  Updated,
  Ignored,
  DateAdded,
} from "./HelperHistory";
import { LinkComponent } from "../tableComponents/LinkComponent";
import { useTranslation } from "react-i18next";
import { FaRegTimesCircle } from "react-icons/fa";

const History = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const isContactsView = location.pathname.includes("/contacts");
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: 30,
    orderByDir: "DESC",
    orderBy: "id",
    where: [
      {
        expr: "eq",
        column: "object",
        value: isContactsView ? "lead" : "company",
      },
    ],
  });

  const [updateImport] = useUpdateImportMutation();
  const { data, refetch, isLoading } = useGetImportsQuery(query);

  const handleTogglePublished = async (data) => {
    const updatedData = { ...data, isPublished: !data.isPublished };
    try {
      const result = await updateImport(updatedData).unwrap();
      await refetch();
      setAlertMessage(`${data?.originalFile} has been updated successfully!`);
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 7500);
    } catch (error) {
      setAlertMessage(`There was an error updating!`);
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 7500);
    }
  };

  const actionButtons = ActionButtons({
    link: "",
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "status",
        header: `${t("caymland.lead.import.status")}`,
        size: 80,
        enableColumnActions: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return <Status {...rowData} />;
        },
      },
      {
        accessorKey: "originalFile",
        header: `${t("caymland.lead.import.source.file")}`,
        accessorFn: (row) => row.originalFile,
        enableColumnActions: false,
        enableSorting: false,
        size: 100,
        Cell: ({ cell }) => (
          <LinkComponent
            data={cell.row.original}
            handleTogglePublished={handleTogglePublished}
            link="companies/import/history"
            view={true}
          />
        ),
      },
      {
        accessorKey: "Runtime",
        header: `${t("caymland.lead.import.runtime")}`,
        enableColumnActions: false,
        enableSorting: false,
        size: 80,
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return <Runtime {...rowData} />;
        },
      },
      {
        accessorKey: "progress",
        header: `${t("caymland.lead.import.progress")}`,
        enableColumnActions: false,
        enableSorting: false,
        size: 80,
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return <Progress {...rowData} />;
        },
      },
      {
        accessorKey: "totalRows",
        header: `${t("caymland.lead.import.line.count")}`,
        enableColumnActions: false,
        enableSorting: false,
        size: 80,
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return <TotalRows {...rowData} />;
        },
      },
      {
        accessorKey: "insertedCount",
        header: `${t("caymland.lead.import.inserted.count")}`,
        enableColumnActions: false,
        enableSorting: false,
        size: 80,
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return <Inserted {...rowData} />;
        },
      },
      {
        accessorKey: "updatedCount",
        header: `${t("caymland.lead.import.updated.count")}`,
        enableColumnActions: false,
        size: 80,
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return <Updated {...rowData} />;
        },
      },
      {
        accessorKey: "ignoredCount",
        header: `${t("caymland.lead.import.ignored.count")}`,
        enableColumnActions: false,
        enableSorting: false,
        size: 80,
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return <Ignored {...rowData} />;
        },
      },
      {
        accessorKey: "dataAdded",
        header: `${t("caymland.core.date.added")}`,
        enableColumnActions: false,
        enableSorting: false,
        size: 80,
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return <DateAdded {...rowData} />;
        },
      },
    ],
    []
  );

  return (
    <Fragment>
      <Header
        buttons={[
          {
            name: "Close",
            title: t("caymland.core.close"),
            icon: <FaRegTimesCircle size={15} />,
            onClick: () => navigate("/contacts"),
          },
          {
            name: "AddNew",
            title: t("caymland.core.form.new"),
            icon: <BiAddToQueue size={15} />,
            onClick: () => navigate("/contacts/import/new"),
          },
        ]}
        title={t("caymland.lead.import.list")}
        loading={isLoading}
        showAlert={showAlert}
        alertMessage={alertMessage}
        setShowAlert={setShowAlert}
      />
      <CustomTable
        data={data}
        columns={columns}
        query={query}
        actions={false}
        setQuery={setQuery}
        setSelectedRowIds={setSelectedRowIds}
        selectedRowIds={selectedRowIds}
      />
    </Fragment>
  );
};

export default History;
