import React, { useEffect, useState } from "react";
import { TbSend } from "react-icons/tb";
import Button from "./Button";
import SendEmailModal from "../../main-menu-sidebar/contacts/SendEmailModal";
import { useGetAddEditEmailsQuery } from "../../../redux/api/emailsApi";
import { Tooltip } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { t } from "i18next";

export default function SendEmail(props) {
  const [show, setShow] = useState(false);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [templatesLoading, setTemplatesLoading] = useState(false);

  const handleShow = () => setShow(true);
  const { data } = useGetAddEditEmailsQuery();
  useEffect(() => {
    if (data) {
      retrieveEmailTemplates();
    }
  }, [data]);

  const retrieveEmailTemplates = async () => {
    setTemplatesLoading(true);
    let response = data;

    setEmailTemplates(Object.entries(response?.data?.emails).map((x) => x[1]));
    setTemplatesLoading(false);
  };

  return (
    <>
      <SendEmailModal
        show={show}
        hide={() => setShow(false)}
        fetchedEmails={emailTemplates}
        loading={templatesLoading}
        email={props.email}
        contact={props.contact}
        setShow={setShow}
        id={props.id}
      />
      <Button
       color="customGrey"
        buttons={[
          {
            name: !props.noText && "Send Email",
            title: !props.noText && t("caymland.email.campaign.event.send"),
            icon: (
              <Tooltip title={t("caymland.email.campaign.event.send")} arrow>
                {" "}
                <TbSend size={15} />
              </Tooltip>
            ),
            onClick: () => handleShow(),
          },
        ]}
      />
    </>
  );
}
