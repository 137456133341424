import { t } from "i18next";
import React, { useMemo, useState } from "react";
import Dropzone from "react-dropzone-uploader";
import { useSelector } from "react-redux";
import CustomTable from "../../CustomTable/CustomTable";
import moment from "moment";
import {
  useDeleteUploadFileMutation,
  useFilesTabQuery,
  useFilesUploadTabMutation,
} from "../../../../../redux/api/contactsApi";
import { ActionButtons } from "../../../../reusableComponents/tableComponents/ActionButtons";

const Files = ({ id, tabData }) => {
  const token = useSelector((state) => state?.auth?.token);
  console.log(tabData[0]);

  const [uploadImg, setUploadImg] = useState();
  const [pdfUploaded, setPdfUploaded] = useState();
  const [errorsMsg, setErrorsMsg] = useState(false);
  const [modifiedData, setModifiedData] = useState({});
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: 30,
    orderByDir: "DESC",
    orderBy: "uf.dateAdded",
  });
  const { data, isFetching, refetch } = useFilesTabQuery({ id, query });
  const [deleteUploadFile] = useDeleteUploadFileMutation();
  const [uploadFile] = useFilesUploadTabMutation();
  
  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append("file", file);

    console.log("getUploadParams called with file:", file);

    return uploadFile({ id, body });
  };

  const handleChangeStatus = async ({ meta, file, xhr }, status) => {
    setErrorsMsg(false);
    console.log("handleChangeStatus called with status:", status);
    refetch();
    if (status === "done") {
      try {
        let response = JSON.parse(xhr.response);
        console.log("Upload response:", response);
        if (response?.file?.name) {
          setModifiedData({
            ...modifiedData,
            ...{ title: meta.name, file: response?.file?.name },
          });
        }
        if (meta.type.includes("image")) {
          setUploadImg(meta?.previewUrl);
        } else if (meta.type === "application/pdf") {
          const blobUrl = URL.createObjectURL(file);
          setPdfUploaded(blobUrl);
        }
      } catch (e) {
        console.error("Error parsing response:", e);
      }
    } else if (status === "removed") {
      setErrorsMsg(true);
      setUploadImg();
      setModifiedData((prev) => ({
        ...prev,
        downloadUrl: {},
      }));
    }
  };

  const formatFileSize = (sizeInBytes) => {
    if (sizeInBytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
    const i = Math.floor(Math.log(sizeInBytes) / Math.log(k));
    return parseFloat((sizeInBytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "fileName",
        accessorFn: (row) =>
          row.fileOriginalName.length > 30 ? row.fileOriginalName.substring(0, 30) + "..." : row.fileOriginalName,
        header: "Name",
        enableColumnActions: false,
      },
      {
        accessorKey: "files",
        accessorFn: (row) => row.typeFile,
        header: "Via",
        enableColumnActions: false,
      },
      {
        accessorKey: "size",
        accessorFn: (row) => formatFileSize(row.size),
        header: "Size",
        enableColumnActions: false,
      },
      {
        accessorKey: "added",
        accessorFn: (row) => row?.owner?.username,
        header: "Added",
        enableColumnActions: false,
      },
      {
        accessorKey: "dateAdded",
        accessorFn: (row) => moment(row?.dateAdded).format("DD.MM.YY HH:mm"),
        header: "Date Added",
        enableColumnActions: false,
        muiTableHeadCellProps: {
          align: "right",
          sx: {
            verticalAlign: "bottom",
            zIndex: 0,
          },
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
    ],
    []
  );

  const actionButtons = ActionButtons({
    link: "files",
    clone: false,
    edit: false,
    deleteItem: deleteUploadFile,

    refetch: refetch,
  });

  return (
    <div style={{ padding: "15px", display: "flex", flexDirection: "column", gap: "10px" }}>
      <div className="w-full">
        <div className="p-no-margin">
          <p className="font-13">{t("caymland.asset.asset.form.file.upload", { max: tabData[0].maxSize })}</p>
          <form
            className="dropzone digits mt-10px"
            id="singleFileUpload"
            style={{ background: "white" }}
            action="/upload.php"
          >
            <div className="dz-message needsclick h-200-dropzone">
              <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                maxFiles={1}
                multiple={false}
                canCancel={false}
                accept={tabData[0].fileExtension.map((ext) => `.${ext}`).join(", ")}
                inputContent={t("caymland.asset.drop.file.here")}
                styles={{
                  dropzoneActive: { borderColor: "green" },
                }}
              />
            </div>
          </form>
        </div>
      </div>
      <CustomTable
        data={data || []}
        columns={columns}
        actionButtons={actionButtons}
        query={query}
        setQuery={setQuery}
        footer
        isFetching={isFetching}
        linkNavigate={null}
      />
    </div>
  );
};

export default Files;
