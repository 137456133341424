import { useTranslation } from "react-i18next";

const Runtime = (props) => {
  const status = props?.status;

  const getDuration = () => {
    const { t } = useTranslation();
    const dateStarted = new Date(props?.dateStarted);
    const dateEnded = new Date(props?.dateEnded);
    const diffMilliseconds = dateEnded - dateStarted;

    if (diffMilliseconds < 1000) {
      return t("caymland.core.date.less.than.second");
    }

    const minutes = Math.floor(diffMilliseconds / (1000 * 60));
    const seconds = Math.floor((diffMilliseconds % (1000 * 60)) / 1000);

    if (minutes === 0) {
      return `${seconds} ${t("caymland.core.time.seconds")}`;
    }

    return `${minutes} ${t("caymland.core.time.minutes")} ${seconds} ${t("caymland.core.time.seconds")}`;

  };

  if (status === 3) {
    return (
      <div>
        <span
          style={{
            width: "100%",
            display: "flex",
            color: "black",
            alignItems: "center",
            justifyContent: "center",
          }}
        ></span>
        <p>{getDuration()}</p>
      </div>
    );
  }

  return null;
};

export default Runtime;
