import React, { useState, useEffect } from "react";
import "./Sidebar.css";
import { MENUITEMS } from "../sidebar-component/menu";
import { SETTINGSMENU } from "../sidebar-component/menu";
import md5 from "blueimp-md5";
import sidebarlogo from "../../../assets/logo/sidebar_logo.png";
import favicon from "../../../assets/logo/favicon_sidebar.png";
import { Link, useLocation, useParams } from "react-router-dom";
import { FaSignOutAlt, FaUser } from "react-icons/fa";
import { MdKeyboardArrowRight } from "react-icons/md";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import { FiBookOpen } from "react-icons/fi";
import { HiOutlineSupport } from "react-icons/hi";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import KeyboardReturnOutlinedIcon from "@mui/icons-material/KeyboardReturnOutlined";
import { NavLink } from "react-router-dom";
import CustomModal from "../../reusableComponents/SupportTicket/CustomModal";
import "animate.css";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const SideNavBar = () => {
  const { t } = useTranslation();

  const [isExpanded, setExpandedState] = useState(true);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [activeSubmenuHeader, setActiveSubmenuHeader] = useState(null);
  const [showAlternateSidebar, setShowAlternateSidebar] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const { id } = useParams();

  const openModal = () => {
    setModalShow(true);
  };

  const closeModal = () => {
    setModalShow(false);
  };
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/mailbox") {
      setExpandedState(false);
      localStorage.setItem("sidebar_expand", "true");
    }
  }, [location]);

  const checkIsActive = (path) => {
    // Logic to determine if the path matches the current location
    // Can be as simple or complex as your application requires
    return location.pathname === path;
  };
  // Function to toggle the showContent state
  const toggleExpand = () => {
    setExpandedState(!isExpanded);
    localStorage.setItem("sidebar_expand", isExpanded.toString());
  };
  const toggleSidebarContent = () => {
    setShowAlternateSidebar(!showAlternateSidebar);
  };

  // Function to toggle dropdown visibility
  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  function getGravatarURL(email, size = 80) {
    const defaultImageURL = "https://cdn-icons-png.flaticon.com/512/149/149071.png";

    if (!email) {
      return defaultImageURL;
    }
    const hash = md5(email.toLowerCase().trim());
    return `https://www.gravatar.com/avatar/${hash}?s=${size}&d=${encodeURIComponent(defaultImageURL)}`;
  }

  // Define state for submenu visibility
  const [submenuState, setSubmenuState] = useState({});
  // Function to handle submenu toggle
  const handleSubmenuToggle = (index) => {
    setSubmenuState((prevState) => {
      // Create a copy of the previous submenu state
      const newState = { ...prevState };

      // If the clicked submenu is already open, close it
      if (newState[index]) {
        newState[index] = false;
      } else {
        // If the clicked submenu is closed, close all other submenus and open the clicked one
        for (const key in newState) {
          if (newState.hasOwnProperty(key) && key !== index.toString()) {
            newState[key] = false;
          }
        }
        newState[index] = true;
      }

      return newState;
    });

    // Update the active submenu header
    setActiveSubmenuHeader(index === activeSubmenuHeader ? null : index);
  };
  const handleClickOutside = (event) => {
    if (isDropdownVisible && !event.target.closest(".page-sidebar")) {
      setDropdownVisible(false);
      event.stopPropagation(); // Prevent the click event from propagating further
    }
  };

  useEffect(() => {
    // Add a click event listener to the document
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isDropdownVisible]);

  const closeAllSubmenus = () => {
    setSubmenuState({});
    setActiveSubmenuHeader(null);
  };
  if (location.pathname === `/reports/view/${id}/html/export`) {
    return null;
  }
  return (
    <div
      className={`
    ${isExpanded ? "side-nav-container" : "side-nav-container side-nav-container-NX"} 
    `}
    >
      {/* ${location.pathname === "/mailbox" ? "tw-hidden" : ""} */}
      <div className="nav-upper ">
        <div className="nav-heading">
          {isExpanded ? (
            <Link to="/" className="nav-brand">
              <img src={sidebarlogo} alt="" srcSet="" />
            </Link>
          ) : (
            <Link to="/" className="nav-brand">
              <img src={favicon} style={{ padding: "14px", marginLeft: "0px" }} />
            </Link>
          )}
        </div>
        <div className="border-line transition"></div>
        <div
          className="nav-menu"
          style={{
            maxHeight: isExpanded ? "70vh" : "none",
            overflowY: isExpanded ? "auto" : "unset",
            overflowX: isExpanded ? "hidden" : "unset",
          }}
        >
          {showAlternateSidebar
            ? SETTINGSMENU.map((menuItem, index) => {
                if (menuItem.type === "link") {
                  const IconComponent = menuItem.icon;

                  return (
                    <div key={index}>
                      <NavLink
                        key={index}
                        to={menuItem.path}
                        className={`menu-item animate__animated animate__fadeInLeft `}
                        style={({ isActive }) => {
                          isActive ? "active" : "";
                        }}
                      >
                        <div className="menu-item-icon">{menuItem.icon}</div>

                        {!isExpanded ? (
                          <div className="menu-item-tooltip ">
                            <div className="tooltip-border"></div>
                            <span
                              style={{
                                fontSize: "10px",
                                paddingLeft: "5px",
                                paddingRight: "12px",
                              }}
                            >
                              {t(menuItem.title)}
                            </span>
                          </div>
                        ) : (
                          <span className={``}>{t(menuItem.title)}</span>
                        )}
                      </NavLink>
                    </div>
                  );
                } else if (menuItem.type === "sub" && menuItem.children) {
                  const isSubmenuExpanded = submenuState[index];
                  const IconComponent = menuItem.icon;

                  return (
                    <div
                      key={index}
                      className={`submenu-item animate__animated animate__fadeInLeft ${
                        isSubmenuExpanded ? "w-full" : ""
                      } `}
                      f
                      onMouseEnter={() => {
                        if (!isExpanded) handleSubmenuToggle(index);
                      }}
                      onMouseLeave={() => {
                        if (!isExpanded) handleSubmenuToggle(index);
                      }}
                    >
                      <div
                        onClick={() => handleSubmenuToggle(index)}
                        className={`submenu-header animate__animated animate__fadeInLeft ${
                          index === activeSubmenuHeader ? "active" : ""
                        }  ${!isExpanded && " gap-0px "} `}
                      >
                        {IconComponent && (
                          <IconComponent className={`menu-item-icon  ${!isExpanded && "justify-center"}`} />
                        )}
                        {isExpanded ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "2px",
                            }}
                          >
                            <span className={``}>{t(menuItem.title)}</span>
                            <KeyboardArrowDownOutlinedIcon
                              className="transition"
                              fontSize="12px"
                              style={{ marginRight: "5px" }}
                            />
                          </div>
                        ) : (
                          <KeyboardArrowRightOutlinedIcon
                            className="transition"
                            fontSize="10px"
                            style={{ marginRight: "-14px" }}
                          />
                        )}
                      </div>
                      {isSubmenuExpanded && (
                        <div className={`sub-menu-items ${!isExpanded ? "right-menu-items" : ""}`}>
                          {menuItem.children.map((subItem, subIndex) => (
                            <div className="" key={subIndex}>
                              <NavLink
                                className={`${isExpanded ? "menu-children-item" : "menu-children-item-nx"} `}
                                style={({ isActive }) => {
                                  isActive ? "active" : "";
                                }}
                                to={subItem.path}
                              >
                                <span>○ {t(subItem.title)}</span>
                              </NavLink>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  );
                }
                return null;
              })
            : MENUITEMS.map((menuItem, index) => {
                if (menuItem.type === "link") {
                  // For regular link items
                  const IconComponent = menuItem.icon;

                  return (
                    <NavLink
                      key={index}
                      to={menuItem.path}
                      className="menu-item animate__animated animate__fadeInLeft"
                      onClick={closeAllSubmenus}
                      style={({ isActive }) => {
                        isActive || checkIsActive(menuItem.path) ? "active" : "";
                      }}
                    >
                      {IconComponent && <IconComponent className="menu-item-icon" />}
                      {!isExpanded ? (
                        <div className="menu-item-tooltip ">
                          <div className="tooltip-border"></div>
                          <span
                            style={{
                              fontSize: "10px",
                              paddingLeft: "5px",
                              paddingRight: "12px",
                            }}
                          >
                            {t(menuItem.title)}
                          </span>
                        </div>
                      ) : (
                        <span className={``}>{t(menuItem.title)}</span>
                      )}
                    </NavLink>
                  );
                } else if (menuItem.type === "sub" && menuItem.children) {
                  const isSubmenuExpanded = submenuState[index];
                  const IconComponent = menuItem.icon;

                  return (
                    <div
                      key={index}
                      className={`submenu-item animate__animated animate__fadeInLeft ${
                        isSubmenuExpanded ? "w-full" : ""
                      } `}
                      f
                      onMouseEnter={() => {
                        if (!isExpanded) handleSubmenuToggle(index);
                      }}
                      onMouseLeave={() => {
                        if (!isExpanded) handleSubmenuToggle(index);
                      }}
                    >
                      <div
                        onClick={() => handleSubmenuToggle(index)}
                        className={`submenu-header animate__animated animate__fadeInLeft ${
                          index === activeSubmenuHeader ? "active" : ""
                        }  ${!isExpanded && " gap-0px "} `}
                      >
                        {IconComponent && (
                          <IconComponent className={`menu-item-icon  ${!isExpanded && "justify-center"}`} />
                        )}
                        {isExpanded ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "2px",
                            }}
                          >
                            <span className={``}>{t(menuItem.title)}</span>
                            <KeyboardArrowDownOutlinedIcon
                              className="transition"
                              fontSize="12px"
                              style={{ marginRight: "5px" }}
                            />
                          </div>
                        ) : (
                          <KeyboardArrowRightOutlinedIcon
                            className="transition"
                            fontSize="10px"
                            style={{ marginRight: "-14px" }}
                          />
                        )}
                      </div>
                      {isSubmenuExpanded && (
                        <div className={`sub-menu-items ${!isExpanded ? "right-menu-items" : ""}`}>
                          {menuItem.children.map((subItem, subIndex) => (
                            <div className="" key={subIndex}>
                              <NavLink
                                className={`${isExpanded ? "menu-children-item" : "menu-children-item-nx"} `}
                                style={({ isActive }) => {
                                  isActive ? "active" : "";
                                }}
                                to={subItem.path}
                              >
                                <span>○ {t(subItem.title)}</span>
                              </NavLink>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  );
                }
                return null;
              })}
        </div>
        {/* <div className="settings-button transition">
          <button
            className={`buttonSettings transition ${!isExpanded ? "width-auto" : ""}`}
            onClick={toggleSidebarContent}
          >
            {showAlternateSidebar ? <KeyboardReturnOutlinedIcon /> : <SettingsOutlinedIcon />}
            {isExpanded && (
              <span>{showAlternateSidebar ? t("caymland.sidebar.settings.back") : t("caymland.core.settings")}</span>
            )}
          </button>
        </div> */}
      </div>

      <div className="nav-footer">
        <div className="border-line transition"></div>
        <div className="nav-details" onClick={toggleDropdown}>
          {/* Step 7: Pass modal visibility and close function as props */}
          <CustomModal show={modalShow} handleClose={closeModal} />
          <img src={getGravatarURL(user?.email)} alt="" srcSet="" className="nav-footer-avatar" />
          {isDropdownVisible && (
            <div className="dropdown-content">
              {/* Add your dropdown content here */}
              <button className="open-modal-button" onClick={openModal}>
                <HiOutlineSupport /> {t("caymland.core.supportticket")}
              </button>

              <Link to="/documentation" className="dropdown-items">
                <FiBookOpen /> {t("caymland.core.documentation")}
              </Link>
              <Link to="/edit-profile" className="dropdown-items">
                <FaUser className="icon" /> {t("caymland.core.account")}
              </Link>

              {/* Logout Link with Icon */}
              <Link to="/login" className="dropdown-items-logout">
                <FaSignOutAlt color="red" /> {t("caymland.core.logout")}
              </Link>
            </div>
          )}

          {isExpanded && (
            <div className="nav-footer-info">
              <p className="nav-footer-user-name">
                {user?.firstName} {user?.lastName}
              </p>
            </div>
          )}
        </div>
      </div>
      <button className={isExpanded ? " hamburger-in" : " hamburger-out"} onClick={toggleExpand}>
        <MdKeyboardArrowRight color="gray" size={24} />
      </button>
    </div>
  );
};

export default SideNavBar;
