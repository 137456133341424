import React, { useState, useEffect, useMemo } from "react";
import { MRT_ToggleGlobalFilterButton, MaterialReactTable } from "material-react-table";
import { ListItemIcon, MenuItem, Typography, Box } from "@mui/material";
import {} from "./MRT_ToggleSelect";
import Button from "../../reusableComponents/Buttons/Button";
import { MRT_CustomHeaderRowsAction } from "./plugins/MRT_CustomHeaderRowsAction";
import { MRT_CustomRowsAction } from "./plugins/MRT_CustomRowsAction";
import { useNavigate } from "react-router-dom";
import { Diversity1Sharp } from "@mui/icons-material";
import DetailPanel from "./DetailPanel";
import { debounce } from "lodash";
import { MRT_GlobalSearch } from "./MRT_GlobalSearch";
import { useTranslation } from "react-i18next";
import { MRT_Localization_DE } from "material-react-table/locales/de";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { useSelector } from "react-redux";
import DetailPanelView from "./DetailPanelView";

const CustomTable = ({
  data,
  columns,
  query,
  setQuery,
  actionButtons,
  actionBatchButtons,
  fieldsType,
  linkNavigate = "view",
  showDetailPanel = false,
  toolbarButtons,
  isFetching,
  viewTable,
  dynamicColumns = false,
  columnsClicks,
  setIsOpen,
  modal,
  sort = true,
  handleReorder,
  rowDragging = false,
  setGetRowData,
  order,
  setOrder,
  highlightedLink,
  hideActionButtons = false,
  handleClick,
  pagination = true,
  actions = true,
  searchFilter = true,
}) => {
  const { t } = useTranslation();
  const [overRowId, setOverRowId] = useState(null);
  const [sorting, setSorting] = useState([{ id: query.orderBy, desc: true }]);
  const [draggedRowId, setDraggedRowId] = useState(null);
  const locale = useSelector((state) => state?.auth?.user?.locale);
  const tableLocalization = locale === "de" ? MRT_Localization_DE : MRT_Localization_EN;
  const totalDBRowCount = data?.total || data?.length || data?.count;
  const navigate = useNavigate();

  const debouncedSetOrder = debounce((newOrder) => {
    setOrder(newOrder);
  }, 50);

  const updateOrderDuringDrag = (draggedOverRowId) => {
    if (!draggedRowId || draggedRowId === draggedOverRowId || overRowId === draggedOverRowId) return;

    setOverRowId(draggedOverRowId);

    let newOrder = [...order];
    let draggedIndex = newOrder.indexOf(draggedRowId);
    let draggedOverIndex = newOrder.indexOf(draggedOverRowId);

    if (draggedIndex < 0 || draggedOverIndex < 0) return;

    newOrder.splice(draggedIndex, 1);
    newOrder.splice(draggedOverIndex, 0, draggedRowId);

    setOrder(newOrder);
  };

  const onDragOver = (e, overId) => {
    e.preventDefault();
    updateOrderDuringDrag(overId);
  };

  const onDrop = (dropId) => {
    if (draggedRowId && dropId) {
      handleReorder(order);
    }
    setDraggedRowId(null);
  };

  const onDragStart = (rowId) => {
    setDraggedRowId(rowId);
  };

  useEffect(() => {
    if (rowDragging) {
      return;
    }
    if (sorting.length > 0) {
      setQuery((prevQuery) => ({
        ...prevQuery,
        orderByDir: sorting[0].desc ? "desc" : "asc",
        orderBy: sorting[0].id,
        pageIndex: 0,
      }));
    } else {
      setQuery((prevQuery) => ({
        ...prevQuery,
        orderByDir: "asc",
        orderBy: query.orderBy,
        pageIndex: 0,
      }));
      setSorting([{ id: query.orderBy, desc: query.orderByDir === "asc" ? true : false }]);
    }
  }, [sorting, setQuery]);

  const htmlToText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const columnsViewTable = useMemo(() => {
    if (dynamicColumns) {
      let keyCount = {};
      data?.data?.forEach((row) => {
        Object.keys(row).forEach((key) => {
          keyCount[key] = (keyCount[key] || 0) + 1;
        });
      });

      let columnKeys = Object.keys(keyCount).filter((key) => keyCount[key]);

      const numberColumn = {
        accessorKey: "rowNumber",
        header: "",
        accessorFn: (row, rowIndex) => {
          return query?.pageIndex * query?.pageSize + rowIndex + 1;
        },
        size: 80,
        enableColumnActions: false,
        enableColumnActions: false,
        enableSorting: false, // Disable sorting for the row number colu
      };
      return [
        numberColumn,
        ...columnKeys.map((key) => ({
          accessorKey: key,
          header: key,
          accessorFn: (row) => {
            return key === "Description" || "Beschreibung" ? htmlToText(row[key]) : row[key];
          },
          enableColumnActions: false,
          enableColumnActions: false,
          enableSorting: false, // Disable sorting for the row number colu
        })),
      ];
    } else {
      return [
        {
          accessorKey: "name",
          accessorFn: (row) =>
            row?.firstname ? row?.firstname + " " + row?.lastname : row?.ipAddresses && row?.ipAddresses[0]?.ip,
          header: t("caymland.lottery.column.name.name"),
          enableColumnActions: false,
          enableSorting: false,
        },
        {
          accessorKey: "email",
          accessorFn: (row) => row?.email,
          header: t("caymland.core.type.email"),
          enableColumnActions: false,
          enableSorting: false,
        },
        {
          accessorKey: "Location",
          accessorFn: (row) => row.location,
          header: t("caymland.lead.lead.thead.location"),
          enableColumnActions: false,
          enableSorting: false,
          Cell: ({ cell }) => {
            const { state, city, country } = cell?.row?.original;
            let flagLetters = cell.row?.original?.fields?.country
              ? cell.row.original?.country === "Switzerland"
                ? "ch"
                : cell?.row?.original?.country.toLowerCase()
              : "";
            return state || city || country ? (
              <div>
                {flagLetters && <i className={`flag-icon flag-icon-${flagLetters.toLowerCase()}`}></i>} {city}
                {city && state && ", "} {state}
                {!city && !state && country}
              </div>
            ) : null;
          },
        },
      ];
    }
  }, [data]);

  const orderedData = useMemo(() => {
    if (!order?.length || !data?.fields) {
      return data?.fields || [];
    }

    const dataMap = new Map(data?.fields?.map((item) => [item.id, item]));

    return order.map((id) => dataMap.get(id)).filter((item) => item);
  }, [order, data?.fields]);

  return (
    <MaterialReactTable
      data={
        rowDragging ? orderedData : (data?.data?.results || data?.data || data?.results || data?.fields || data) ?? []
      }
      localization={tableLocalization}
      layout="grid"
      enableRowOrdering={rowDragging}
      columns={
        viewTable && dynamicColumns
          ? columnsViewTable
          : viewTable
          ? [
              ...columnsViewTable,
              {
                accessorKey: "id",
                accessorFn: (row) => row.id,
                header: t("caymland.points.table.id"),
                size: 80,
                enableColumnActions: false,
                muiTableHeadCellProps: {
                  align: "right",
                  sx: {
                    paddingRight: 2,
                    verticalAlign: "bottom",
                  },
                },
                muiTableBodyCellProps: {
                  align: "right",
                  sx: {
                    paddingRight: 2,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "150px",
                  },
                },
              },
            ]
          : columns
          ? [
              ...columns,
              {
                accessorKey: "id",
                accessorFn: (row) => row.id,
                header: t("caymland.points.table.id"),
                size: 80,
                enableColumnActions: false,

                muiTableHeadCellProps: {
                  align: "right",
                },
                muiTableBodyCellProps: {
                  align: "right",
                },
              },
            ]
          : columnsClicks
      }
      enablePagination={pagination}
      manualPagination
      manualSorting
      onPaginationChange={setQuery}
      rowCount={totalDBRowCount}
      onSortingChange={setSorting}
      positionToolbarAlertBanner="bottom"
      state={{
        pagination: query,
        showProgressBars: isFetching,
        sorting: [{ id: query.orderBy, desc: query?.orderByDir === "desc" }],
      }}
      muiLinearProgressProps={({ isTopToolbar }) => ({
        color: "secondary",
        sx: {
          display: isTopToolbar ? "block" : "none",
        },
      })}
      enableColumnFilters={false}
      enableDensityToggle={false}
      enableTableFooter={linkNavigate === "advancedReport" && true}
      enableBottomToolbar={pagination}
      enableStickyFooter={linkNavigate === "advancedReport" && true}
      enableSorting={sort}
      enableGlobalFilter={viewTable ? false : true}
      onGlobalFilterChange={(search) => MRT_GlobalSearch(search, setQuery)}
      // enableFilters={false}
      enableColumnActions={false}
      enableRowDragging={rowDragging}
      enableStickyHeader={true}
      enableRowSelection={hideActionButtons ? false : viewTable ? false : actions}
      enableMultiRowSelection={viewTable ? false : actions}
      muiToolbarProps={{
        sx: {
          borderRadius: "8px",
        },
      }}
      displayColumnDefOptions={{
        "mrt-row-drag": {
          size: 20,
          Header: () => <></>,
        },
        ...(actionButtons && actionButtons.length > 0
          ? {
              "mrt-row-select": {
                accessorKey: "mrt-row-action",
                Header: ({ table }) => <MRT_CustomHeaderRowsAction selectAll table={table} />,
                Cell: ({ cell, row, table }) => <MRT_CustomRowsAction cell={cell} row={row} table={table} />,
                size: 80,
                enableSorting: false,
              },
            }
          : {}),
      }}
      renderRowActionMenuItems={({ row, closeMenu }) => {
        return actionButtons?.map((actionButton, index) => {
          const isDeleteButton = actionButton.name === t("caymland.mailbox.list.action.deleteMessage");
          const isSendEmailButton = actionButton.name === t("caymland.mailbox.message.send");
          const isDoNotContact = row.original.doNotContact?.length > 0;
          let shouldRenderButton = true;

          if (actionButton.name === t("caymland.lead.batch.dnc") && isDoNotContact) {
            shouldRenderButton = false;
          } else if (actionButton.name === t("plugin.dncevent.campaign.removeDnc.label") && !isDoNotContact) {
            shouldRenderButton = false;
          }
          if (shouldRenderButton) {
            return (
              <MenuItem
                key={index}
                onClick={() => {
                  closeMenu();
                  if (
                    actionButton.name === t("caymland.lead.batch.dnc") ||
                    actionButton.name === t("plugin.dncevent.campaign.removeDnc.label")
                  ) {
                    actionButton.onClick(row.original.id, isDoNotContact);
                  } else if (isDeleteButton || isSendEmailButton) {
                    actionButton.onClick(row.original);
                  } else {
                    actionButton.onClick(row.original.id);
                  }
                }}
                sx={{ m: 0, color: isDeleteButton ? "rgba(255, 0, 0, 0.7)" : undefined }}
              >
                <ListItemIcon sx={{ color: isDeleteButton ? "rgba(255, 0, 0, 0.7)" : undefined }}>
                  {actionButton.icon}
                </ListItemIcon>
                {actionButton.name}
              </MenuItem>
            );
          }
          return null;
        });
      }}
      renderCustomBatchActions={({ table, closeMenu }) => {
        let selectedRows = table.getSelectedRowModel().rows.map((e) => e.original.id);
        return actionBatchButtons?.map((e, i) => (
          <MenuItem
            key={i}
            onClick={() => {
              closeMenu();
              e.onClick(selectedRows, table);
            }}
            sx={{
              m: 0,
              color:
                e.name === t("caymland.core.form.delete_selected")
                  ? "rgba(255, 0, 0, 0.7)"
                  : e.name === "Set Do Not Contact"
                  ? "rgba(255, 0, 0, 0.7)"
                  : undefined,
            }}
          >
            <ListItemIcon
              sx={{ color: e.name === t("caymland.core.form.delete_selected") ? "rgba(255, 0, 0, 0.7)" : undefined }}
            >
              {e.icon}
            </ListItemIcon>
            {e.name}
          </MenuItem>
        ));
      }}
      renderToolbarInternalActions={({ table }) => (
        <>
          {viewTable ? <></> : searchFilter === false ? <></> : <MRT_ToggleGlobalFilterButton table={table} />}
          {/* <MRT_ShowHideColumnsButton table={table} /> */}
          {/* <MRT_FullScreenToggleButton table={table} /> */}
          {toolbarButtons?.map((item) => React.createElement(item.component, { table, ...item.props }))}
        </>
      )}
      getRowId={(originalRow, rowIndex) => `${originalRow.id || rowIndex}`}
      muiTablePaperProps={{
        sx: {
          boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.1) !important",
          borderRadius: "8px",
        },
      }}
      muiTableHeadCellProps={{
        sx: {
          verticalAlign: "bottom",
        },
      }}
      muiBottomToolbarProps={{
        sx: {
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
        },
      }}
      muiTableContainerProps={{
        sx: {
          height: !pagination ? "auto" : viewTable ? "50px" : "calc(100vh - 195px)",
          overflowY: "auto",
          minHeight: "487px",
        },
      }}
      initialState={{
        density: "compact",
      }}
      muiTableBodyRowProps={({ row }) => {
        return {
          draggable: handleReorder ? true : false,
          onDragStart: () => onDragStart(row?.original?.id),
          onDragOver: (e) => {
            onDragOver(e, row?.original?.id);
          },
          onDrop: () => onDrop(row?.original?.id),
          onClick: (e) => {
            if (linkNavigate === "advancedReport") {
              handleClick(e, row?.original?.redirect_id);
              return;
            }
            if (linkNavigate === "categories") {
              setIsOpen(true);
              modal("Edit", row?.original?.id);
            } else if (linkNavigate === "tags") {
              modal({ type: "edit", id: row.original.id, isOpen: true });
            } else if (linkNavigate === null) {
              return;
            } else {
              navigate(
                viewTable ? `/contacts/${linkNavigate}/${row?.original?.id}` : `${linkNavigate}/${row?.original?.id}`,
                {
                  state: { id: row?.original?.id },
                }
              );
            }
          },
          sx: {
            cursor: "pointer",
            backgroundColor:
              linkNavigate === "advancedReport" &&
              row?.original?.redirect_id === highlightedLink &&
              "rgb(224, 224, 224)",
          },
        };
      }}
      {...(showDetailPanel && {
        renderDetailPanel: ({ row }) => {
          const data = viewTable ? row.original : row.original.fields.core;
          const sortedFields = Object.values(data)
            .filter((field) => field.label && field.value !== null && field.value !== undefined)
            .sort((a, b) => parseInt(a.order) - parseInt(b.order));

          const nonEmptyFields = {};

          sortedFields.forEach((field) => {
            nonEmptyFields[field.label] = field.value;
          });

          const nonEmptyFieldsView = Object.entries(data).filter(
            ([key, value]) =>
              value !== null &&
              value !== undefined &&
              typeof value === "string" &&
              value.trim() !== "" &&
              key !== "dateModified" &&
              key !== "dateIdentified" &&
              key !== "preferredProfileImage" &&
              key !== "dateAdded" &&
              key !== "leadlist_id"
          );

          const thirdPoint = Math.ceil(Object.keys(nonEmptyFields).length / 3);
          const twoThirdPoint = 2 * thirdPoint;

          if (Object.keys(nonEmptyFields).length > 0) {
            return (
              <DetailPanel thirdPoint={thirdPoint} twoThirdPoint={twoThirdPoint} nonEmptyFields={nonEmptyFields} />
            );
          } else {
            return (
              <DetailPanelView
                thirdPoint={thirdPoint}
                twoThirdPoint={twoThirdPoint}
                nonEmptyFields={nonEmptyFieldsView}
              />
            );
          }
        },
      })}
    />
  );
};

export default CustomTable;
