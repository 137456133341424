import React, { useMemo, useState } from "react";
import { useHistoryTabQuery } from "../../../../../redux/api/contactsApi";
import CustomTable from "../../CustomTable/CustomTable";
import moment from "moment";
import { MRT_ToggleSelect } from "../../../../reusableComponents/CustomTable/MRT_ToggleSelect";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import MailModal from "./MailModal";
import { useMessageReadMutation } from "../../../../../redux/api/mailboxApi";
import PostalMime from "postal-mime";
import { FileDownloadOutlined as FileDownloadOutlinedIcon } from "@mui/icons-material";
import { Archive, Forward, Reply, ReplyAll } from "lucide-react";


const History = ({ id }) => {
  const [activeRowId, setActiveRowId] = useState();
  const [selectedEventTypes, setSelectedEventTypes] = useState([]);
  const [parsedEmail, setParsedEmail] = useState(null);
  const [show, setShow] = useState(false);
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: 30,
    orderByDir: "DESC",
    // orderBy: "timestamp",
  });

  const { data, isFetching } = useHistoryTabQuery({ id, query, includeEvents: selectedEventTypes });
  const navigate = useNavigate();

  const [messageRead] = useMessageReadMutation();

  const sendReadRequest = async (sendHash) => {
    try {
      let response = await messageRead(sendHash);
      let rawData = response?.data || (response?.error && response?.error?.data);

      if (rawData) {
        const parser = new PostalMime();
        try {
          const parsedEmailData = await parser.parse(rawData);
          setParsedEmail(parsedEmailData);
        } catch (parseError) {
          console.error("Error parsing email data:", parseError);
        }
      } else {
        console.error("No valid data in the response");
      }
    } catch (error) {
      console.error("Failed to mark message as read", error);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "eventLabel",
        accessorFn: (row) => {
          const label = row?.eventLabel?.label ? row.eventLabel.label : row.eventType;
          return label.length > 30 ? label.substring(0, 30) + "..." : label;
        },
        Cell: ({ cell }) => {
          const row = cell.row.original;
          const href = row?.eventLabel?.href;
          const hrefRedirect = row?.eventLabel?.href?.includes("email");
          const hitPageRedirect = row?.event === "page.hit";
          const handleClick = () => {
            if (row?.eventLabel?.isModal) {
              sendReadRequest(row?.extra?.stat?.message_id);
              setShow(true);
            }
            return;

            if (hitPageRedirect || hrefRedirect) {
              window.open(href, "_blank");
            } else if (href) {
              navigate(href);
            }
          };

          return (
            <div onClick={handleClick} style={{ cursor: "pointer" }}>
              {cell.getValue()}
            </div>
          );
        },
        header: t("caymland.lead.timeline.event_name"),
        enableColumnActions: false,
      },

      {
        // Update this column definition
        accessorKey: "eventType",
        accessorFn: (row) => row.eventType,
        header: t("caymland.lead.timeline.event_type"),
        enableColumnActions: false,
      },
      {
        accessorKey: "timestamp",
        accessorFn: (row) => {
          // Use Moment.js to format the timestamp
          return moment(row?.timestamp).format("DD.MM.YY HH:mm");
        },
        header: t("caymland.lead.timeline.event_timestamp"),
        enableColumnActions: false,
        muiTableHeadCellProps: {
          align: "right",
          sx: {
            verticalAlign: "bottom",
            zIndex: 0,
          },
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
    ],
    []
  );
  let options = [];

  // Check if types is defined before trying to create the options array
  if (data && data?.types) {
    options = Object.entries(data.types).map(([key, value]) => ({
      label: value,
      value: key,
    }));
  }

  const handleEventTypeChange = (selectedOptions) => {
    // Assuming selectedOptions is an array of selected event types
    setSelectedEventTypes(selectedOptions);
  };

  const toolbarButtons = useMemo(() => [
    {
      name: "history",
      component: MRT_ToggleSelect,
      props: {
        data: options,
        onChange: handleEventTypeChange,
        value: selectedEventTypes,
        title: t("caymland.lead.lead.tab.history"),
        isMulti: true,
      },
    },
  ]);

  function createEmlContent(mail) {
    if (!mail) return;

    let emlContent = "";

    mail.headers.forEach((header) => {
      emlContent += `${header.name}: ${header.value}\r\n`;
    });

    emlContent += `Subject: ${mail.subject}\r\n`;
    emlContent += `From: ${mail.from.address}\r\n`;
    emlContent += `To: ${mail.to.map((to) => to.address).join(", ")}\r\n`;
    emlContent += "\r\n";

    if (mail.text) {
      emlContent += mail.text + "\r\n\r\n";
    }
    if (mail.html) {
      emlContent += mail.html + "\r\n\r\n";
    }

    return emlContent;
  }

  function downloadEmlFile(mail) {
    if (!mail) return;
    const emlContent = createEmlContent(mail);
    const blob = new Blob([emlContent], { type: "message/rfc822" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `email-${new Date().getTime()}.eml`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  const replyToMail = () => {
    if (!parsedEmail) return;

    const initialData = {
      subject: `Re: ${parsedEmail?.subject}`,
      body: parsedEmail?.html || parsedEmail?.text,
      to: parsedEmail?.from.address,
    };

    navigate('/mailbox', { state: { initialData,  action: "Reply" } });
  };

  const replyAllToMail = () => {
    if (!parsedEmail) return;
  
    const initialData = {
      subject: `Re: ${parsedEmail?.subject}`,
      body: parsedEmail?.html || parsedEmail?.text,
      to: parsedEmail?.from.address,
      cc: parsedEmail?.cc?.map((recipient) => recipient.address).join(", "),
    };
  
    navigate('/mailbox', { state: { initialData, action: "Reply All" } });
  };

  const forwardMail = () => {
    if (!parsedEmail) return;
  
    const initialData = {
      subject: `Fw: ${parsedEmail?.subject}`,
      body: parsedEmail?.html || parsedEmail?.text,
      attachments: parsedEmail?.attachments || [],
    };
  
    navigate('/mailbox', { state: { initialData, action: "Forward" } });
  };

  const buttons = [
    {
      name: "download",
      title: t("caymland.mailbox.list.action.download"),
      icon: <FileDownloadOutlinedIcon size={15} />,
      onClick: () => downloadEmlFile(parsedEmail),
    },
    {
      name: "reply",
      title: t("caymland.mailbox.list.action.reply"),
      icon:  <Reply className="tw-h-5 tw-w-5" />,
      onClick: replyToMail,
    },
    {
      name: "replyAll",
      title: t("caymland.mailbox.list.action.replyAll"),
      icon:  <ReplyAll className="tw-h-5 tw-w-5" />,
      onClick: replyAllToMail,
    },
    {
      name: "forward",
      title: t("caymland.mailbox.list.action.forward"),
      icon:   <Forward className="tw-h-5 tw-w-5" />,
      onClick: forwardMail,
    },
  ];

  return (
    <div className="h-full">
      <MailModal setShow={setShow} show={show} mail={parsedEmail} buttons={buttons} />
      <CustomTable
        data={data}
        columns={columns}
        query={query}
        setQuery={setQuery}
        showDetailPanel
        footer
        mediumHeight
        actions={false}
        toolbarButtons={toolbarButtons}
        isFetching={isFetching}
        linkNavigate={null}
        header
        history
        setActiveRowId={setActiveRowId}
      />
    </div>
  );
};

export default History;
