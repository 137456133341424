import React, { useMemo, useState } from "react";
import {
  useAddNoteMutation,
  useDeleteNoteMutation,
  useNoteTabQuery,
  useUpdateNoteMutation,
} from "../../../../../redux/api/contactsApi";
import CustomTable from "../../CustomTable/CustomTable";
import moment from "moment";
import CallIcon from "@mui/icons-material/Call";
import { TbSend } from "react-icons/tb";
import GroupsIcon from "@mui/icons-material/Groups";
import ReceiptIcon from "@mui/icons-material/Receipt";
import CustomModal from "../Modal/CustomModal";
import { ActionButtons } from "../../../../reusableComponents/tableComponents/ActionButtons";
import { MRT_ToggleSelect } from "../../../../reusableComponents/CustomTable/MRT_ToggleSelect";
import { t } from "i18next";

const Notes = ({ id }) => {
  const [addNote] = useAddNoteMutation();
  const [deleteNote] = useDeleteNoteMutation();
  const [updateNote] = useUpdateNoteMutation();
  const [selectedEventTypes, setSelectedEventTypes] = useState([]);
  const [showModal, setShowModal] = useState({
    isOpen: false,
    type: "add",
    id: null,
  });
  const [activeRowId, setActiveRowId] = useState();
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: 30,
    orderByDir: "ASC",
    orderBy: "dateTime",
  });
  const [noteTypes, setNoteTypes] = useState([]);

  console.log(noteTypes);
  const { data, isFetching, refetch } = useNoteTabQuery({ id, query, noteTypes });
  console.log(data);

  const typeIcons = {
    email: <TbSend style={{ height: "20px", width: "20px" }} />,
    call: <CallIcon />,
    meeting: <GroupsIcon />,
    general: <ReceiptIcon />,
  };
  const handleRowClick = (row) => {
    setActiveRowId(row.id);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "type",
        accessorFn: (row) => row.type,
        header: "",
        size: "unset",
        enableColumnActions: false,
        Cell: ({ cell }) => {
          if (cell.row.original.type && typeIcons[cell.row.original.type]) {
            return typeIcons[cell.row.original.type];
          } else {
            return "Type not recognized";
          }
        },
        muiTableHeadCellProps: {
          sx: {
            verticalAlign: "bottom",
            zIndex: 0,
            width: "10px",
          },
        },
        muiTableBodyCellProps: {},
      },
      {
        accessorKey: "text",
        accessorFn: (row) => row.text,
        header: t("caymland.core.type.text"),
        enableColumnActions: false,
      },
      {
        accessorKey: "createdByUser",
        accessorFn: (row) => row.createdByUser,
        header: t("caymland.showare.field.created_by"),
        enableColumnActions: false,
      },
      {
        accessorKey: "dateAdded",
        accessorFn: (row) => {
          return moment(row?.dateAdded).format("DD.MM.YY HH:mm");
        },
        header: t("caymland.showare.field.date_added"),
        enableColumnActions: false,
        muiTableHeadCellProps: {
          align: "right",
          sx: {
            verticalAlign: "bottom",
            zIndex: 0,
          },
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
    ],
    []
  );
  const options = [
    { label: t("caymland.lead.note.type.general"), value: "general" },
    { label: t("caymland.lead.note.type.call"), value: "call" },
    { label: t("caymland.lead.note.type.email"), value: "email" },
    { label: t("caymland.lead.note.type.meeting"), value: "meeting" },
  ];
  const handleEventTypeChange = (selectedOptions) => {
    // Assuming selectedOptions is an array of selected event types
    setSelectedEventTypes(selectedOptions);
    setNoteTypes(selectedOptions);
  };
  console.log(selectedEventTypes);

  const toolbarButtons = useMemo(() => [
    {
      name: "notes",
      component: MRT_ToggleSelect,
      props: {
        data: options,
        onChange: handleEventTypeChange,
        value: selectedEventTypes,
        title: t("caymland.lead.lead.tab.notes"),
        isMulti: true,
      },
    },
    {
      name: "Add Note",
      component: CustomModal,
      props: {
        showModal: showModal,
        setShowModal: setShowModal,
        addNote: addNote,
        id: id,
        refetch: refetch,
        add: "add",
        noteId: activeRowId,
        updateNote: updateNote,
        setNoteTypes: setNoteTypes,
      },
    },
  ]);

  const actionButtons = ActionButtons({
    link: "notes",
    clone: false,
    deleteItem: deleteNote,
    openModal: setShowModal,
    refetch: refetch,
  });
  return (
    <div>
      {
        <CustomTable
          data={data?.notes}
          columns={columns}
          mediumHeight
          header
          footer
          query={query}
          setQuery={setQuery}
          toolbarButtons={toolbarButtons}
          actionButtons={actionButtons}
          isFetching={isFetching}
          linkNavigate={null}
          onRowClick={handleRowClick}
        />
      }
    </div>
  );
};

export default Notes;
