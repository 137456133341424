import React, { useState, useEffect, useMemo, useRef } from "react";
import { MRT_ToggleGlobalFilterButton, MaterialReactTable } from "material-react-table";
import { ListItemIcon, MenuItem, Typography, Box } from "@mui/material";
// import {} from "./MRT_ToggleSelect";
import { MRT_CustomRowsAction } from "../../../reusableComponents/CustomTable/plugins/MRT_CustomRowsAction";
import { useNavigate } from "react-router-dom";
import { Diversity1Sharp } from "@mui/icons-material";
import { MRT_CustomHeaderRowsAction } from "../../../reusableComponents/CustomTable/plugins/MRT_CustomHeaderRowsAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import DetailsPanel from "../Tabs/DetailPanel/DetailsPanel";
import { MRT_CustomExpandButton } from "./MRT_CustomExpandButton";
import { t } from "i18next";
import { MRT_Localization_DE } from 'material-react-table/locales/de';



const CustomTable = ({
  data,
  columns,
  query,
  setQuery,
  actionButtons,
  actionBatchButtons,
  fieldsType,
  linkNavigate = "view",
  showDetailPanel = false,
  toolbarButtons,
  isFetching,
  viewTable,
  columnsClicks,
  setIsOpen,
  modal,
  smallHeight,
  mediumHeight,
  setActiveRowId,
  footer = false,
  actions = true,
  header = false,
  history = false,
  tickets = false,
}) => {
  const totalDBRowCount = data?.total;
  const navigate = useNavigate();
  const [rowsId, setRowsId] = useState();

  const [sorting, setSorting] = useState([]);

  useEffect(() => {
    if (sorting.length) {
      setQuery({
        ...query,
        ...{
          orderByDir: sorting[0].desc ? "ASC" : "DESC",
          orderBy: sorting[0].is_priority,
        },
      });
    }
  }, [sorting]);

  const rowsPerPageOptions = history ? [] : [5, 10, 20, 30, 50, 100]; // Example options, adjust as needed

  const processHtmlContent = (htmlContent) => {
    // Create a temporary div element to parse and manipulate the HTML content
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;

    // Get all button elements from the parsed HTML
    const buttons = tempDiv.querySelectorAll("button");

    // Remove the unwanted button elements
    buttons.forEach((button) => {
      button.remove();
    });

    // Replace &nbsp; with an empty string
    tempDiv.innerHTML = tempDiv.innerHTML.replace(/&nbsp;/g, "");

    // Convert the modified HTML content back to a string
    const modifiedHtmlContent = tempDiv.innerHTML;

    return { __html: modifiedHtmlContent };
  };
  return (
    <MaterialReactTable
      data={
        (data?.data?.results || data?.uploads || data?.data || data?.results || data?.fields || data?.events || data) ??
        []
      }
      layout="grid"
      columns={columns}
      enablePagination={true}
      manualPagination
      manualSorting
      onPaginationChange={setQuery}
      rowCount={totalDBRowCount}
      onSortingChange={setSorting}
      enableTopToolbar={header}
      // localization={{ noRecordsToDisplay: 'Keine Datensätze zum Anzeigen'}}
      localization={MRT_Localization_DE}

      icons={{
        ExpandMoreIcde: (props) => "",
      }}
      // icons={{
      //   ExpandMoreIcon: ({ row }) => {
      //     const iconClass = row?.original?.icon || "fa-user"; // Default class if icon not specified
      //     return <i className={`fa ${iconClass}`} style={{ fontSize: "16px" }}></i>;
      //   },
      // }}
      //   positionToolbarAlertBanner="bottom"
      state={{
        pagination: query,
        showProgressBars: isFetching,
        sorting: [{ is_sorting: query?.orderBy, desc: query?.orderByDir === "asc" }],
      }}
      muiLinearProgressProps={({ isTopToolbar }) => ({
        color: "secondary",
        sx: {
          display: isTopToolbar ? "block" : "none",
        },
      })}
      enableColumnFilters={false}
      enableDensityToggle={false}
      enableBottomToolbar={footer}
      enableSorting={false}
      enableFilters={false}
      enableColumnActions={false}
      enableStickyHeader
      enableRowSelection={actions}
      enableMultiRowSelection={actions}
      muiTablePaginationProps={{
        rowsPerPageOptions: rowsPerPageOptions, // This removes the 'Rows Per Page' option
      }}
      displayColumnDefOptions={{
        "mrt-row-select": {
          accessorKey: "mrt-row-action",
          Header: ({ table }) => <MRT_CustomHeaderRowsAction dontShowSelect table={table} />,
          Cell: ({ cell, row, table }) => <MRT_CustomRowsAction dontShowSelect cell={cell} row={row} table={table} />,
          size: 10,
          enableSorting: false,
        },
        "mrt-row-expand": {
          Cell: ({ cell, row, table }) => (
            <MRT_CustomExpandButton cell={cell} row={row} table={table} history={history} />
          ),
        },
      }}
      renderRowActionMenuItems={({ row, closeMenu }) =>
        actionButtons?.map((e, i) => {
          const isDeleteButton = e.name === t("caymland.mailbox.list.action.deleteMessage");
          return (
            <MenuItem
              key={i}
              onClick={() => {
                closeMenu();
                if (e.name === "Set Do Not Contact" || e.name === "Remove Do Not Contact") {
                  e.onClick(row.original.id, row.original.doNotContact?.length > 0 ? true : false);
                } else if (e.name === t("caymland.mailbox.list.action.deleteMessage")) {
                  e.onClick(row.original);
                } else {
                  e.onClick(row.original.id);
                }
              }}
              sx={{ m: 0, color: isDeleteButton ? "rgba(255, 0, 0, 0.7)" : undefined }}
            >
              <ListItemIcon
                sx={{
                  color:
                    e.name === t("caymland.mailbox.list.action.deleteMessage") ? "rgba(255, 0, 0, 0.7)" : undefined,
                }}
              >
                {e.icon}
              </ListItemIcon>
              {e.name}
            </MenuItem>
          );
        })
      }
      renderToolbarInternalActions={({ table }) => (
        <>
          <MRT_ToggleGlobalFilterButton table={table} />
          {toolbarButtons?.map((item) => React.createElement(item.component, { table, ...item.props }))}
        </>
      )}
      getRowId={(originalRow) => originalRow.id}
      muiTablePaperProps={{
        sx: {
          boxShadow: "none",
        },
      }}
      muiTableHeadCellProps={{
        sx: {
          verticalAlign: "bottom",
        },
      }}
      muiBottomToolbarProps={{
        sx: {
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
        },
      }}
      muiTableContainerProps={{
        sx: {
          // height: viewTable ? "500px" : smallHeight ? "100%" : mediumHeight ? "100%" : "calc(100vh - 195px)",
          overflowY: "auto",
          minHeight: viewTable ? "500px" : smallHeight ? "354px" : mediumHeight ? "782px" : "calc(100vh - 195px)",
          maxHeight: viewTable ? "500px" : smallHeight ? "354px" : mediumHeight ? "1190px" : "calc(100vh - 195px)",
        },
      }}
      initialState={{
        density: "compact",
      }}
      muiTableBodyRowProps={({ row }) => {
        return {
          onClick: () => {
            if (linkNavigate === "categories") {
              setIsOpen(true);
              modal("Edit", row?.original?.id);
            } else if (linkNavigate === null) {
              return;
            } else {
              navigate(
                viewTable
                  ? `/contacts/${linkNavigate}/${row?.original?.id}`
                  : linkNavigate === "companyContacts"
                  ? `/contacts/view/${row?.original?.id}`
                  : `${linkNavigate}/${row?.original?.id}`,
                {
                  state: { id: row?.original?.id }, // Pass the index here
                }
              );
            }
          },
          sx: {
            cursor: "pointer",
          },
        };
      }}
      {...(showDetailPanel && {
        renderDetailPanel: ({ row }) => {
          setRowsId(row);
          // Assuming 'history' and 'assetData' are defined and accessible here
          if (history) {
            // Display asset if history is true
            const hitData = row?.original?.extra?.hit;
            const statData = row?.original?.extra?.stat;
            const assetData = row.original?.extra; // assuming asset data is in row.original.extra
            const isImage = ["png", "jpg", "jpeg", "gif", "bmp", "svg"].includes(assetData?.asset?.extension);
            const pageData = row?.original?.extra?.hit;
            return (
              <>
                {assetData?.asset ? (
                  <div style={{ padding: "20px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {isImage ? (
                      <img src={`${assetData?.assetDownloadUrl}?stream=1&api=1`} alt="Asset" />
                    ) : (
                      <iframe src={`${assetData?.assetDownloadUrl}?stream=1&api=1`} width="100%" height="500" />
                    )}
                  </div>
                ) : row?.original?.eventContent ? (
                  <div dangerouslySetInnerHTML={processHtmlContent(row?.original.eventContent)} />
                ) : (
                  <></>
                )}
              </>
            );
          } else if (tickets) {
            return <DetailsPanel data={row.original} />;
          } else {
            const fields = row.original?.details?.fields || {};
            return (
              <div className="detail-table">
                <div className="detail-row header-row">
                  <div className="detail-cell">{t("caymland.showare.table.object")}</div>
                  <div className="detail-cell">{t("caymland.showare.table.newvalue")}</div>
                  <div className="detail-cell">{t("caymland.showare.table.oldvalue")}</div>
                </div>
                {Object.entries(fields).map(([field, values]) => (
                  <div key={field} className="detail-row">
                    <div className="detail-cell">{field}</div>
                    <div className="detail-cell">
                      {Array.isArray(values.newValue) ? (
                        values.newValue.map((newValue, idx) => (
                          <div key={idx} className="detail-subcell">
                            <div dangerouslySetInnerHTML={processHtmlContent(newValue)} />
                          </div>
                        ))
                      ) : (
                        <div className="detail-subcell">{values.newValue}</div>
                      )}
                    </div>
                    <div className="detail-cell">
                      {Array.isArray(values.oldValue) ? (
                        values.oldValue.map((oldValue, idx) => (
                          <div key={idx} className="detail-subcell">
                            <div dangerouslySetInnerHTML={processHtmlContent(oldValue)} />
                          </div>
                        ))
                      ) : (
                        <div className="detail-subcell">{values.oldValue === "&nbsp" ? "" : values.oldValue}</div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            );
          }
        },
      })}
    />
  );
};

export default CustomTable;
