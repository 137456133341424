import React, { useEffect, useMemo, useState } from "react";
import CustomTable from "../CustomTable/CustomTable";
import LinkCompaniesToContactModal from "../LinkCompaniesToContactModal";
import { ActionButtons } from "../../../reusableComponents/tableComponents/ActionButtons";
import { useRemoveCompanyToContactMutation, useSetPrimaryMutation } from "../../../../redux/api/contactsApi";
import { useParams } from "react-router-dom";
import { FaCheck, FaTimes } from "react-icons/fa";
import { Tooltip } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import AddHomeIcon from "@mui/icons-material/AddHome";
import { useTranslation } from "react-i18next";

const Companies = ({
  companiesFields,
  setMessage,
  setShow,
  updateCompanies,
  isFetching,
  replaceCompanies,
  setBackgroundToast,
}) => {
  const { t } = useTranslation();
  const [activeRowId, setActiveRowId] = useState(null);

  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: 30,
    orderByDir: "DESC",
    orderBy: "is_priority",
  });
  const { id } = useParams();
  const columns = useMemo(
    () => [
      {
        accessorKey: "companyname",
        accessorFn: (row) => row?.companyname,
        header: t("caymland.lead.field.companyname"),
        enableColumnActions: false,
      },
      {
        accessorKey: "is_priority",
        accessorFn: (row) => row?.is_primary,
        header: t("caymland.core.main"),
        size: 80,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <div
            className="hover-parent"
            style={{ marginRight: "6px" }}
            onClick={(e) => {
              e.stopPropagation();
              updatePrimary(cell.row.original.id);
            }}
          >
            {cell.row.original.is_primary === "1" ? (
              <Tooltip title="Is Primary" arrow>
                <HomeIcon fontSize="small" style={{ color: "gray" }} />
              </Tooltip>
            ) : (
              <Tooltip title="Set Primary" arrow>
                <AddHomeIcon className="hide-on-hover" fontSize="small" style={{ color: "gray" }} />
              </Tooltip>
            )}
          </div>
        ),
        muiTableHeadCellProps: {
          align: "right",
          sx: {
            verticalAlign: "bottom",
            zIndex: 0,
          },
        },
        muiTableBodyCellProps: {
          align: "right",
          sx: {},
        },
      },
    ],
    [companiesFields]
  );

  const [removeCompanyToContact] = useRemoveCompanyToContactMutation();
  const [setPrimary] = useSetPrimaryMutation();

  const updatePrimary = async (companyId) => {
    try {
      const response = await setPrimary({
        contactId: id,
        companyId: companyId,
      });

      if (!response.error && response.data) {
        // Extract newPrimary and oldPrimary from response
        const newPrimaryId = parseInt(response.data.data.newPrimary);
        const oldPrimaryId = parseInt(response.data.data.oldPrimary);

        // Find indexes
        const newPrimaryIndex = companiesFields.findIndex(({ id }) => parseInt(id) === newPrimaryId);
        const oldPrimaryIndex = companiesFields.findIndex(({ id }) => parseInt(id) === oldPrimaryId);

        // Update the new primary company's is_primary to "1"
        if (newPrimaryIndex !== -1) {
          updateCompanies(newPrimaryIndex, { ...companiesFields[newPrimaryIndex], is_primary: "1" });
        }

        // Update the old primary company's is_primary to "0"
        if (oldPrimaryIndex !== -1) {
          updateCompanies(oldPrimaryIndex, { ...companiesFields[oldPrimaryIndex], is_primary: "0" });
        }
        setShow(true);
        setMessage(t("caymland.core.setmessage.company"));
        setTimeout(() => {
          setShow(false);
          setMessage("");
        }, 3000);
      } else {
        setShow(true);
        setMessage(response.error.data.errors[0].message);
        setTimeout(() => {
          setShow(false);
          setMessage("");
        }, 3000);
      }
    } catch (error) {
      console.error("An error occurred while updating the primary company:", error);
    }
  };

  const [sortedCompanies, setSortedCompanies] = useState();




  useEffect(() => {
    setSortedCompanies(
      [...companiesFields].sort((a, b) => {
        const isPrimaryA = parseInt(a.is_primary, 10);
        const isPrimaryB = parseInt(b.is_primary, 10);
        return isPrimaryB - isPrimaryA;
      })
    );
  }, [companiesFields]);

  const addNewCompany = (newCompany) => {
    setSortedCompanies((prevCompanies) => [...prevCompanies, newCompany]);
  };

  const actionButtons = ActionButtons({
    link: "companies",
    removeItem: removeCompanyToContact,
    setShow: setShow,
    setMessage: setMessage,
    setBackgroundToast: setBackgroundToast,
    replaceCompanies: replaceCompanies,
    companiesFields: companiesFields,
    edit: false,
    clone: false,
    _delete: false,
    showRemoveCompany: true,
    folderId: id,
    setSorted: setSortedCompanies,
    sortedCompanies: sortedCompanies,
  });


  return (
    <div
      style={{
        backgroundColor: "inherit",
        boxShadow: "none",
        height: "400px",
      }}
    >
      <div className="w-full justify-between d-flex items-center mb-10">
        <span style={{ fontWeight: "bold", textTransform: "uppercase", fontSize: 16, marginBottom: "6px " }}>
          {t("caymland.companies.menu.index")}
        </span>
        <LinkCompaniesToContactModal
          setMessage={setMessage}
          setShow={setShow}
          replaceCompanies={replaceCompanies}
          companiesFields={companiesFields}
          addNewCompany={addNewCompany}
          setBackgroundToast={setBackgroundToast}
        />
      </div>
      <CustomTable
        data={sortedCompanies}
        columns={columns}
        query={query}
        setQuery={setQuery}
        smallHeight
        setActiveRowId={setActiveRowId}
        actionButtons={actionButtons}
        linkNavigate="/companies/view"
        // isFetching={isFetching}
      />
    </div>
  );
};

export default Companies;
