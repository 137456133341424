import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBaseQuery } from "../utils/customFetchBaseQuery";
import { customTransformResponse } from "../utils/customTransformResponse";
import qs from "qs";

export const ordersApi = createApi({
  reducerPath: "ordersApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["Orders"],
  keepUnusedDataFor: 30,
  endpoints: (build) => ({
    getOrders: build.query({
      query: (q) =>
        `/api/brochures/orders?${qs.stringify({
          ...q,
          ...{ start: q.pageIndex * q.pageSize, limit: q.pageSize },
        })}`,
      keepUnusedDataFor: false,
      providesTags: ["Orders"],
      transformResponse: customTransformResponse,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getOrder: build.query({
      query: (id) => `api/brochures/orders/${id}`,
      providesTags: (id) => [{ type: "Orders", id }],
      lazy: true,
    }),
    getOrderFields: build.query({
      query: (id) => `api/brochures/orders/fields`,
      providesTags: (id) => [{ type: "Orders", id }],
      lazy: true,
    }),
    addOrder: build.mutation({
      query: (body) => ({
        url: `api/brochures/orders/new`,
        method: "POST",
        body,
      }),
    }),
    updateOrder: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/api/brochures/orders/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["Orders"],
    }),
    deleteOrder: build.mutation({
      query: (id) => ({
        url: `/api/brochures/orders/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["Orders"],
    }),
    exportOrders: build.mutation({
      query: (body) => ({
        url: `api/brochures/orders/export/batch`,
        method: "POST",
        body,
        responseHandler: (response) => response.blob()
      }),
    }),
    

    //FOLDERS
    //GET FOLDERS
    getFolderOrders: build.query({
      query: (q) => `/api/folders?limit=100&where[0][col]=source&where[0][expr]=eq&where[0][val]=brochures.orders`,
      transformResponse: customTransformResponse,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },

      forceRefetch({ currentArg, previousArg }, arg) {
        return currentArg !== previousArg;
      },
    }),
    //ADD FOLDER
    addFolderOrders: build.mutation({
      query: (body) => ({
        url: `api/folders/new`,
        method: "POST",
        body,
      }),
    }),
    //EDIT FOLDER
    updateFolderOrders: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `api/folders/${id}/edit`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Orders", id }],
    }),
    //DELETE FOLDER
    deleteFolderOrders: build.mutation({
      query: (id) => ({
        url: `/api/folders/${id}/delete`,
        method: "DELETE",
      }),

      invalidatesTags: (result, error, id) => [{ type: "Orders", id }],
    }),
    //MOVE EMAIL TO FOLDER
    updateOrderToFolders: build.mutation({
      query: ({ id, itemId, ...patch }) => ({
        url: `api/folders/${id}/borders/${itemId}`,
        method: "PATCH",
      }),
    }),
    //REMOVE EMAIL TO FOLDER
    removeOrderToFolders: build.mutation({
      query: ({ id, itemId, ...patch }) => ({
        url: `api/folders/${id}/borders/${itemId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetOrdersQuery,
  useGetOrderFieldsQuery,
  useGetOrderQuery,
  useExportOrdersMutation,
  useUpdateOrderMutation,
  useDeleteOrderMutation,
  useAddOrderMutation,

  // //FOLDERS
  useGetFolderOrdersQuery,
  useUpdateFolderOrdersMutation,
  useDeleteFolderOrdersMutation,
  useAddFolderOrdersMutation,
  useUpdateOrderToFoldersMutation,
  useRemoveOrderToFoldersMutation,
} = ordersApi;
