import Swal from "sweetalert2";
import { useBatchDeleteMutation } from "../../../redux/api/batchDeleteApi";
import { batchDeleteParamsGenerator } from "../../helpers/batchDeleteParamsGenerator";
import DisplaySettingsOutlinedIcon from "@mui/icons-material/DisplaySettingsOutlined";
import { FaTrashAlt, FaBan, FaSignal } from "react-icons/fa";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { AiOutlineFolderOpen } from "react-icons/ai";
import RouteOutlinedIcon from "@mui/icons-material/RouteOutlined";
import { FiUsers } from "react-icons/fi";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useTranslation } from "react-i18next";
export const ActionBatchButtons = ({
  _delete = true,
  changeCampaigns = false,
  changeCategories = false,
  changeSegments = false,
  changeStage = false,
  changeOwner = false,
  changeStatus = false,
  exportFile = false,
  link,
  setModalOptions,
  refetch,
  handleBatchExport
}) => {
  const { t } = useTranslation();
  const [deleteItems] = useBatchDeleteMutation();

  const handleBatchDelete = async (selectedRows, table) => {
    const params = batchDeleteParamsGenerator(selectedRows);
    Swal.fire({
      title: t("caymland.mailbox.message.delete.title"),
      text: t("caymland.core.form.confirmbatchdelete"),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: t("caymland.core.form.delete"),
      cancelButtonText: t("caymland.core.form.cancel"),
      customClass: {
        confirmButton: "delete-component swal-custom",
        cancelButton: "result-component swal-custom",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteItems({ source: link, params });
        table.resetRowSelection();
        if (response && !response.error) {
          refetch();
        } else {
          Swal.fire(t("caymland.core.error"), t("caymland.message.error"));
        }
      }
    });
  };

  const actionBatchButtons = [
    {
      name: t("caymland.lead.batch.campaigns"),
      icon: <DisplaySettingsOutlinedIcon style={{ fontSize: "16px" }} />,
      show: changeCampaigns,
      onClick: async (id) => {
        setModalOptions({ isOpen: true, id: id, title: t("caymland.lead.batch.campaigns"), type: "campaigns" });
      },
    },
    {
      name: t("caymland.lead.batch.categories"),
      icon: <AiOutlineFolderOpen style={{ fontSize: "16px" }} />,
      show: changeCategories,
      onClick: async (id) => {
        setModalOptions({ isOpen: true, id: id, title: t("caymland.lead.batch.categories"), type: "categories" });
      },
    },
    {
      name: t("caymland.lead.batch.channels"),
      icon: <FaSignal style={{ fontSize: "16px" }} />,
      show: changeCategories,
      onClick: async (id) => {
        setModalOptions({ isOpen: true, id: id, title: t("caymland.lead.batch.channels"), type: "channels" });
      },
    },
    {
      name: t("caymland.lead.batch.owner"),
      icon: <FiUsers style={{ fontSize: "16px" }} />,
      show: changeOwner,
      onClick: async (id) => {
        setModalOptions({ isOpen: true, id: id, title: t("caymland.lead.batch.owner"), type: "owner" });
      },
    },
    {
      name: t("caymland.lead.batch.lists"),
      icon: <FilterAltOutlinedIcon style={{ fontSize: "16px" }} />,
      show: changeSegments,
      onClick: async (id) => {
        setModalOptions({ isOpen: true, id: id, title: t("caymland.lead.batch.lists"), type: "segments" });
      },
    },
    // {
    //   name: t("caymland.lead.batch.stages"),
    //   icon: <RouteOutlinedIcon style={{ fontSize: "16px" }} />,
    //   show: changeStage,
    //   onClick: async (id) => {
    //     setModalOptions({ isOpen: true, id: id, title: t("caymland.lead.batch.stages"), type: "stage" });
    //   },
    // },
    {
      name: t("caymland.lead.batch.dnc"),
      icon: <FaBan style={{ fontSize: "16px" }} color="#f86b4f" />,
      show: changeStage,
      onClick: async (id) => {
        setModalOptions({ isOpen: true, id: id, title: t("caymland.lead.batch.dnc"), type: "dnc" });
      },
    },
    {
      name: t("caymland.brochures.orders.change.status"),
      icon: <DoneAllIcon style={{ fontSize: "16px" }} color="#f86b4f" />,
      show: changeStatus,
      onClick: async (id) => {
        setModalOptions({ isOpen: true, id: id, title: t("caymland.brochures.orders.change.status"), type: "status" });
      },
    },
    {
      name: t("caymland.email.report.export"),
      icon: <FileDownloadIcon style={{ fontSize: "16px" }} />,
      show: exportFile,
      onClick: async (id, table) => {
        handleBatchExport(id, table);
      },
    },
    {
      name: t("caymland.core.form.delete_selected"),
      icon: <FaTrashAlt style={{ fontSize: "16px" }} />,
      show: _delete,
      onClick: async (id, table) => {
        handleBatchDelete(id, table);
      },
    },
 
  ];

  return actionBatchButtons.filter((button) => button.show);
};
