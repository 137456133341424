import React, { useState, useEffect } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import Select from "react-select";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { ToggleField } from "./contentFields/toggleField";
import { Checkbox, Label, Text, TextArea } from "../../../../reusableComponents/Inputs";
import { arrayMoveImmutable } from "array-move";
import { BiDotsVertical } from "react-icons/bi";
import { Editor } from "@monaco-editor/react";
import { Select as ReSelect } from "../../../../reusableComponents/Inputs";
import { fieldsApi } from "../../../../../redux/api";
import { t } from "i18next";

const SortableItem = SortableElement(({ value, index, onChange, onRemove, rowIndex, required }) => {
  return (
    <div index={rowIndex} key={index} className="d-flex mt-2" style={{ marginBottom: "5px", width: "100%" }}>
      <button
        type="button"
        onClick={() => onRemove(value)}
        className="result-component mt-2"
        style={{
          height: "40px",
          width: "80px",
        }}
      >
        X
      </button>
      <div className="mb-1 mt-2" style={{ height: "28px", width: "100%" }}>
        <Text
          name="label"
          value={value.label || ""}
          onChange={(e) => onChange(e, rowIndex, "label")}
          label={t("caymland.form.field.form.label")}
          required={required}
        />
      </div>
      <div className="mb-1 mt-2" style={{ height: "28px", width: "100%" }}>
        <Text
          name="value"
          value={value.value || ""}
          onChange={(e) => onChange(e, rowIndex, "value")}
          label={t("caymland.form.field.form.value")}
          required={required}
        />
      </div>

      <button type="button" className="result-component mt-2" style={{ height: "40px", width: "80px" }}>
        <span>
          <BiDotsVertical size={12} />
        </span>
      </button>
    </div>
  );
});
const SortableList = SortableContainer(({ items, onChange, onRemove, required }) => {
  return (
    <div>
      {Array.isArray(items) &&
        items.map((value, index) => (
          <SortableItem
            required={required}
            value={value}
            rowIndex={index}
            index={index}
            key={value.id}
            onChange={onChange}
            onRemove={onRemove}
          />
        ))}
    </div>
  );
});

const ContentGenerator = ({ data, groupedData, control, setEntity, entity, modifiedData, type, show }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [selectedOption, setSelectedOption] = useState();
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  // const types = ["plugin.brochures", "captcha", "freetext", "freehtml", "pagebreak", "plugin.loginSocial"];
  const tabNames = ["generalTab", "validationTab", "propertiesTab", "attributesTab", "behaviorTab"];

  const [triggerContactFields] = fieldsApi.useLazyGetContactFieldsQuery();

  const retrieveContacts = (inputValue, bundle, includeGlobal = true) =>
    new Promise(async (resolve, reject) => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
      const newTimeout = setTimeout(async () => {
        const query = {};

        if (inputValue.length) {
          query.search = inputValue;
        }

        const response = await triggerContactFields(query);

        if (!response.error) {
          const contactsData = response?.data?.data;
          const contactsArray = contactsData
            .map((field) => ({
              label: field?.label,
              value: field?.alias,
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
          setSelectedOption(contactsArray);
          resolve(contactsArray);
        } else {
          reject();
        }
      }, 500);
      setDebounceTimeout(newTimeout);
    });

  const toggleTab = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const handleOptionsChange = (e, index, type, listType) => {
    const { value } = e.target;

    if (listType === "optionlist") {
      setEntity((prevEntity) => {
        // Clone the current list items
        let updatedItems;
        if (listType === "optionlist") {
          updatedItems = [...prevEntity.properties.optionlist.list];
        } else {
          updatedItems = [...prevEntity.properties.list.list];
        }

        if (updatedItems[index]) {
          updatedItems[index] = {
            ...updatedItems[index],
            [type]: value,
          };
        }
        // Return the updated entity
        return {
          ...prevEntity,
          properties: {
            ...prevEntity.properties,
            [listType === "optionlist" ? "optionlist" : "list"]: {
              ...prevEntity.properties[listType === "optionlist" ? "optionlist" : "list"],
              list: updatedItems,
            },
          },
        };
      });
    } else {
      setEntity((prevEntity) => {
        // Clone the current list items
        const updatedItems = [...prevEntity.properties.list.list];

        // Update the specific item's label or value
        if (updatedItems[index]) {
          updatedItems[index][type] = value;
        }

        // Return the updated entity
        return {
          ...prevEntity,
          properties: {
            ...prevEntity.properties,
            list: {
              ...prevEntity.properties.list,
              list: updatedItems,
            },
          },
        };
      });
    }
  };

  const handleOptionsAdd = (listType) => {
    const newItem = {
      // tempId: Math.random().toString(36).substr(2, 9), // unique ID
      label: "",
      value: "",
    };
    if (listType === "optionlist") {
      setEntity((prevEntity) => {
        // Ensuring that all the necessary nested properties exist
        const updatedProperties = prevEntity.properties || {};
        const updatedList = updatedProperties.optionlist || {}; // Changed from optionslist to optionlist
        const currentList = updatedList.list || [];

        return {
          ...prevEntity,
          properties: {
            ...updatedProperties,
            optionlist: {
              // Changed from optionslist to optionlist
              ...updatedList,
              list: [...currentList, newItem],
            },
          },
        };
      });
    } else {
      setEntity((prevEntity) => {
        // Ensuring that all the necessary nested properties exist
        const updatedProperties = prevEntity.properties || {};
        const updatedList = updatedProperties.list || {}; // Changed from optionslist to optionlist
        const currentList = updatedList.list || [];

        return {
          ...prevEntity,
          properties: {
            ...updatedProperties,
            list: {
              // Changed from optionslist to optionlist
              ...updatedList,
              list: [...currentList, newItem],
            },
          },
        };
      });
    }
  };

  const handleOptionsRemove = (index, listType) => {
    if (listType === "optionlist") {
      const updatedItems = entity.properties.optionlist.list.filter((x) => x.value !== index.value);
      setEntity({
        ...entity,
        properties: {
          ...entity.properties,
          optionlist: {
            ...entity.properties.optionlist,
            list: updatedItems,
          },
        },
      });
    } else {
      const updatedItems = entity?.properties?.list?.list?.filter((x) => x?.value !== index?.value);
      setEntity({
        ...entity,
        properties: {
          ...entity.properties,
          list: {
            ...entity.properties.list,
            list: updatedItems,
          },
        },
      });
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }, listType) => {
    if (listType === "optionlist") {
      const updatedItems = arrayMoveImmutable(entity.properties.optionlist.list, oldIndex, newIndex);
      setEntity({
        ...entity,
        properties: {
          ...entity.properties,
          optionlist: {
            ...entity.properties.optionlist,
            list: updatedItems,
          },
        },
      });
    } else {
      const updatedItems = arrayMoveImmutable(entity.properties.list.list, oldIndex, newIndex);
      setEntity({
        ...entity,
        properties: {
          ...entity.properties,
          list: {
            ...entity.properties.list,
            list: updatedItems,
          },
        },
      });
    }
  };

  const tabNameToLabel = (tabName) => {
    const label = tabName.replace(/Tab$/, "");
    return label.charAt(0).toUpperCase() + label.slice(1);
  };

  if (!groupedData) {
    return <div>Loading...</div>; // You can adjust the loading message
  }
  const isRequired = entity?.isRequired === true; // Assuming "isRequired" is the field name
  const shouldShowValidationMessage = isRequired && !entity?.fieldName; // Aappdd your specific condition here
  const isWeightRequired = entity?.brochures_validator_weight === true;
  const shouldShowInput = isWeightRequired && !entity?.fieldName;

  const backTabNames = Object.keys(groupedData);
  const finalTabNames = tabNames.filter((tabName) => backTabNames.includes(tabName));

  return (
    <div>
      <Nav style={{ marginBottom: "10px" }} tabs>
        {finalTabNames.map((tabName, index) => (
          <NavItem key={index}>
            <NavLink className={activeTab === index ? "active-tab" : ""} onClick={() => toggleTab(index)}>
              {tabNameToLabel(tabName)}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {finalTabNames?.map((tabName, index) => {
          return (
            <TabPane key={index} tabId={index}>
              <div style={{ padding: "16px" }}>
                {tabName === "generalTab" && (
                  <div className="grid ">
                    {/* First row with a text input and two checkboxes */}
                    {type === "captcha" ? (
                      <div className="d-flex items-center w-full  gap-5px" style={{ justifyContent: "space-evenly" }}>
                        <Text
                          label={t("caymland.form.field.form.label")}
                          name="label"
                          required
                          wFull="w-full"
                          value={entity?.label || ""}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setEntity((prevEntity) => ({
                              ...prevEntity,
                              label: newValue,
                            }));
                          }}
                        />
                        <Text
                          label={t("caymland.form.field.form.helpmessage")}
                          name="helpMessage"
                          wFull="w-full"
                          value={entity?.helpMessage || ""}
                          tooltip={"test"}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setEntity((prevEntity) => ({
                              ...prevEntity,
                              helpMessage: newValue,
                            }));
                          }}
                        />
                      </div>
                    ) : type === "freetext" || type === "freehtml" ? (
                      <div className="d-flex items-center w-full  gap-5px" style={{ justifyContent: "space-between" }}>
                        <Text
                          label={t("caymland.form.field.form.header")}
                          name="label"
                          wFull="w-full"
                          required
                          value={entity?.label || ""}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setEntity((prevEntity) => ({
                              ...prevEntity,
                              label: newValue,
                            }));
                          }}
                        />
                        <div className="w-full d-flex justify-center mb-20px">
                          <Checkbox
                            label={t("caymland.form.field.form.showheader")}
                            name="showLabel"
                            checked={entity?.showLabel}
                            value={entity?.showLabel}
                            onChange={(e) => {
                              const { checked } = e.target;
                              const newValue = checked ? checked : false;
                              setEntity((prevEntity) => ({
                                ...prevEntity,
                                showLabel: newValue,
                              }));
                            }}
                          />
                        </div>
                      </div>
                    ) : type === "file" ? (
                      <div>
                        <div
                          className="d-flex items-center w-full  gap-5px"
                          style={{ justifyContent: "space-between" }}
                        >
                          <Text
                            label={t("caymland.form.field.form.label")}
                            name="label"
                            wFull="w-full"
                            required
                            value={entity?.label || ""}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              setEntity((prevEntity) => ({
                                ...prevEntity,
                                label: newValue,
                              }));
                            }}
                          />
                          <div className="w-full d-flex justify-center mb-20px">
                            <Checkbox
                              label={t("caymland.form.field.form.saveresults")}
                              name="saveResult"
                              checked={entity?.saveResult}
                              tooltip={t("caymland.form.field.help.saveresult")}
                              value={entity?.saveResult}
                              onChange={(e) => {
                                const { checked } = e.target;
                                const newValue = checked ? checked : false;
                                setEntity((prevEntity) => ({
                                  ...prevEntity,
                                  saveResult: newValue,
                                }));
                              }}
                            />
                          </div>
                        </div>
                        <Text
                          label={t("caymland.form.field.form.helpmessage")}
                          name="helpMessage"
                          wFull="w-full"
                          tooltip={"Text to display below the label."}
                          value={entity?.helpMessage || ""}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setEntity((prevEntity) => ({
                              ...prevEntity,
                              helpMessage: newValue,
                            }));
                          }}
                        />
                      </div>
                    ) : type === "hidden" ? (
                      <div>
                        <div
                          className="d-flex items-center w-full  gap-5px"
                          style={{ justifyContent: "space-between" }}
                        >
                          <Text
                            label={t("caymland.form.field.form.label")}
                            name="label"
                            wFull="w-full"
                            required
                            value={entity?.label || ""}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              setEntity((prevEntity) => ({
                                ...prevEntity,
                                label: newValue,
                              }));
                            }}
                          />
                          <div className="w-full d-flex justify-center mb-20px">
                            <Checkbox
                              label={t("caymland.form.field.form.saveresults")}
                              name="saveResult"
                              checked={entity?.saveResult}
                              value={entity?.saveResult}
                              onChange={(e) => {
                                const { checked } = e.target;
                                const newValue = checked ? checked : false;
                                setEntity((prevEntity) => ({
                                  ...prevEntity,
                                  saveResult: newValue,
                                }));
                              }}
                            />
                          </div>
                        </div>
                        <Text
                          label={t("caymland.core.defaultvalue")}
                          name="defaultValue"
                          wFull="w-full"
                          value={entity?.defaultValue || ""}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setEntity((prevEntity) => ({
                              ...prevEntity,
                              defaultValue: newValue,
                            }));
                          }}
                        />
                      </div>
                    ) : type === "plugin.loginSocial" ? (
                      <div>
                        <div
                          className="d-flex items-center w-full  gap-5px"
                          style={{ justifyContent: "space-between" }}
                        >
                          <Text
                            label={t("caymland.form.field.form.label")}
                            name="label"
                            wFull="w-full"
                            required
                            value={entity?.label || ""}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              setEntity((prevEntity) => ({
                                ...prevEntity,
                                label: newValue,
                              }));
                            }}
                          />
                          <div className="w-full d-flex justify-center mb-20px">
                            <Checkbox
                              label={t("caymland.form.field.form.showlabel")}
                              name="showLabel"
                              checked={entity?.showLabel}
                              value={entity?.showLabel}
                              onChange={(e) => {
                                const { checked } = e.target;
                                const newValue = checked ? checked : false;
                                setEntity((prevEntity) => ({
                                  ...prevEntity,
                                  showLabel: newValue,
                                }));
                              }}
                            />
                          </div>
                        </div>
                        <Text
                          label={t("caymland.form.field.form.helpmessage")}
                          name="helpMessage"
                          wFull="w-full"
                          value={entity?.helpMessage || ""}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setEntity((prevEntity) => ({
                              ...prevEntity,
                              helpMessage: newValue,
                            }));
                          }}
                        />
                      </div>
                    ) : type === "pagebreak" ? (
                      <Text
                        label={t("caymland.form.field.form.label")}
                        name="label"
                        required
                        wFull
                        value={entity?.label || ""}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setEntity((prevEntity) => ({
                            ...prevEntity,
                            label: newValue,
                          }));
                        }}
                      />
                    ) : (
                      <div className="grid">
                        <div className="d-flex items-center w-full justify-between gap-5px">
                          <div className="w-full">
                            <Text
                              label={t("caymland.form.field.form.label")}
                              name="label"
                              required
                              wFull
                              value={entity?.label || ""}
                              onChange={(e) => {
                                const newValue = e.target.value;
                                setEntity((prevEntity) => ({
                                  ...prevEntity,
                                  label: newValue,
                                }));
                              }}
                            />
                          </div>
                          <div
                            className="d-flex itemx-center w-full  mb-20px"
                            style={{ justifyContent: "space-evenly" }}
                          >
                            <Checkbox
                              label={t("caymland.form.field.form.showlabel")}
                              name="showLabel"
                              checked={entity?.showLabel}
                              value={entity?.showLabel}
                              onChange={(e) => {
                                const { checked } = e.target;
                                const newValue = checked ? checked : false;
                                setEntity((prevEntity) => ({
                                  ...prevEntity,
                                  showLabel: newValue,
                                }));
                              }}
                            />

                            <Checkbox
                              label={t("caymland.form.field.form.saveresults")}
                              name="saveResult"
                              checked={entity?.saveResult}
                              value={entity?.saveResult}
                              tooltip={t("caymland.form.field.help.saveresult")}
                              onChange={(e) => {
                                const { checked } = e.target;
                                const newValue = checked ? checked : false;
                                setEntity((prevEntity) => ({
                                  ...prevEntity,
                                  saveResult: newValue,
                                }));
                              }}
                            />
                          </div>
                        </div>
                        <div className="d-flex items-center w-full  gap-5px" style={{ justifyContent: "space-evenly" }}>
                          <Text
                            label={t("caymland.core.defaultvalue")}
                            name="defaultValue"
                            wFull="w-full"
                            value={entity?.defaultValue || ""}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              setEntity((prevEntity) => ({
                                ...prevEntity,
                                defaultValue: newValue,
                              }));
                            }}
                          />
                          <Text
                            label={t("caymland.form.field.form.helpmessage")}
                            name="helpMessage"
                            wFull="w-full"
                            value={entity?.helpMessage || ""}
                            tooltip={t("caymland.form.field.help.helpmessage")}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              setEntity((prevEntity) => ({
                                ...prevEntity,
                                helpMessage: newValue,
                              }));
                            }}
                          />
                        </div>{" "}
                      </div>
                    )}

                    {groupedData?.leadFieldTab ? (
                      <ReSelect
                        label={t("caymland.form.field.form.lead_field")}
                        options={retrieveContacts}
                        value={
                          entity?.leadField
                            ? {
                                value: entity.leadField,
                                label:
                                  selectedOption?.find((choice) => choice.value === entity.leadField)?.label ||
                                  entity?.leadField,
                              }
                            : null
                        }
                        onChange={(selectedOption) => {
                          const newValue = selectedOption?.value;
                          setEntity((prevEntity) => ({
                            ...prevEntity,
                            leadField: newValue,
                          }));
                        }}
                      />
                    ) : null}
                    {/* Dynamic Fields in GeneralTab */}
                    {groupedData["generalTab"]
                      ?.filter((field) => {
                        return !["label", "showLabel", "defaultValue", "helpMessage", "saveResult"].includes(
                          field.name
                        );
                      })
                      .map((field, fieldIndex) => {
                        if (field.childType === "text") {
                          return (
                            <Text
                              label={field.label}
                              name={field.name}
                              value={entity?.[field.name] || ""}
                              tooltip={field.attr.tooltip}
                              wFull
                              onChange={(e) => {
                                const newValue = e.target.value;
                                setEntity((prevEntity) => ({
                                  ...prevEntity,
                                  [field.name]: newValue,
                                }));
                              }}
                            />
                          );
                        } else if (field.childType === "toggle") {
                          return (
                            <div className="mb-20px">
                              <Checkbox
                                label={field.label}
                                name={field.name}
                                value={entity?.[field.name]}
                                onChange={(e) => {
                                  const { checked } = e.target;
                                  const newValue = checked ? checked : false;
                                  setEntity((prevEntity) => ({
                                    ...prevEntity,
                                    [field.name]: newValue,
                                  }));
                                }}
                              />
                            </div>
                          );
                        } else if (field.childType === "choice") {
                          return (
                            <div>
                              <ReSelect
                                label={field.label}
                                options={field?.choices?.map((choice) => ({
                                  value: choice.value,
                                  label: choice.label,
                                }))}
                                value={
                                  entity?.[field.name]
                                    ? {
                                        value: entity[field.name],
                                        label:
                                          field.choices.find((choice) => choice.value === entity[field.name])?.label ||
                                          "",
                                      }
                                    : null
                                }
                                onChange={(selectedOption) => {
                                  const newValue = selectedOption?.value;
                                  setEntity((prevEntity) => ({
                                    ...prevEntity,
                                    [field.name]: newValue,
                                  }));
                                }}
                              />
                            </div>
                          );
                        }
                      })}
                  </div>
                )}

                {tabName === "validationTab" && (
                  <div>
                    {groupedData["validationTab"]?.map((field, index) => {
                      return (
                        <>
                          {field.childType === "toggle" && (
                            <div className="mb-20px">
                              <Checkbox
                                label={field.label}
                                name={field.name}
                                value={entity?.[field.name]}
                                onChange={(e) => {
                                  const { checked } = e.target;
                                  const newValue = e.target.checked ? checked : false;
                                  setEntity((prevEntity) => ({
                                    ...prevEntity,
                                    [field.name]: newValue,
                                  }));
                                }}
                              />
                            </div>
                          )}

                          {shouldShowValidationMessage && field.name === "validationMessage" && (
                            <Text
                              label={field.label}
                              name={field.name}
                              value={isRequired ? entity?.[field.name] : ""}
                              wFull
                              onChange={(e) => {
                                const newValue = e.target.value;
                                setEntity((prevEntity) => ({
                                  ...prevEntity,
                                  [field.name]: newValue,
                                }));
                              }}
                            />
                          )}
                          {Object.keys(field).map((nestedFieldName) => {
                            const nestedField = field[nestedFieldName];

                            if (nestedField.childType === "choice") {
                              // Render the toggle field
                              return (
                                <div className="mb-20px">
                                  <Checkbox
                                    label={nestedField.label}
                                    name={nestedField.name}
                                    value={entity?.[nestedField.name]}
                                    onChange={(e) => {
                                      const { checked } = e.target;
                                      const newValue = e.target.checked ? checked : false;
                                      setEntity((prevEntity) => ({
                                        ...prevEntity,
                                        [nestedField.name]: newValue,
                                      }));
                                    }}
                                  />
                                </div>
                              );
                            } else if (shouldShowInput && nestedField.childType === "text") {
                              // Render the text field
                              return (
                                <Text
                                  label={nestedField.label}
                                  name={nestedField.name}
                                  value={entity?.[nestedField.name] || ""}
                                  wFull
                                  onChange={(e) => {
                                    const newValue = e.target.value;
                                    setEntity((prevEntity) => ({
                                      ...prevEntity,
                                      [nestedField.name]: newValue,
                                    }));
                                  }}
                                />
                              );
                            }

                            return null; // Handle other types of nested fields here
                          })}
                        </>
                      );
                    })}
                  </div>
                )}

                {tabName === "propertiesTab" && (
                  <>
                    {groupedData["propertiesTab"]?.map((field, index) => {
                      return (
                        <>
                          {Object.keys(field)?.map((subFieldName) => {
                            const subField = field[subFieldName];
                            if (subField?.childType === "choice") {
                              return (
                                <div key={subFieldName}>
                                  <ReSelect
                                    label={subField.label || subField.name}
                                    tooltip={field?.attr?.tooltip || subField?.attr?.tooltip}
                                    required={subField?.required}
                                    options={subField?.choices?.map((choice) => ({
                                      value: choice.value,
                                      label: choice.label,
                                    }))}
                                    placeholder={`Select...`}
                                    value={
                                      entity?.properties?.[subFieldName]
                                        ? {
                                            value: entity.properties[subFieldName],
                                            label: subField?.choices?.find(
                                              (choice) => choice.value === entity.properties[subFieldName]
                                            )?.label,
                                          }
                                        : ""
                                    }
                                    onChange={(selectedOption) => {
                                      const newValue = selectedOption?.value;
                                      setEntity((prevEntity) => ({
                                        ...prevEntity,
                                        properties: {
                                          ...prevEntity.properties,
                                          [subFieldName]: newValue,
                                        },
                                      }));
                                    }}
                                  />
                                </div>
                              );
                            } else if (subField?.childType === "text") {
                              return (
                                <Text
                                  label={subField?.label}
                                  name={subField?.name}
                                  errors={show && subField.name === "allowed_file_size"}
                                  required={subField.name === "allowed_file_size" ? true : subField?.required}
                                  tooltip={subField?.attr?.tooltip}
                                  value={entity?.properties?.[subFieldName]}
                                  wFull
                                  onChange={(e) => {
                                    const newValue = e.target.value;
                                    setEntity((prevEntity) => ({
                                      ...prevEntity,
                                      properties: {
                                        ...prevEntity?.properties,
                                        [subFieldName]: newValue,
                                      },
                                    }));
                                  }}
                                />
                              );
                            } else if (subField?.childType === "sortablelist") {
                              return (
                                <div style={{ width: "100%" }}>
                                  <Label name={subField.name} label={subField.name} required={subField.required} />
                                  <button
                                    className="addOptions-btn"
                                    onClick={() => handleOptionsAdd(field?.optionlist ? "optionlist" : "list")}
                                  >
                                    {t("caymland.form.button.add.value")}
                                  </button>
                                  <SortableList
                                    items={
                                      field?.optionlist
                                        ? entity?.properties?.optionlist?.list
                                        : entity?.properties?.list?.list
                                    }
                                    onSortEnd={({ oldIndex, newIndex }) =>
                                      onSortEnd({ oldIndex, newIndex }, field?.optionlist ? "optionlist" : "list")
                                    }
                                    onChange={(e, type, index) =>
                                      handleOptionsChange(e, type, index, field?.optionlist ? "optionlist" : "list")
                                    }
                                    onRemove={(index) =>
                                      handleOptionsRemove(index, field?.optionlist ? "optionlist" : "list")
                                    }
                                    axis="y"
                                  />
                                </div>
                              );
                            } else if (subField?.childType === "textarea") {
                              return (
                                <TextArea
                                  tooltip={field?.attr?.tooltip || subField?.attr?.tooltip}
                                  label={subField.label}
                                  showToolbar={subField.name === "allowed_file_extensions" ? false : true}
                                  required={subField.required}
                                  value={
                                    entity && entity.properties && entity.properties[subFieldName]
                                      ? entity.properties[subFieldName]
                                      : subField.name === "allowed_file_extensions" && subField.data
                                      ? subField.data.join(", ")
                                      : ""
                                  }
                                  onChange={(e) => {
                                    const newValue = e?.target?.value || e;
                                    setEntity((prevEntity) => ({
                                      ...prevEntity,
                                      properties: {
                                        ...prevEntity?.properties,
                                        [subFieldName]: newValue,
                                      },
                                    }));
                                  }}
                                />
                              );
                            } else if (subField?.childType === "editor") {
                              return (
                                <Editor
                                  height="400px"
                                  defaultLanguage="html"
                                  theme="vs-dark"
                                  value={entity?.properties?.[subFieldName] || ""}
                                  onChange={(e) => {
                                    const newValue = e.target.value;
                                    setEntity((prevEntity) => ({
                                      ...prevEntity,
                                      properties: {
                                        ...prevEntity?.properties,
                                        [subFieldName]: newValue,
                                      },
                                    }));
                                  }}
                                />
                              );
                            } else if (subField.childType === "toggle") {
                              return (
                                <div className="mb-20px">
                                  <Checkbox
                                    tooltip={field?.attr?.tooltip}
                                    label={subField.label}
                                    required={subField.required}
                                    name={subField.name}
                                    value={entity?.properties?.[subFieldName] || ""}
                                    onChange={(e) => {
                                      const { checked } = e.target;
                                      const newValue = e.target.checked ? checked : false;
                                      setEntity((prevEntity) => ({
                                        ...prevEntity,
                                        properties: {
                                          ...prevEntity.properties,
                                          [subFieldName]: newValue,
                                        },
                                      }));
                                    }}
                                  />
                                </div>
                              );
                            }

                            // Add more conditional rendering for different sub-field types
                            return null;
                          })}
                        </>
                      );
                    })}
                  </>
                )}

                {tabName === "attributesTab" && (
                  <>
                    {groupedData["attributesTab"].map((field, fieldIndex) => {
                      if (field.childType === "text") {
                        return (
                          <Text
                            label={field.label}
                            name={field.name}
                            value={entity?.[field.name] || ""}
                            tooltip={field?.attr?.tooltip}
                            required={field?.required}
                            wFull
                            onChange={(e) => {
                              const newValue = e.target.value;
                              setEntity((prevEntity) => ({
                                ...prevEntity,
                                [field.name]: newValue.replace(/-/g, ""),
                              }));
                            }}
                          />
                        );
                      } else if (field.childType === "toggle") {
                        return (
                          <div className="mb-20px">
                            <Checkbox
                              label={field.label}
                              name={field.name}
                              required={field?.required}
                              tooltip={field?.attr?.tooltip}
                              value={entity?.[field.name]}
                              onChange={(e) => {
                                const { checked } = e.target;
                                const newValue = checked ? checked : false;
                                setEntity((prevEntity) => ({
                                  ...prevEntity,
                                  [field.name]: newValue,
                                }));
                              }}
                            />
                          </div>
                        );
                      } else if (field.childType === "choice") {
                        return (
                          <div>
                            <ReSelect
                              label={field?.label}
                              required={field?.required}
                              options={field?.choices?.map((choice) => ({
                                value: choice.value,
                                label: choice.label,
                              }))}
                              tooltip={field?.attr?.tooltip}
                              value={
                                entity?.[field.name]
                                  ? {
                                      value: entity[field.name],
                                      label:
                                        field.choices.find((choice) => choice.value === entity[field.name])?.label ||
                                        "",
                                    }
                                  : null
                              }
                              onChange={(selectedOption) => {
                                const newValue = selectedOption?.value;
                                setEntity((prevEntity) => ({
                                  ...prevEntity,
                                  [field.name]: newValue,
                                }));
                              }}
                            />
                          </div>
                        );
                      }

                      // Add additional cases for other field types if necessary
                    })}
                  </>
                )}
                {tabName === "behaviorTab" && (
                  <>
                    {groupedData["behaviorTab"]?.map((field, fieldIndex) => {
                      if (field.childType === "text") {
                        return (
                          <Text
                            label={field.label}
                            name={field.name}
                            value={entity?.[field.name] || ""}
                            wFull
                            tooltip={field?.attr?.tooltip}
                            required={field.required}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              setEntity((prevEntity) => ({
                                ...prevEntity,
                                [field.name]: newValue,
                              }));
                            }}
                          />
                        );
                      } else if (field.childType === "toggle") {
                        return (
                          <div className="mb-20px d-flex gap-5px">
                            <Checkbox
                              label={field.label}
                              name={field.name}
                              value={entity?.[field.name]}
                              tooltip={field?.attr?.tooltip}
                              required={field.required}
                              onChange={(e) => {
                                const { checked } = e.target;
                                const newValue = checked ? checked : false;
                                setEntity((prevEntity) => ({
                                  ...prevEntity,
                                  [field.name]: newValue,
                                }));
                              }}
                            />
                          </div>
                        );
                      } else if (field.childType === "choice") {
                        return (
                          <div>
                            <ReSelect
                              label={field.label}
                              options={field?.choices?.map((choice) => ({
                                value: choice.value,
                                label: choice.label,
                              }))}
                              tooltip={field?.attr?.tooltip}
                              required={field.required}
                              value={
                                entity?.[field.name]
                                  ? {
                                      value: entity[field.name],
                                      label:
                                        field.choices.find((choice) => choice.value === entity[field.name])?.label ||
                                        "",
                                    }
                                  : null
                              }
                              onChange={(selectedOption) => {
                                const newValue = selectedOption?.value;
                                setEntity((prevEntity) => ({
                                  ...prevEntity,
                                  [field.name]: newValue,
                                }));
                              }}
                            />
                          </div>
                        );
                      }
                    })}
                  </>
                )}
              </div>
            </TabPane>
          );
        })}
      </TabContent>
    </div>
  );
};

export default ContentGenerator;
