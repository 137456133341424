import React, { Fragment, useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BiAddToQueue } from "react-icons/bi";
import Header from "../../../reusableComponents/Header/Header";
import DragAndDropTable from "../../../reusableComponents/CustomDragAndDropTable/DragAndDropTable";
import Swal from "sweetalert2";
import CampaignForm from "../../../reusableComponents/FolderForms/CampaignForm";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { MRT_CustomRowsActionFolder } from "../../../reusableComponents/CustomTable/plugins/MRT_CustomActionsFolder";
import { CategoryComponent } from "../../../reusableComponents/tableComponents/CategoryComponent";
import { ActionButtons } from "../../../reusableComponents/tableComponents/ActionButtons";
import { ActionBatchButtons } from "../../../reusableComponents/tableComponents/ActionBatchButtons";
import {
  emailsApi,
  useAddFolderEmailsMutation,
  useDeleteEmailMutation,
  useDeleteFolderEmailsMutation,
  useGetEmailsQuery,
  useGetFolderEmailsQuery,
  useGetSelectSegmentsQuery,
  useRemoveEmailsToFoldersMutation,
  useSendSegmentEmailMutation,
  useStopSegmentEmailMutation,
  useUpdateEmailsMutation,
  useUpdateEmailsToFoldersMutation,
  useUpdateFolderEmailsMutation,
} from "../../../../redux/api/emailsApi";
import { LinkComponent } from "../../../reusableComponents/tableComponents/LinkComponent";
import { MRT_ToggleSelect } from "../../../reusableComponents/CustomTable/MRT_ToggleSelect";
import { t } from "i18next";
import { Tooltip, Zoom } from "@mui/material";

export const StatusComponent = ({ data }) => {
  console.log(data);
  return (
    data?.isPublished && (
      <div className="center">
        {data?.status === 1 ? (
          <Tooltip
            arrow
            followCursor
            placement="top"
            TransitionComponent={Zoom}
            title={t("caymland.email.status.sending")}
            style={{ padding: "0" }}
            className="circle pulse orange"
          ></Tooltip>
        ) : data?.status === 2 && data?.pendingCount > 0 ? (
          <Tooltip
            arrow
            followCursor
            placement="top"
            TransitionComponent={Zoom}
            title={t("caymland.email.status.stopped")}
            style={{ padding: "0" }}
          >
            <div className="flex items-center p-no-margin">
              <p
                className="badge badge-dark"
                style={{
                  textAlign: "center",
                  margin: "0",
                  fontSize: "0.75",
                  fontWeight: "400",
                  border: `1px solid #d33d3d`,
                  color: "#d33d3d",
                  background: "white",
                }}
              >
                {t("caymland.email.stat.stop")}
              </p>
            </div>
          </Tooltip>
        ) : (
          data?.status === 3 && (
            <Tooltip
              arrow
              followCursor
              placement="top"
              TransitionComponent={Zoom}
              title={t("caymland.email.status.completed")}
              style={{ padding: "0" }}
            >
              <div className="flex items-center p-no-margin">
                <span
                  className="p-10"
                  style={{
                    width: "5px",
                    height: "5px",
                    backgroundColor: `#04B76B`,
                    boxShadow: "0 0 10px rgb(0 0 0 / 20%)",
                    borderRadius: "50%",
                  }}
                ></span>
              </div>
            </Tooltip>
          )
        )}
      </div>
    )
  );
};

function Emails(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [editingFolder, setEditingFolder] = useState();
  const [activeRowId, setActiveRowId] = useState(null);
  const [localLoading, setLocalLoading] = useState(-1);

  const [query, setQuery] = useState(() => {
    if (location?.state && location?.state?.search) {
      return {
        pageIndex: 0,
        pageSize: 30,
        orderByDir: "DESC",
        orderBy: "id",
        where: [
          // {
          //   expr: "isNull",
          //   col: "folderId",
          // },
        ],
        search: location?.state?.search,
      };
    } else {
      return {
        pageIndex: 0,
        pageSize: 30,
        orderByDir: "DESC",
        orderBy: "id",
        where: [
          // {
          //   expr: "isNull",
          //   col: "folderId",
          // },
        ],
      };
    }
  });

  useEffect(() => {
    reset({
      name: editingFolder?.name,
      parentId: editingFolder?.parentId || null,
      source: "emails",
    });
  }, [editingFolder]);

  const defaultValues = {
    name: editingFolder?.name || "",
    parentId: editingFolder?.parentId || null,
    source: "emails",
  };
  const clientSchema = yup.object({
    name: yup.string().required(),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues,
    resolver: yupResolver(clientSchema),
  });

  const handleTogglePublished = async (data) => {
    setLocalLoading(data.id);
    const updatedData = { isPublished: !data.isPublished };
    await updateEmail({ id: data.id, ...updatedData });
    await refetchTableData();
    setLocalLoading(-1);
  };

  const columns = useMemo(() => [
    {
      accessorKey: "name",
      accessorFn: (row) => row.name,
      header: t("caymland.email.report.email_name"),
      Cell: ({ cell }) => (
        <LinkComponent
          data={cell.row.original}
          loading={localLoading}
          handleTogglePublished={handleTogglePublished}
          link="emails"
          view={true}
        />
      ),
      size: 100,
      enableColumnFilters: false,
    },
    {
      accessorKey: "status",
      accessorFn: (row) => row.name,
      header: "",
      Cell: ({ cell }) => <StatusComponent data={cell.row.original} />,
      enableColumnFilters: false,
    },
    {
      accessorKey: "category",
      accessorFn: (row) => (row.category?.title ? row.category?.title : t("caymland.core.form.uncategorized")),
      header: t("caymland.core.category"),
      size: 150,
      Cell: ({ cell }) => <CategoryComponent data={cell.row.original} />,
    },
    {
      accessorKey: "pendingCount",
      accessorFn: (row) => row.pendingCount !== 0 && `${row.pendingCount} ${t("caymland.core.form.pending")}`,
      header: (
        <div
          style={{
            border: "1px solid black",
            color: "black",
            borderRadius: "5px",
            padding: "2px 10px",
            fontSize: "14px",
            margin: "2px",
          }}
        >
          {t("caymland.core.form.pending")}
        </div>
      ),
      size: 150,
      enableColumnFilters: false,
    },
    {
      accessorKey: "sentCount",
      accessorFn: (row) => row.sentCount !== 0 && `${row.sentCount} ${t("caymland.email.stat.sent")}`,
      header: (
        <div
          style={{
            border: "1px solid #FFBB33",
            color: "#FFBB33",
            borderRadius: "5px",
            padding: "2px 10px",
            fontSize: "14px",
            margin: "2px",
          }}
        >
          {t("caymland.email.stat.sent")}
        </div>
      ),
      size: 150,
      enableColumnFilters: false,
    },
    {
      accessorKey: "read",
      accessorFn: (row) => row.readCount !== 0 && `${row.readCount} ${t("caymland.email.stat.read")}`,
      header: (
        <div
          style={{
            border: "1px solid #04B76B",
            color: "#04B76B",
            borderRadius: "5px",
            padding: "2px 10px",
            fontSize: "14px",
            margin: "2px",
          }}
        >
          {t("caymland.email.stat.read")}
        </div>
      ),
      size: 150,
      enableColumnFilters: false,
    },
    {
      accessorKey: "readPercentage",
      accessorFn: (row) => {
        // Check if sentCount is zero to avoid division by zero
        if (row.sentCount === 0 || row.readCount === 0) {
          return "";
        }

        // Calculate percentage
        const percentage = (row.readCount / row.sentCount) * 100;
        // Round to two decimal places and add percentage sign
        return `${percentage.toFixed(1)}% ${t("caymland.email.stat.read")} `;
      },

      header: (
        <div
          style={{
            border: "1px solid #051E34",
            color: "#051E34",
            borderRadius: "5px",
            padding: "2px 10px",
            fontSize: "14px",
            margin: "2px",
          }}
        >
          % {t("caymland.email.stat.read")}
        </div>
      ),
      size: 150,
      enableColumnFilters: false,
    },
  ]);

  const folderColumns = useMemo(() => [
    {
      accessorKey: "name", //access nested data with dot notation
      header: "",
      size: 100,
      enableSorting: false,
      enableColumnActions: false,
    },
    {
      accessorKey: "mrt-row-action",
      maxSize: 30,
      Cell: ({ cell, row, table }) => <MRT_CustomRowsActionFolder cell={cell} row={row} table={table} />,
      enableSorting: false,
      enableColumnActions: false,
    },
  ]);
  const body = [<CampaignForm control={control} />];

  const buttons = [
    {
      name: "addnew",
      title: t("caymland.core.form.new"),
      icon: <BiAddToQueue size={17} />,
      onClick: () => navigate("/emails/new"),
    },
  ];

  const successMessage = "Campaign updated successfully!";
  const failMessage = "There was an error updating the campaign!";
  const showMessage = showAlert ? alertMessage : success ? successMessage : fail ? failMessage : "";

  const { data: emailsData, isFetching: isFetching, refetch: refetchTableData } = useGetEmailsQuery(query);
  const { data: folderEmailsData, refetch } = useGetFolderEmailsQuery();

  const { data: selectSegments } = useGetSelectSegmentsQuery();

  const listCategory = (type) => {
    if (type) {
      setQuery((prev) => ({
        ...prev,
        where: [
          {
            col: "l.id",
            expr: "in",
            val: type,
          },
        ],
      }));
    } else {
      setQuery((prev) => ({
        ...prev,
        where: [],
      }));
    }
  };

  const [updateEmail] = useUpdateEmailsMutation();
  const [deleteEmail] = useDeleteEmailMutation();
  const [addFolder] = useAddFolderEmailsMutation();
  const [updateFolder] = useUpdateFolderEmailsMutation();
  const [deleteFolder] = useDeleteFolderEmailsMutation();
  const [updateEmailToFolder] = useUpdateEmailsToFoldersMutation();
  const [removeEmailsFromFolder] = useRemoveEmailsToFoldersMutation();
  const [_sendSegmentEmail] = useSendSegmentEmailMutation();
  const [_stopSegmentEmail] = useStopSegmentEmailMutation();
  const [getEmail] = emailsApi.useLazyGetEmailQuery();

  const [refetchInterval, setRefetchInterval] = useState(null);

  const stopRefetching = () => {
    if (refetchInterval) {
      clearInterval(refetchInterval);
      setRefetchInterval(null);
    }
  };

  const stopSegmentEmail = async (id) => {
    const result = await Swal.fire({
      title: t("caymland.badge_contact.form.confirmdelete"),
      text: t("caymland.email.stopsend.emails"),
      icon: "question",
      customClass: {
        confirmButton: "confirm-component swal-custom",
        cancelButton: "result-component swal-custom",
      },
      showCancelButton: true,
      confirmButtonText: t("caymland.core.form.yes"),
      cancelButtonText: t("caymland.core.form.cancel"),
    });
    if (result.isConfirmed) {
      await _stopSegmentEmail({ id });
      refetchTableData();
      stopRefetching();
    }
  };

  const sendSegmentEmail = async (id) => {
    const email = await getEmail(id);
    const result = await Swal.fire({
      title: t("caymland.badge_contact.form.confirmdelete"),
      text: t("caymland.email.send.emails", { number: email?.data?.email?.pendingCount }),
      icon: "question",
      customClass: {
        confirmButton: "confirm-component swal-custom",
        cancelButton: "result-component swal-custom",
      },
      showCancelButton: true,
      confirmButtonText: t("caymland.core.form.yes"),
      cancelButtonText: t("caymland.core.form.cancel"),
    });
    if (result.isConfirmed) {
      await _sendSegmentEmail({ id });
      await refetchTableData();

      stopRefetching();

      const interval = setInterval(async () => {
        await refetchTableData();

        if (email?.data?.email?.status === 3) {
          clearInterval(interval);
          setRefetchInterval(null);
        }
      }, 5000);

      setRefetchInterval(interval);
    }
  };

  const actionButtons = ActionButtons({
    link: "emails",
    deleteItem: deleteEmail,
    refetch: refetchTableData,
    removeItemFromFolder: removeEmailsFromFolder,
    folderId: activeRowId,
    showRemove: activeRowId !== null,
    sendSegmentEmail: sendSegmentEmail,
    stopSegmentEmail: stopSegmentEmail,
    showSendSegmentEmail: true,
  });
  const actionBatchButtons = ActionBatchButtons({
    link: "emails",
    refetch: refetchTableData,
  });

  const options = Object.entries(selectSegments?.lists || []).map(([_, { id, name }]) => ({
    value: id,
    label: name,
  }));
  const selectProps = {
    data: options,
    onChange: listCategory,
    value: query?.where && query?.where[0]?.val,
    title: t("caymland.email.emails"),
  };
  const filteredEmailsData = emailsData?.data?.filter((email) => email?.translationParent === null);

  const toolbarButtons = useMemo(() => [
    {
      name: "segments",
      component: MRT_ToggleSelect,
      props: {
        data: options,
        onChange: listCategory,
        value: query?.where && query?.where[0]?.val,
        title: t("caymland.email.builder.tag.segments"),
      },
    },
  ]);

  return (
    <Fragment>
      <Header
        buttons={buttons}
        title={t("caymland.email.emails")}
        showAlert={showAlert || success || fail}
        alertMessage={showMessage}
      />
      <DragAndDropTable
        filteredData={filteredEmailsData}
        folderData={folderEmailsData}
        columns={columns}
        isFetching={isFetching}
        query={query}
        setQuery={setQuery}
        actionButtons={actionButtons}
        actionBatchButtons={actionBatchButtons}
        folderColumns={folderColumns}
        addFolder={addFolder}
        updateFolder={updateFolder}
        deleteFolder={deleteFolder}
        body={body}
        handleSubmit={handleSubmit}
        reset={reset}
        activeRowId={activeRowId}
        setActiveRowId={setActiveRowId}
        setEditingFolder={setEditingFolder}
        editingFolder={editingFolder}
        refetch={refetch}
        refetchTableData={refetchTableData}
        updateCampaignsToFolder={updateEmailToFolder}
        toolbarActions={{
          select: true,
        }}
        selectProps={selectProps}
        toolbarButtons={toolbarButtons}
      />
    </Fragment>
  );
}

export default Emails;
